import { useSelector } from 'model/hooks'
import Loading from 'components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import NotFound from 'shared/components/NotFound'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { useContext } from 'react'
import MainContext from 'contexts/MainContext'
import { IEntityType, IItemInfo } from 'shared/types'
import { storageRef } from 'controllers/db'
import CoursePage from 'shared/pages/CoursePage'

const Webinar = () => {
  const { webinarId } = useParams()
  const { openPayment } = useContext(MainContext)
  const webinars = useSelector(state => state.webinars)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const webinar = useSelector(
    state => state.webinars && webinarId && state.webinars[webinarId]
  )
  const userCourses = useSelector(state =>
    get(state.userEntities, 'seminars', {})
  )
  const teachers = useSelector(state => state.teachers)
  const navigate = useNavigate()

  const onPay = () => {
    if (webinar) {
      const itemInfo: IItemInfo = {
        title: webinar.title,
        id: webinar.id,
        type: IEntityType.WEBINARS,
        price: webinar.price,
        paymentLink: webinar.paymentLink
      }
      openPayment(itemInfo)
    }
  }

  if (webinars === null) {
    return <Loading />
  } else if (!webinar) {
    return <NotFound message='Такой вебинар не существует' />
  } else {
    const tags = [
      webinar.partitionId
        ? get(partitions, [webinar.partitionId, 'name'])
        : undefined,
      webinar.levelId ? get(levels, [webinar.levelId, 'name']) : undefined,
      ...(webinar.tags || [])
    ]
    return (
      <CoursePage
        course={{ ...webinar, tags: compact(tags) }}
        onPay={onPay}
        toCourse={() => navigate(`/profile/webinars/${webinar.id}`)}
        userCourses={userCourses}
        storageRef={storageRef}
        teachers={teachers || {}}
        partitions={partitions}
      />
    )
  }
}

export default Webinar
