import { useSelector } from 'model/hooks'
import Loading from 'components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import NotFound from 'shared/components/NotFound'
import get from 'lodash/get'
import { useContext } from 'react'
import MainContext from 'contexts/MainContext'
import { ICourse, IEntityType, IItemInfo } from 'shared/types'
import { storageRef } from 'controllers/db'
import CoursePage from 'shared/pages/CoursePage'
import { dbSaveFeedback } from 'controllers/feedback'

const Course = () => {
  const { courseId } = useParams()
  const { openPayment } = useContext(MainContext)
  const courses = useSelector(state => state.courses)
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const user = useSelector(state => state.user)
  const userCourses = useSelector(state =>
    get(state.userEntities, 'courses', {})
  )
  const navigate = useNavigate()
  const course: ICourse | undefined = useSelector(state =>
    state.courses && courseId ? state.courses[courseId] : undefined
  )

  const onPay = () => {
    if (course) {
      const itemInfo: IItemInfo = {
        title: course.title,
        id: course.id,
        type: IEntityType.COURSES,
        price: course.price,
        paymentLink: course.paymentLink
      }
      openPayment(itemInfo)
    }
  }

  if (courses === null) {
    return <Loading />
  } else if (!course) {
    return <NotFound message='Курс не существует' />
  } else {
    return (
      <CoursePage
        course={course}
        onPay={onPay}
        toCourse={() => navigate(`/profile/courses/${course.id}`)}
        userCourses={userCourses}
        storageRef={storageRef}
        user={user}
        dbSaveFeedback={dbSaveFeedback}
        teachers={teachers || {}}
        partitions={partitions}
      />
    )
  }
}

export default Course
