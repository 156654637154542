import { HStack, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { ReactComponent as IconChevronLeft } from 'shared/assets/bsChevronLeft.svg'

type Props = {
  onClick: () => void
}

const AuthBackButton: FC<Props> = ({ onClick }) => {
  return (
    <HStack w='full' as='button' onClick={onClick} color='darkGray' spacing={1}>
      <IconChevronLeft height='10' width='12' />
      <Text textStyle={'tag'}>назад</Text>
    </HStack>
  )
}

export default AuthBackButton
