import { Divider, Text, VStack, HStack, Stack, Flex } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  title: string
  description: string
  description2: string
  onClick: () => void
}

const MenuItem: FC<Props> = ({ title, onClick, description, description2 }) => {
  return (
    <VStack
      w='full'
      align='flex-start'
      spacing={'14px'}
      as='button'
      _hover={{ cursor: 'pointer' }}
      onClick={onClick}
      // pb={{ base: 5, lg: '60px' }}
    >
      <HStack w='full' align={{ base: 'flex-start', lg: 'center' }}>
        <Stack
          p={0}
          direction={{ base: 'column', lg: 'row' }}
          w='full'
          // spacing={{ base: 2, lg: 0 }}
          spacing={{ base: 1, lg: 6 }}
          align={{ base: 'flex-start', lg: 'center' }}
        >
          <Flex flex={1} flexShrink={0} justify={'flex-start'}>
            <Text textStyle={'body'} textAlign={'start'}>
              {title}
            </Text>
          </Flex>
          <HStack
            flex={0.5}
            flexShrink={0}
            justify={'space-between'}
            spacing={0}
          >
            <Text
              textStyle='small'
              color='black.300'
              pr={description !== '' ? 4 : 0}
            >
              {description}
            </Text>
            <Text textStyle='small' color='black.300'>
              {description2}
            </Text>
          </HStack>
        </Stack>
      </HStack>
      <Divider />
    </VStack>
  )
}

export default MenuItem
