import PageContentWrapper from 'shared/components/PageContentWrapper'
import { VStack, Text } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import BlackPageHeader from 'components/BlackPageHeader'

const Privacy = () => {
  const content = (
    <VStack
      w='full'
      align={'start'}
      color='black'
      textStyle={'body'}
      spacing={6}
      pt={12}
    >
      <Text>
        Московская область <br />
        Утверждено: 05.08.2020 г.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Цель Положения о конфиденциальности
      </Text>
      <Text>
        Настоящее Положение о конфиденциальности (далее – «Положение»)
        разработано в соответствии с Конституцией РФ, Гражданским кодексом РФ,
        Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных»
        и принятыми в соответствии с ними нормативными правовыми актами РФ.
        Данное Положение издано и применяется индивидуальным предпринимателем
        Остроуховой Маргаритой Сергеевной (ОГРНИП: 320508100147062) (далее –
        «Мы») в отношении информации, получаемой с Сайта Оператора с доменным
        именем: https://thequeenenglish.com/, включая все поддомены (далее –
        «Сайты»).
      </Text>
      <Text>
        Мы уважаем неприкосновенность частной жизни посетителей нашего сайта и
        понимаем, что информация о вас являются составляющей вашей частной жизни
        и может быть личной тайной. За исключением случаев, предусмотренных
        Положением о конфиденциальности в интернете настоящего веб-сайта, мы
        обязуемся не продавать, не передавать в пользование и не предоставлять
        информацию, позволяющую установить вашу личность, любому третьему лицу
        без вашего согласия. Настоящее Положение о конфиденциальности
        разработано с целью предоставления информации о наших правилах сбора,
        использования и раскрытия информации, которую вы предоставляете при
        посещении настоящего веб-сайта. Пожалуйста, ознакомьтесь с настоящим
        Положением о перед использованием нашего вебсайта или предоставлением
        ваших персональных данных. Пользуясь этим веб-сайтом, вы подтверждаете,
        что понимаете и соглашаетесь с условиями настоящего Положения о
        конфиденциальности.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Информация, добровольно предоставляемая пользователями
      </Text>
      <Text>
        Мы собираем два типа информации о вас: (A) информация, позволяющая
        установить личность (далее – «Персональные данные») и (В) информацию, не
        позволяющую установить личность (далее – «Автоматическая информация»).
        Предоставляя подобного рода информацию на вебсайте, вы понимаете и
        соглашаетесь, что мы не имеем никаких обязательств перед вами в
        отношении этой информации, кроме обязательств, заявленных в настоящем
        документе.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        A. Информация, позволяющая установить личность (Персональные данные)
      </Text>
      <Text>
        «Персональные данные» - любая информация, относящаяся прямо или косвенно
        к определенному или определяемому физическому лицу (субъекту
        персональных данных). По сути, это информация, которая идентифицирует
        вас лично, например, ваше ФИО, номер телефона, адрес электронной почты,
        почтовый адрес, ссылка на профиль в социальных сетях, фотографии и
        видео, предоставляемые вами для целей оказания Услуг.
      </Text>
      <Text>
        Мы собираем и храним информацию, которую вы нам предоставили. Рассмотрим
        некоторые примеры способов, с помощью которых мы можем собирать
        персональные данные. Мы можем собирать информацию о вашем имени, номере
        телефона, адресе электронной почты, если вы:
      </Text>
      <Text>заполняете форму регистрации на нашем веб-сайте;</Text>
      <Text>оставляете отзыв на нашем веб-сайте, социальных сетях;</Text>
      <Text>подписываетесь на нашу рассылку о новостях и акциях ;</Text>
      <Text>заполняете форму обратной связи;</Text>
      <Text>
        связываетесь с нами за консультацией о способе получения какой-либо
        услуги.
      </Text>
      <Text>
        Приведенный выше перечень представляет собой пример того, каким именно
        способом мы можем получить ваши персональные данные через наш веб-сайт.
        Время от времени, мы можем собирать личную информацию о вас другими
        способами, которые не описаны выше. Если вы не хотите, чтобы мы получили
        в обработку ваши персональные данные, пожалуйста, не предоставляйте их
        нам. Ваши персональные данные будут использоваться для тех целей, для
        которых вы нам их предоставили.
      </Text>
      <Text>
        Мы не осуществляем обработку персональных данных несовершеннолетних лиц.
        Если вам нет 18 лет, пожалуйста, не передавайте нам свои персональные
        данные. Ответственность за действия несовершеннолетних, включая
        приобретение ими услуг на Сайте, лежит на законных представителях
        несовершеннолетних. Все посетители, младше 18 лет, обязаны получить
        разрешение своих законных представителей прежде, чем предоставлять
        какую-либо персональную информацию о себе. Если нам станет известно о
        том, что мы получили персональные данные о несовершеннолетнем лице без
        согласия законных представителей, то такая информация будет удалена в
        максимально короткие сроки.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        B. Информация, не позволяющая установить личность (автоматическая
        информация)
      </Text>
      <Text>
        "Информация, не позволяющая установить личность" (автоматическая
        информация) не идентифицирует вас лично и не является персональными
        данными. Например, мы можем проводить исследования демографии наших
        пользователей, времени которое затрачено на просмотр страниц веб-сайта,
        интересов посетителей и поведения на основе информации, предоставленной
        специализированными службами по продвижению веб-сайта или на основе
        опросов. Мы делаем это, чтобы лучше понять вас и помочь вам, а также для
        того, чтобы иметь возможность улучшать наш веб-сайт. Автоматическая
        информация включает в себя:
      </Text>
      <Text>информацию о типе используемого браузера;</Text>
      <Text>детали просмотренных вами страниц;</Text>
      <Text>ваш IP-адрес;</Text>
      <Text>данные геолокации;</Text>
      <Text>гиперссылки, по которым вы перешли;</Text>
      <Text>
        веб-сайты, которые вы посетили, прежде чем зайти на наш веб-сайт;
      </Text>
      <Text>
        иные данные, сбор и обработка которых происходит автоматически на
        сайтах, принадлежащих Оператору.
      </Text>
      <Text>
        Большинство интернет-браузеров по умолчанию настроены на прием cookies.
        Вы можете изменить настройки, чтобы блокировать cookies, или установить
        предупреждение об отправке cookies на устройство.
      </Text>
      <Text>
        Мы также можем собирать автоматическую информацию, чтобы предоставлять
        услуги в соответствии с вашим желанием, выполнять ваши запросы на
        получение информации, а также улучшить наши услуги. Автоматическая
        информация может предоставляться третьим лицам. Например, мы можем
        передавать третьим лицам информацию о количестве пользователей нашего
        веб-сайта и мероприятиях, которые проводятся на сайте.
      </Text>
      <Text>
        Информация, которую вы предоставляете, и которая не относится к
        персональным данным, (например, вопросы или предложения) не
        рассматривается нами в качестве конфиденциальной. Мы вправе раскрывать
        такую информацию любыми средствами и использовать для любых целей. Если
        вы не хотите, чтобы мы собирали автоматическую информацию, пожалуйста,
        не посещайте наш веб-сайт или не предоставляйте нам эту информацию.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Использование и передача предоставленных персональных данных
      </Text>
      <Text>
        Мы можем обрабатывать и передавать ваши персональные данные в следующих
        случаях:
      </Text>
      <Text>
        Для предоставления услуг по вашему запросу, или совершения операций,
        запрошенных вами;
      </Text>
      <Text>
        Мы также можем использовать вашу личную информацию, чтобы послать вам
        рассылку, с вашего согласия;
      </Text>
      <Text>
        Третьим лицам – поставщикам услуг, агентам или независимым подрядчикам,
        которые помогают нам поддерживать наш веб-сайт и предоставлять нам
        другие административные услуги (включая, но не ограничиваясь, обработку
        поступающих заявок на оказание информационно-консультационных услуг,
        оказание услуг платежными системами);
      </Text>
      <Text>
        В соответствии с законом, или в случае разумного предположения, что
        такое действие необходимо для того, чтобы соответствовать требованиям
        закона или требованиям судебного процесса, чтобы охранять и защищать
        наши права или собственность, или при чрезвычайных обстоятельствах для
        защиты личной безопасности наших конечных пользователей;
      </Text>
      <Text>
        Компаниям-правопреемникам в случае нашей реорганизации, аффилированным
        компаниям;
      </Text>
      <Text>
        С целью отслеживания и анализа пользования сайтом без идентификации и
        оценки статистической информации о наших посетителях и пользователях и
        предоставления такой информации третьим лицам;
      </Text>
      <Text>
        Для обнаружения какой-либо физической или другого типа угрозы в ваш
        адрес или для защиты вашей собственности или законных прав.
      </Text>
      <Text>
        За исключением случаев, описанных в настоящем Положении или случаев,
        когда мы запрашиваем отдельное согласие или информацию, мы не можем
        использовать, передавать или раскрывать ваши персональные данные третьим
        лицам. Кроме того, Персональные данные могут быть переданы фактическим
        или потенциальным сторонам предполагаемой сделки, в случае принятия
        решения о реорганизации, без получения вашего согласия или без
        дополнительного уведомления. При таких обстоятельствах, мы будем просить
        письменные гарантии того, что персональные данные, переданные через этот
        веб-сайт, будут защищаться надлежащим образом.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Использование и раскрытие автоматической информации
      </Text>
      <Text>
        Автоматическая информация, не позволяющая установить личность, может
        быть использована, как описано выше или другими способами, в
        соответствии с действующим законодательством, в том числе в сочетании с
        персональными данными.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Доступ, обновление, исправление и уничтожение персональных данных
      </Text>
      <Text>
        Вы можете связаться с нами, чтобы обновить или внести исправления в
        информацию о вас. Если вы хотите отказаться от рассылки, на которую
        ранее были подписаны, пожалуйста, свяжитесь с нами по эл.
        почте: margaret@thequeenenglish.ru
      </Text>
      <Text>
        Вы вправе отозвать свое согласие на обработку ваших персональных данных.
        Для этого направьте требование по адресу электронной
        почты: margaret@thequeenenglish.ru
      </Text>
      <Text>
        Мы уничтожим ваши персональные данные, хранящиеся у нас, в течение 30
        дней со дня получения отзыва согласия.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Безопасность
      </Text>
      <Text>
        Мы принимаем разумные меры предосторожности, чтобы обеспечивать защиту
        личной информации, собранной через этот веб-сайт и используемой в
        соответствии с настоящим Положением. Мы имеем определенные физические,
        электронные, договорные и административные меры по защите безопасности и
        конфиденциальности вашей личной информации.
      </Text>
      <Text>
        В дополнение к техническим гарантиям, мы также используем
        неавтоматизированные средства и процедуры для защиты ваших персональных
        данных. Тем не менее, мы не можем гарантировать, полную безопасность от
        людей, которые могут попытаться уклониться от наших мер безопасности и
        перехватить операции по сети Интернет.
      </Text>
      <Text>
        Настоящее Положение применимо только к Сайтам, и Мы не контролируем и не
        несем ответственность за сайты третьих лиц, на которые пользователь
        может перейти по ссылкам, доступным на Сайтах. На таких ресурсах у
        пользователя может собираться или запрашиваться иная персональная
        информация, а также могут совершаться иные действия, которые не имеют
        отношения к нам.
      </Text>
      <Text>
        Сайты в общем случае не проверяют достоверность персональной информации,
        предоставляемой пользователями, и не осуществляют контроль за их
        дееспособностью. Однако мы исходим из того, что пользователь
        предоставляет достоверную и достаточную персональную информацию по
        вопросам, предлагаемым в формах ресурсов Сайтов, и поддерживают эту
        информацию в актуальном состоянии.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Изменения в Положении о конфиденциальности
      </Text>
      <Text>
        Мы можем в любое время изменять Положение о конфиденциальности в
        интернете, обновляя его. На вас распространяются любые такие изменения и
        поэтому вы должны периодически посещать эту страницу для ознакомления с
        самой последней версией Положения о конфиденциальности.
      </Text>
      <Text pt={4} fontWeight={'semibold'}>
        Вопросы
      </Text>
      <Text>
        Если у вас есть какие-либо вопросы в отношении Положения о
        конфиденциальности данного сайта в интернете или других аспектов
        обработки персональных данных на нашем сайте, пожалуйста, свяжитесь с
        нами по электронной почте margaret@thequeenenglish.ru или по телефону:
        +7 (926)078-36-37
      </Text>
    </VStack>
  )

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='lightGray'
        backBg='white'
      >
        <BlackPageHeader title='Положение о конфиденциальности' />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        pb={12}
      >
        {content}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Privacy
