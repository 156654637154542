import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  HStack,
  VStack
} from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  msg: string
  onReset?: () => void
}

const GeneralError: FC<Props> = ({ msg, onReset }) => {
  return (
    <Alert status='error' rounded='md'>
      <AlertIcon />
      <HStack color='red' spacing={4} w='full' justify={'space-between'}>
        <VStack align='flex-start' color='black' spacing={1}>
          <AlertTitle>Приозошла ошибка</AlertTitle>
          <AlertDescription fontSize={'sm'} lineHeight={1.4}>
            {msg}
          </AlertDescription>
        </VStack>
        {onReset && (
          <Box>
            <CloseButton color='black' onClick={onReset} />
          </Box>
        )}
      </HStack>
    </Alert>
  )
}

export default GeneralError
