import { useContext, useMemo } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import ContentContainer from 'shared/components/ContentContainer'
import find from 'lodash/find'
import UserEntityHeader from 'pages/userEntity/UserEntityHeader'
import { IDay } from 'shared/types'
import { useParams } from 'react-router-dom'
import EntityElementsContainer from 'components/EntityElementsContainer'
import EntityRowStatus from 'components/EntityRowStatus'
import get from 'lodash/get'
import has from 'lodash/has'

const UserEntityWeek = () => {
  const { entity, entityType, passedDays } = useContext(LessonsContext)
  const { weekId } = useParams() as { weekId: string }

  const week = useMemo(() => {
    if (entity && entity.content) {
      return find(entity.content, w => w.id === weekId)
    }
  }, [entity, weekId])

  const renderDay = (d: IDay) => {
    if (entity && week) {
      return (
        <EntityRowStatus
          key={d.id}
          title={d.title}
          toPath={`/profile/${entityType}/${entity.id}/${week.id}/${d.id}`}
          status={get(passedDays, d.id, false) ? 'success' : 'checkmark'}
          isDisabled={!has(passedDays, d.id)}
        />
      )
    }
  }

  const renderDays = () => {
    if (week && week.days) {
      return (
        <EntityElementsContainer>
          {week.days.map(renderDay)}
        </EntityElementsContainer>
      )
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        {entity && week && (
          <UserEntityHeader
            title={week.title || ''}
            backUrl={`/profile/${entityType}/${entity.id}`}
          />
        )}
      </ContentContainer>
      <ContentContainer
        bg='white'
        backBg='black.950'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={8}
        full
      >
        {renderDays()}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default UserEntityWeek
