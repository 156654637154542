import {
  onSnapshot,
  query,
  collection,
  where,
  documentId,
  getDocs
} from 'firebase/firestore'
import { db } from 'controllers/db'
import { ICourse } from 'shared/types'
import { receiveCourses } from 'model/actions'
import map from 'lodash/map'
import store from 'model/store'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'

export const dbFetchCourses = async () => {
  try {
    const q = query(collection(db, 'courses'), where('invisible', '==', false))
    onSnapshot(
      q,
      sn => {
        const res: Record<string, ICourse> = {}
        sn.forEach(doc => {
          const p = doc.data() as ICourse
          res[doc.id] = { ...p, id: doc.id }
        })
        store.dispatch(receiveCourses(res))
      },
      err => {
        console.log(`dbFetchCourses error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchCourses error', e)
  }
}

export const dbFetchCoursesList = async (coursesIds: string[]) => {
  try {
    const chunks = chunk(coursesIds, 10)
    const promises = map(chunks, async ids => {
      const q = query(collection(db, 'courses'), where(documentId(), 'in', ids))
      const sn = await getDocs(q)
      return sn.docs
    })
    const resAr = await Promise.all(promises)
    const docs = flatten(resAr)
    return keyBy(
      map(docs, doc => doc.data() as ICourse),
      'id'
    )
  } catch (e) {
    console.error('dbFetchCoursesList error', e)
    return null
  }
}
