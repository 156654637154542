import { onSnapshot, query, collection } from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { IPartition } from 'shared/types'
import { receivePartitions } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchPartitions = async () => {
  try {
    const q = query(collection(db, 'partitions'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IPartition> = {}
        sn.forEach(doc => {
          const p = doc.data() as IPartition
          res[doc.id] = p
        })
        store.dispatch(receivePartitions(res))
      },
      err => {
        console.log(`dbFetchPartitions error: ${err.message}`)
      }
    )
    addListener('partitions', unsubscribe)
  } catch (e) {
    console.error('dbFetchPartitions error', e)
  }
}
