import { useSelector } from 'model/hooks'
import Loading from 'components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import NotFound from 'shared/components/NotFound'
import get from 'lodash/get'
import compact from 'lodash/compact'
import { useContext } from 'react'
import MainContext from 'contexts/MainContext'
import { IEntityType, IItemInfo } from 'shared/types'
import { storageRef } from 'controllers/db'
import CoursePage from 'shared/pages/CoursePage'

const LibraryCourse = () => {
  const { courseId } = useParams()
  const { openPayment } = useContext(MainContext)
  const courses = useSelector(state => state.library)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const course = useSelector(
    state => state.library && courseId && state.library[courseId]
  )
  const userCourses = useSelector(state =>
    get(state.userEntities, 'library', {})
  )
  const teachers = useSelector(state => state.teachers)
  const navigate = useNavigate()

  const onPay = () => {
    if (course) {
      const itemInfo: IItemInfo = {
        title: course.title,
        id: course.id,
        type: IEntityType.LIBRARY,
        price: course.price,
        paymentLink: course.paymentLink
      }
      openPayment(itemInfo)
    }
  }

  if (courses === null) {
    return <Loading />
  } else if (!course) {
    return <NotFound message='Такой курс не существует' />
  } else {
    const tags = [
      course.partitionId
        ? get(partitions, [course.partitionId, 'name'])
        : undefined,
      course.levelId ? get(levels, [course.levelId, 'name']) : undefined,
      ...(course.tags || [])
    ]
    return (
      <CoursePage
        course={{ ...course, tags: compact(tags) }}
        onPay={onPay}
        toCourse={() => navigate(`/profile/library/${course.id}`)}
        userCourses={userCourses}
        storageRef={storageRef}
        teachers={teachers || {}}
        partitions={partitions}
      />
    )
  }
}

export default LibraryCourse
