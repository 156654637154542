import { onSnapshot, query, collection, where } from 'firebase/firestore'
import { db } from 'controllers/db'
import { IWebinar } from 'shared/types'
import { receiveWebinars } from 'model/actions'
import store from 'model/store'

export const dbFetchWebinars = async () => {
  try {
    const q = query(collection(db, 'webinars'), where('invisible', '==', false))
    onSnapshot(
      q,
      sn => {
        const res: Record<string, IWebinar> = {}
        sn.forEach(doc => {
          const p = doc.data() as IWebinar
          res[doc.id] = { ...p, id: doc.id }
        })
        store.dispatch(receiveWebinars(res))
      },
      err => {
        console.log(`dbFetchWebinars error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchWebinars error', e)
  }
}
