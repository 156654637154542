import {
  Divider,
  VStack,
  Text,
  Stack,
  Box,
  TextProps,
  Flex
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import BackButton from 'shared/components/BackButton'

type Props = {
  title?: string
  subtitle?: string
  description?: string
  videoId?: string
  hideDivider?: boolean
  titleProps?: TextProps
  backUrl?: string
  children?: ReactNode
}

const BlackPageHeader: FC<Props> = ({
  title,
  subtitle,
  description,
  videoId,
  hideDivider,
  titleProps,
  backUrl,
  children
}) => {
  const renderBackButton = () => {
    if (backUrl) {
      return <BackButton backUrl={backUrl} />
    }
  }

  return (
    <VStack
      w='full'
      // px={{ base: 4, lg: 8 }}
      pt={{ base: '72px', lg: '96px' }}
      pb={{ base: 4, lg: 8 }}
      align={'flex-start'}
      spacing={0}
    >
      {renderBackButton()}
      {title && (
        <Text
          as='h1'
          textStyle='h1'
          color='black.950'
          fontWeight={700}
          {...titleProps}
        >
          {title}
        </Text>
      )}
      {!hideDivider && <Divider mt={{ base: '2', lg: '4' }} />}
      {(subtitle || description || videoId) && (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          pt={{ base: 6, lg: 6 }}
          spacing={{ base: 2, lg: 6 }}
        >
          <Stack
            flex={1}
            spacing={videoId ? 2 : { base: 2, lg: 6 }}
            maxW={{ base: 'full', lg: videoId ? '800px' : 'full' }}
            direction={{ base: 'column', lg: videoId ? 'column' : 'row' }}
          >
            {subtitle && (
              <Flex flex={1}>
                <Text textStyle='h3'>{subtitle}</Text>
              </Flex>
            )}
            <Flex flex={1} maxW={{ base: 'full', lg: '60%' }}>
              <Text textStyle='body' color='black.500'>
                {description}
              </Text>
            </Flex>
          </Stack>
          {videoId && (
            <Box
              flex={1}
              borderRadius={'8px'}
              sx={{ iframe: { borderRadius: '8px' } }}
            >
              <Vimeo
                video={'115783408'}
                responsive
                onError={e => console.log(e)}
              />
            </Box>
          )}
        </Stack>
      )}
      {children}
    </VStack>
  )
}

export default BlackPageHeader
