import { initializeApp } from 'firebase/app'
import { doc, collection, initializeFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getStorage, ref } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'

import config from 'shared/config'

const firebaseApp = initializeApp(config)

export const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
})
export const auth = getAuth()
export const functions = getFunctions(firebaseApp, 'europe-central2')
export const storage = getStorage(firebaseApp)
export const storageRef = (refer: string) => ref(storage, refer)

export const generateId = (): string => {
  return doc(collection(db, 'tmp')).id
}

export const analytics = getAnalytics(firebaseApp)
