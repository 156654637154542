import { useState, useRef, FC } from 'react'
import { Alert, AlertIcon, Button, VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { IItemInfo, IUser } from 'shared/types'
import AuthTitle from 'components/auth/AuthTitle'
import numeral from 'numeral'
import StyledButton from 'components/StyledButton'
import Auth from 'components/Auth'
import SimpleAgreement from 'components/auth/SimpleAgreement'
import ProdamusModal, { IProdamusModal } from 'modals/ProdamusModal'
import PayanywayModalContent from 'modals/payanywayModal/PayanywayModalContent'

export type IPaymentModalContent = {
  getErrors: () => Record<string, string>
  signUp: () => Promise<IUser | undefined>
}

type Props = {
  onClose?: () => void
  itemInfo: IItemInfo
  navigateOnSucess: () => void
}

const PaymentModalContent: FC<Props> = ({ itemInfo, navigateOnSucess }) => {
  const [isPaid, setIsPaid] = useState(false)
  const authData = useSelector(state => state.authData)
  const prodamusModalRef = useRef<IProdamusModal>(null)
  const [showPayanyway, setShowPayanyway] = useState(false)

  const openPaymentDialog = async (
    userId: string,
    email: string,
    isRub: boolean
  ) => {
    console.log('openPaymentDialog', userId, isRub)
    if (itemInfo && authData) {
      if (isRub) {
        console.log('open payanyway')
        setShowPayanyway(true)
        // payanywayModalRef.current?.open({
        //   ...itemInfo,
        //   successPath: `/profile/${itemInfo.type}/${itemInfo.id}`
        // })
      } else {
        prodamusModalRef.current?.open({
          ...itemInfo,
          successPath: `/profile/${itemInfo.type}/${itemInfo.id}`
        })
      }
    }
  }

  const apply = async (isRub: boolean) => {
    if (authData?.uid) {
      openPaymentDialog(authData.uid, authData.email || '', isRub)
    }
  }

  const renderButtons = () => {
    if (itemInfo) {
      return (
        <VStack w='full'>
          {itemInfo.price > 0 && (
            <Button w='full' variant={'primary'} onClick={() => apply(true)}>
              Оплатить российской картой
            </Button>
          )}
          {itemInfo.price > 0 &&
            itemInfo.paymentLink &&
            itemInfo.paymentLink !== '' && (
              <Button
                w='full'
                variant={'outline'}
                // onClick={() => apply(false)}
                onClick={() => window.open(itemInfo.paymentLink, '_blank')}
              >
                Оплатить зарубежной картой
              </Button>
            )}
        </VStack>
      )
    }
  }

  const renderPaymentPage = () => {
    if (itemInfo) {
      return (
        <VStack w='full' flexShrink={0} align='flex-start' spacing={6} p={6}>
          <AuthTitle
            title={itemInfo.title}
            subTitle={numeral(itemInfo.price).format('0 0[.]00') + '₽'}
          />
          <SimpleAgreement />
          {renderButtons()}
        </VStack>
      )
    }
  }

  const renderContent = () => {
    if (isPaid && itemInfo) {
      return (
        <VStack w='full' flexShrink={0} align='flex-start' spacing={6} p={6}>
          <AuthTitle
            title={itemInfo.title}
            subTitle={numeral(itemInfo.price).format('0 0[.]00') + '₽'}
          />
          <Alert status='success' rounded='md'>
            <AlertIcon />
            Оплата прошла успешно
          </Alert>
          <StyledButton w='full' onClick={() => navigateOnSucess()}>
            Перейти в Аккаунт
          </StyledButton>
        </VStack>
      )
    } else if (showPayanyway && itemInfo) {
      return (
        <PayanywayModalContent
          itemInfo={itemInfo}
          onSuccess={() => setIsPaid(true)}
        />
      )
    } else if (!authData && itemInfo) {
      return (
        <Auth
          onAuth={() => null}
          title={itemInfo.title}
          subTitle={numeral(itemInfo.price).format('0 0[.]00') + '₽'}
          description='Чтобы оплатить заказ, войдите в аккаунт или зарегистрируйтесь'
        />
      )
    } else if (itemInfo) {
      return renderPaymentPage()
    } else {
      return null
    }
  }

  return (
    <>
      {renderContent()}
      {/* <PayanywayModal
        ref={payanywayModalRef}
        onSuccess={() => setIsPaid(true)}
      /> */}
      <ProdamusModal ref={prodamusModalRef} />
    </>
  )
}

export default PaymentModalContent
