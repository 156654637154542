import { onSnapshot, query, collection } from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { ITeacher } from 'shared/types'
import { receiveTeachers } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchTeachers = async () => {
  try {
    const q = query(collection(db, 'teachers'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ITeacher> = {}
        sn.forEach(doc => {
          const p = doc.data() as ITeacher
          res[doc.id] = p
        })
        store.dispatch(receiveTeachers(res))
      },
      err => {
        console.log(`dbFetchTeachers error: ${err.message}`)
      }
    )
    addListener('teachers', unsubscribe)
  } catch (e) {
    console.error('dbFetchTeachers error', e)
  }
}
