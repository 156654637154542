import { HStack, VStack, Text, Flex } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/crrown.svg'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()

  return (
    <VStack
      bg='black.950'
      w='full'
      px={{ base: 4, lg: 8 }}
      py={{ base: 2, lg: '10px' }}
      borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      position={'fixed'}
      top='0'
      zIndex={1}
      align={'flex-start'}
    >
      <HStack spacing={2} as='button' onClick={() => navigate('/')}>
        <Flex
          h={{ base: '22px', lg: 7 }}
          w={{ base: '22px', lg: 7 }}
          bg='white'
          rounded={'full'}
          justify={'center'}
          align='center'
          px={0.5}
        >
          <Logo />
        </Flex>
        <Text
          color='white'
          fontWeight={'500'}
          fontSize={{ base: 'xl', lg: 'lg' }}
          lineHeight={{ lg: '28px' }}
          letterSpacing={{ base: '-1px', lg: '-0.9px' }}
        >
          thequeenenglish
        </Text>
      </HStack>
    </VStack>
  )
}

export default Header
