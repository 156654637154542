import {
  onSnapshot,
  query,
  collection,
  where,
  getDocs,
  documentId,
  doc,
  getDoc
} from 'firebase/firestore'
import { db } from 'controllers/db'
import { functions } from 'controllers/db'
import { httpsCallable } from 'firebase/functions'
import {
  IConference,
  ICourse,
  IEntityType,
  ISeminar,
  IUserPayment,
  IWebinar,
  IFree,
  IPaymentLink
} from 'shared/types'
import {
  receiveUserPayments,
  receiveUserCourses,
  receiveUserWebinars,
  receiveUserSeminars,
  receiveUserConferences,
  receiveUserLibraryCourses,
  receiveUserFrees
} from 'model/actions'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import forEach from 'lodash/forEach'

const fetchList = async (k: string, allIds: string[]) => {
  // console.log('fetch ids', k, allIds)
  const chunks = chunk(allIds, 10)
  const promises = map(chunks, async ids => {
    const q = query(collection(db, k), where(documentId(), 'in', ids))
    const sn = await getDocs(q)
    return sn.docs
  })
  const resAr = await Promise.all(promises)
  return flatten(resAr)
}

const fetchEntities = async (userPayments: Record<string, IUserPayment>) => {
  const byType = groupBy(userPayments, 'type')
  console.log('fetchEntities byType', byType)
  forEach(byType, async (upayments, k) => {
    const ids = map(upayments, up => up.entityId)
    if (ids.length > 0) {
      const docs = await fetchList(k, ids)
      switch (k) {
        case IEntityType.COURSES: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as ICourse)),
            'id'
          )
          store.dispatch(receiveUserCourses(d))
          break
        }
        case IEntityType.FREE: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as IFree)),
            'id'
          )
          store.dispatch(receiveUserFrees(d))
          break
        }
        case IEntityType.WEBINARS: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as IWebinar)),
            'id'
          )
          store.dispatch(receiveUserWebinars(d))
          break
        }
        case IEntityType.SEMINARS: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as ISeminar)),
            'id'
          )
          store.dispatch(receiveUserSeminars(d))
          break
        }
        case IEntityType.CONFERENCES: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as IConference)),
            'id'
          )
          store.dispatch(receiveUserConferences(d))
          break
        }
        case IEntityType.LIBRARY: {
          const d = keyBy(
            map(docs, doc => ({ id: doc.id, ...doc.data() } as ICourse)),
            'id'
          )
          store.dispatch(receiveUserLibraryCourses(d))
          break
        }
      }
    } else {
      return null
    }
  })
}

export const dbFetchUserPayments = async (userId: string) => {
  try {
    const q = query(
      collection(db, 'userPayments'),
      where('userId', '==', userId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IUserPayment> = {}
        sn.forEach(doc => {
          const p = doc.data() as IUserPayment
          res[doc.id] = { ...p, id: doc.id } as IUserPayment
        })
        store.dispatch(receiveUserPayments(res))
        fetchEntities(res)
      },
      err => {
        console.log(`dbFetchUserPayments error: ${err.message}`)
      }
    )
    addListener('userPayments', unsubscribe)
  } catch (e) {
    console.error('dbFetchUserPayments error', e)
  }
}

export const buyFreeCourse = async (entityId: string) => {
  try {
    const f = httpsCallable(functions, 'buyFreeCourse')
    const res = await f({ entityId })
    console.log('buyFreeCourse function res:', res)
    return res.data
  } catch (e) {
    console.error('buyFreeCourse error', e)
    return null
  }
}

export const dbFetchPaymentLink = async (linkId: string) => {
  try {
    const ref = doc(db, 'paymentLinks', linkId)
    const sn = await getDoc(ref)
    return sn.data() as IPaymentLink | null
  } catch (e) {
    console.error('dbFetchPaymentLink error', e)
    return null
  }
}
