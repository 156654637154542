import { createSelector } from '@reduxjs/toolkit'
import { getUserPayments } from 'model/selectors/base'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import { IEntityType } from 'shared/types'

export const getPaymentsByType = createSelector(
  [getUserPayments],
  userPayments => {
    return groupBy(userPayments, 'type')
  }
)

export const getPaymentsByEntityId = createSelector(
  [getUserPayments],
  userPayments => {
    return keyBy(userPayments, 'entityId')
  }
)
export const getSortedLibraryCoursesPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.LIBRARY, []),
      'createdAt',
      'desc'
    )
  }
)
export const getSortedFreeCoursesPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.FREE, []),
      'createdAt',
      'desc'
    )
  }
)

export const getSortedCoursesPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.COURSES, []),
      'createdAt',
      'desc'
    )
  }
)
export const getSortedWebinarsPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.WEBINARS, []),
      'createdAt',
      'desc'
    )
  }
)
export const getSortedSeminarsPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.SEMINARS, []),
      'createdAt',
      'desc'
    )
  }
)
export const getSortedConferencesPayments = createSelector(
  [getPaymentsByType],
  paymentsByType => {
    return orderBy(
      get(paymentsByType, IEntityType.CONFERENCES, []),
      'createdAt',
      'desc'
    )
  }
)
