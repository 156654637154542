import { createReducer } from '@reduxjs/toolkit'
import { IUserEntities } from 'shared/types'
import {
  receiveUserCourses,
  receiveUserSeminars,
  receiveUserWebinars,
  receiveUserConferences,
  receiveUserLibraryCourses,
  receiveUserFrees
} from 'model/actions'

const initialState: IUserEntities = {
  courses: {},
  seminars: {},
  webinars: {},
  conferences: {},
  library: {},
  free: {}
}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveUserCourses, (state, action) => {
      state.courses = action.payload
    })
    .addCase(receiveUserSeminars, (state, action) => {
      state.seminars = action.payload
    })
    .addCase(receiveUserWebinars, (state, action) => {
      state.webinars = action.payload
    })
    .addCase(receiveUserConferences, (state, action) => {
      state.conferences = action.payload
    })
    .addCase(receiveUserLibraryCourses, (state, action) => {
      state.library = action.payload
    })
    .addCase(receiveUserFrees, (state, action) => {
      state.free = action.payload
    })
})

export default reducer
