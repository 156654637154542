import { useMemo, FC } from 'react'
import {
  GridItem,
  useBreakpointValue,
  Image,
  Text,
  VStack,
  Stack,
  Grid
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getSortedLibraryCourses } from 'model/selectors/libraray'
import take from 'lodash/take'
import { ICourse } from 'shared/types'
import lessonDefault from 'shared/assets/lesson-default.png'
import { useNavigate } from 'react-router-dom'

type Props = {
  limit?: number
}

const LessonsCards: FC<Props> = ({ limit }) => {
  const navigate = useNavigate()
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const libraryCourses = useSelector(getSortedLibraryCourses)

  const cardsAmount = useBreakpointValue({
    base: limit ? limit : undefined,
    lg: limit ? limit * 2 : undefined
  })

  const columnsAmount = useBreakpointValue({
    base: 2,
    lg: 4
  })

  const courses = useMemo(() => {
    if (cardsAmount) {
      return take(libraryCourses, cardsAmount)
    } else {
      return libraryCourses
    }
  }, [libraryCourses, cardsAmount])

  const renderCard = (l: ICourse, i: number) => {
    const p =
      partitions && l.partitionId ? partitions[l.partitionId] : undefined
    const level = levels && l.levelId ? levels[l.levelId] : undefined
    return (
      <GridItem
        key={i}
        onClick={() => navigate(`/library/${l.id}`)}
        _hover={{ cursor: 'pointer' }}
      >
        <VStack align='flex-start' spacing={0}>
          <Image
            boxSize={'full'}
            // boxSize={{ base: 'calc(50vw - 20px)', lg: '286px' }}
            src={l.imageUrl || lessonDefault}
            rounded={'xs'}
          />
          <Text
            textStyle={'small'}
            pt={3}
            // w={{ base: 'calc(50vw - 20px)', lg: '286px' }}
            // fontSize={{ base: 'sm', lg: 'sm' }}
            // lineHeight={'119%'}
            // letterSpacing={'-0.14px'}
            // fontWeight={{ base: 'medium', lg: '450' }}
          >
            {l.title}
          </Text>
          <Stack
            textStyle={'small'}
            color='darkGray'
            pt={1}
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 0, lg: 4 }}
          >
            <Text as='span'>{p?.name}</Text>
            <Text>{level?.name}</Text>
          </Stack>
        </VStack>
      </GridItem>
    )
  }

  return (
    <Grid templateColumns={`repeat(${columnsAmount}, 1fr)`} gap={6} w='full'>
      {courses.map(renderCard)}
    </Grid>
  )
}

export default LessonsCards
