import { Box, Divider, VStack } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const EntityElementsContainer: FC<Props> = ({ children }) => {
  return (
    <Box
      rounded={'xs'}
      w={{ base: 'full', lg: '596px' }}
      px={{ base: 6, lg: 8 }}
      py={{ base: 8, lg: 12 }}
      bg='wood.50'
    >
      <Divider />
      <VStack divider={<Divider />} spacing={0}>
        {children}
      </VStack>
      <Divider />
    </Box>
  )
}
export default EntityElementsContainer
