import { VStack, Image, AspectRatio, Text, HStack } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  imageUrl: string
  title: string
  details: string[]
  onClick?: () => void
}

const BigCard: FC<Props> = ({ imageUrl, title, details, onClick }) => {
  return (
    <VStack
      w='full'
      spacing={3}
      align={'flex-start'}
      as={onClick ? 'button' : undefined}
      onClick={onClick}
    >
      <AspectRatio w='full' ratio={16 / 9}>
        <Image src={imageUrl} objectFit='fill' p={0} borderRadius={'xs'} />
      </AspectRatio>
      <VStack spacing={1} align='flex-start'>
        <Text textStyle={'small'} noOfLines={1} textAlign={'start'}>
          {title}
        </Text>
        <HStack spacing={2}>
          {details.map(v => (
            <Text textStyle='small' color='darkGray' key={v}>
              {v}
            </Text>
          ))}
        </HStack>
      </VStack>
    </VStack>
  )
}

export default BigCard
