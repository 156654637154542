import { useMemo } from 'react'
import { VStack, Text, Button } from '@chakra-ui/react'
import BlackPageHeader from 'components/BlackPageHeader'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { useSelector } from 'model/hooks'
import { getPaymentsByType } from 'model/selectors/userPayments'
import orderBy from 'lodash/orderBy'
import CourseRow from 'pages/user/CourseRow'
import { useNavigate, useParams } from 'react-router-dom'
import {
  IConference,
  ICourse,
  IEntityType,
  ISeminar,
  IWebinar
} from 'shared/types'
import { getAllUserEntities } from 'model/selectors/entities'
import { ReactComponent as ChevronRightIcon } from 'shared/assets/bsChevronRight.svg'
import { getSortedFreeCourses } from 'model/selectors/free'

const TITLE = {
  [IEntityType.COURSES]: 'Мои Курсы',
  [IEntityType.WEBINARS]: 'Мои Вебинары',
  [IEntityType.SEMINARS]: 'Мои Семинары',
  [IEntityType.CONFERENCES]: 'Мои Конференции',
  [IEntityType.LIBRARY]: 'Моя Библиотека',
  [IEntityType.FREE]: 'Бесплатное',
  [IEntityType.PAYMENT_LINKS]: ''
}

const UserEntitites = () => {
  const { entityType } = useParams() as { entityType: IEntityType }
  const navigate = useNavigate()
  const paymentsByType = useSelector(getPaymentsByType)
  const userEntities = useSelector(getAllUserEntities)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const frees = useSelector(getSortedFreeCourses)

  const payments = useMemo(() => {
    return orderBy(get(paymentsByType, entityType, []), 'createdAt', 'desc')
  }, [paymentsByType, entityType])

  const getEntityDescription = (
    c: ICourse | ISeminar | IWebinar | IConference
  ) => {
    switch (entityType) {
      case IEntityType.LIBRARY: {
        const course = c as ICourse
        return course.partitionId
          ? get(partitions, [course.partitionId, 'name'], '')
          : ''
      }
      case IEntityType.CONFERENCES:
      case IEntityType.WEBINARS:
      case IEntityType.SEMINARS: {
        const startDate = c.startDate
        if (startDate) {
          return dayjs(startDate).format('D MMMM')
        } else {
          return ''
        }
      }
      case IEntityType.COURSES: {
        return ''
      }
      default:
        return ''
    }
  }

  const getEntityDescription2 = (
    c: ICourse | ISeminar | IWebinar | IConference
  ) => {
    switch (entityType) {
      case IEntityType.LIBRARY: {
        const course = c as ICourse
        return course.levelId ? get(levels, [course.levelId, 'name'], '') : ''
      }
      case IEntityType.COURSES: {
        const course = c as ICourse
        const startDate = course.startDate
        const endDate = course.endDate
        if (startDate && endDate) {
          return `${dayjs(startDate).format('D MMMM')} - ${dayjs(
            endDate
          ).format('D MMMM')}`
        } else {
          return ''
        }
      }
      case IEntityType.CONFERENCES:
      case IEntityType.SEMINARS:
      case IEntityType.WEBINARS: {
        const startDate = c.startDate
        const endDate = c.endDate
        if (startDate && endDate) {
          return `${dayjs(startDate).format('HH:mm')} - ${dayjs(endDate).format(
            'HH:mm'
          )}, ${dayjs.tz.guess()}`
        } else {
          return ''
        }
      }

      default:
        return ''
    }
  }

  const renderEntities = () => {
    if (entityType === IEntityType.FREE) {
      return frees.map(c => {
        return (
          <CourseRow
            key={c.id}
            title={c.title}
            description={getEntityDescription(c)}
            description2={getEntityDescription2(c)}
            onClick={() => navigate(`/profile/${entityType}/${c.id}`)}
          />
        )
      })
    } else {
      return payments.map(up => {
        const c = userEntities[up.entityId]
        if (c) {
          return (
            <CourseRow
              key={c.id}
              title={c.title}
              description={getEntityDescription(c)}
              description2={getEntityDescription2(c)}
              onClick={() => navigate(`/profile/${entityType}/${c.id}`)}
            />
          )
        } else {
          return null
        }
      })
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg={'white'}
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        <BlackPageHeader
          backUrl='/profile'
          title={TITLE[entityType]}
          hideDivider={entityType !== IEntityType.LIBRARY}
          description={
            entityType === IEntityType.LIBRARY
              ? 'По всем курсам из Библиотеки мы регулярно проводим Семинары, где студенты могут прорабоать материал с преподавателем и задать вопросы по тематике курса. Количество мест на каждом Семинаре ограничено.'
              : undefined
          }
          titleProps={{ textStyle: 'h2' }}
        >
          {entityType === IEntityType.LIBRARY && (
            <Button
              mt={2}
              variant={'link'}
              color='wood.600'
              rightIcon={<ChevronRightIcon height={12} width={12} />}
              onClick={() => navigate('/seminars')}
            >
              <Text textStyle={'small'} color='wood.600'>
                Смотреть расписание Семинаров
              </Text>
            </Button>
          )}
        </BlackPageHeader>
      </ContentContainer>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        full
        minH='80vh'
      >
        <VStack w='full' align='flex-start' spacing={4}>
          {renderEntities()}
        </VStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default UserEntitites
