import { VStack, Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  title: string
  subTitle?: string
}

const AuthTitle: FC<Props> = ({ title, subTitle }) => {
  return (
    <VStack spacing={1} w='full' align='flex-start'>
      <Text color='black' textStyle='h3' fontWeight={700}>
        {title}
      </Text>
      {subTitle && (
        <Text color='black' textStyle='h3'>
          {subTitle}
        </Text>
      )}
    </VStack>
  )
}

export default AuthTitle
