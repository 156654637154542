import { configureStore } from '@reduxjs/toolkit'

import authData from 'model/reducers/authData'
import courses from 'model/reducers/courses'
import webinars from 'model/reducers/webinars'
import library from 'model/reducers/library'
import userPayments from 'model/reducers/userPayments'
import userEntities from 'model/reducers/userEntities'
import user from 'model/reducers/user'
import partitions from 'model/reducers/partitions'
import levels from 'model/reducers/levels'
import teachers from 'model/reducers/teachers'
import seminars from 'model/reducers/seminars'
import frees from 'model/reducers/frees'
import coursesPacks from 'model/reducers/coursesPacks'
import channels from 'model/reducers/channels'
import cardTags from 'model/reducers/cardTags'
import wordCards from 'model/reducers/wordCards'

const store = configureStore({
  reducer: {
    authData,
    courses: courses,
    webinars,
    library,
    userPayments,
    userEntities,
    user,
    partitions,
    levels,
    teachers,
    seminars,
    channels,
    frees,
    coursesPacks,
    cardTags,
    wordCards
  }
})
export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
