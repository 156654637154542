import {
  AuthError,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  AuthCredential,
  EmailAuthProvider,
  updatePassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import { auth, db } from 'controllers/db'
import { clearListeners } from 'controllers/listeners'
import store from 'model/store'
import { logout } from 'model/actions'
import { IUser } from 'shared/types'
import { doc, setDoc } from 'firebase/firestore'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'

export const dbSignIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    return { authUser }
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    return { error: er.code }
  }
}

export const dbResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (e) {
    console.warn('password reset email was not sent', e)
    return false
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      console.log('%cLOGOUT', 'color: orange;')
      clearListeners()
      store.dispatch(logout())
      await signOut(auth)
    } else {
      navigate('/login')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}

export const dbSignUp = async (
  name: string,
  email: string,
  password: string,
  phone: string,
  telegram?: string
) => {
  console.log('dbSignUp name', name, 'email', email, 'password', password)
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    const userId = authUser.uid
    const user: IUser = {
      id: userId,
      name,
      email,
      phone,
      createdAt: +dayjs()
    }
    if (!isEmpty(telegram)) {
      user.telegram = telegram
    }
    const ref = doc(db, `users/${userId}`)
    await setDoc(ref, user, { merge: true })
    return { user, authUser }
  } catch (e) {
    console.log('dbSignUp error', e)
    const er = e as AuthError
    console.log('err code:', er.code)
    console.log('err message:', er.message)
    return { error: er.code }
  }
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  try {
    const userCredential = await signInWithPopup(auth, provider)
    GoogleAuthProvider.credentialFromResult(userCredential)
    console.log('signInWithGoogle result user', userCredential.user)
    const authUser = userCredential.user
    const userId = authUser.uid
    const user: IUser = {
      id: userId,
      name: authUser.displayName || '',
      email: authUser.email || '',
      phone: authUser.phoneNumber || '',
      createdAt: +dayjs()
    }
    const ref = doc(db, `users/${userId}`)
    await setDoc(ref, user, { merge: true })
    return { user, authUser }
  } catch (e) {
    console.log('signInWithGoogle error', e)
    const er = e as AuthError
    console.log('err code:', er.code)
    console.log('err message:', er.message)
    return { error: er.code }
  }
}

export const dbChangePassword = async (
  passowrd: string,
  newPassword: string
) => {
  const user = auth.currentUser
  if (user && user.email) {
    try {
      const credential: AuthCredential = EmailAuthProvider.credential(
        user.email,
        passowrd
      )
      const userCredential = await reauthenticateWithCredential(
        user,
        credential
      )
      console.log('userCredential', userCredential)
      await updatePassword(user, newPassword)
      return null
    } catch (e) {
      const er = e as AuthError
      console.log('error', er.code, er.message)
      return er.code
    }
  } else {
    console.log('user is not authorized')
    return 'auth/user-not-found'
  }
}
