import { createSelector } from '@reduxjs/toolkit'
import { getSeminars } from 'model/selectors/base'
import has from 'lodash/has'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import { getPaymentsByType } from 'model/selectors/userPayments'
import sortBy from 'lodash/sortBy'
import dayjs from 'dayjs'

export const getAvailableSeminars = createSelector(
  [getPaymentsByType, getSeminars],
  (paymentsByType, seminars) => {
    const libraryPayments = paymentsByType.library
    const courses = keyBy(libraryPayments, 'entityId')
    // const now = +dayjs()
    // const dayNum = now.day() === 0 ? 6 : now.day() - 1
    // const curMonth = now.month()
    // const startWeekDate = now
    //   .subtract(dayNum, 'days')
    //   .set('hour', 0)
    //   .set('minutes', 0)
    //   .set('second', 0)
    // const prevMonth = startWeekDate.month()

    const neededSeminars = filter(seminars, s => {
      if (
        s.startDate === undefined ||
        (s.courseId !== undefined && !has(courses, s.courseId))
      ) {
        return false
      } else {
        // const d = dayjs(s.startDate)
        // const seminarMonth = d.month()
        // const monthValid =
        //   seminarMonth === prevMonth || seminarMonth === curMonth
        return s.startDate > +dayjs()
      }
    })
    return sortBy(neededSeminars, 'startDate')
  }
)
