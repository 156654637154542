import { useSelector } from 'model/hooks'
import { getSortedCourses } from 'model/selectors/courses'
import InfoSection from 'pages/home/InfoSection'
import ServiceSection from 'pages/home/ServiceSection'
import { ICourse, ICoursesPack, IWebinar } from 'shared/types'
import courseDefault from 'shared/assets/course-default.png'
import webinarDefault from 'shared/assets/webinar-default.png'
import { getSortedWebinars } from 'model/selectors/webinars'
import { useNavigate } from 'react-router-dom'
import ContentContainer from 'shared/components/ContentContainer'
import { Divider, Show, Text, VStack, Button } from '@chakra-ui/react'
import take from 'lodash/take'
import map from 'lodash/map'
import has from 'lodash/has'
import compact from 'lodash/compact'
import Cards, { ICard } from 'components/Cards'
import { getSortedLibraryCourses } from 'model/selectors/libraray'
import { getSortedFreeCourses } from 'model/selectors/free'

const Home = () => {
  const navigate = useNavigate()
  const courses: (ICourse | ICoursesPack)[] = useSelector(getSortedCourses)
  const webinars: IWebinar[] = useSelector(getSortedWebinars)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const libraryCourses = useSelector(getSortedLibraryCourses)
  const freeCourses = useSelector(getSortedFreeCourses)

  const renderCourses = () => {
    const cards = map(take(courses, 2), c => {
      const p =
        partitions && c.partitionId ? partitions[c.partitionId] : undefined
      const level = levels && c.levelId ? levels[c.levelId] : undefined
      const card: ICard = {
        imageUrl: c.imageUrl || courseDefault,
        onClick: () =>
          has(c, 'plans')
            ? navigate(`/tariffs/${c.id}`)
            : navigate(`/courses/${c.id}`),
        title: c.title,
        desc: compact([p?.name, level?.name])
      }
      return card
    })
    return (
      <>
        <Show above='lg'>
          <Cards cards={cards} amountPerRow={2} aspectRatio={16 / 9} />
        </Show>
        <Show below='lg'>
          <Cards cards={cards} amountPerRow={1} aspectRatio={16 / 9} />
        </Show>
      </>
    )
  }

  const renderLibraryCourses = () => {
    const cards = map(take(libraryCourses, 8), c => {
      const p =
        partitions && c.partitionId ? partitions[c.partitionId] : undefined
      const level = levels && c.levelId ? levels[c.levelId] : undefined
      const card: ICard = {
        imageUrl: c.imageUrl || courseDefault,
        onClick: () => navigate(`/library/${c.id}`),
        title: c.title,
        desc: compact([p?.name, level?.name])
      }
      return card
    })
    return (
      <>
        <Show above='lg'>
          <Cards cards={cards} amountPerRow={4} aspectRatio={1} />
        </Show>
        <Show below='lg'>
          <Cards cards={cards} amountPerRow={2} aspectRatio={1} />
        </Show>
      </>
    )
  }

  const renderFreeCourses = () => {
    const cards = map(take(freeCourses, 8), c => {
      const p =
        partitions && c.partitionId ? partitions[c.partitionId] : undefined
      const level = levels && c.levelId ? levels[c.levelId] : undefined
      const card: ICard = {
        imageUrl: c.imageUrl || courseDefault,
        onClick: () => navigate(`/free/${c.id}`),
        title: c.title,
        desc: compact([p?.name, level?.name])
      }
      return card
    })
    return (
      <>
        <Show above='lg'>
          <Cards cards={cards} amountPerRow={4} aspectRatio={1} />
        </Show>
        <Show below='lg'>
          <Cards cards={cards} amountPerRow={2} aspectRatio={1} />
        </Show>
      </>
    )
  }

  const renderWebinar = () => {
    const cards = map(take(webinars, 2), c => {
      const p =
        partitions && c.partitionId ? partitions[c.partitionId] : undefined
      const level = levels && c.levelId ? levels[c.levelId] : undefined
      const card: ICard = {
        imageUrl: c.imageUrl || webinarDefault,
        onClick: () => navigate(`/webinars/${c.id}`),
        title: c.title,
        desc: compact([p?.name, level?.name])
      }
      return card
    })
    return <Cards cards={cards} amountPerRow={1} aspectRatio={16 / 9} />
  }

  return (
    <>
      <InfoSection />
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
      >
        <VStack w='full' py={{ base: 6, lg: 8 }} spacing={{ base: 2, lg: 4 }}>
          <ServiceSection
            title='Бесплатное'
            buttons={
              <Button
                variant={'primary'}
                onClick={() => navigate('/profile')}
                w={{ base: 'full', lg: 'auto' }}
                size={{ base: 'sm', lg: 'md' }}
              >
                Зарегистрироваться / Войти
              </Button>
            }
            buttonTitle='Смотреть все'
            buttonProps={{
              variant: 'outline',
              rounded: 'full',
              size: { base: 'sm', lg: 'md' }
              // borderColor: 'black'
            }}
            path='free'
            desc='Раздел «Бесплатное» — это уникальная возможность пройти демо версии всех наших онлайн-курсов, а также интересные уроки на различные темы'
            desc2='Вам предоставляется доступ к 1-2 неделям каждого курса: обучающим материалам, включая видео лекции, пробные уроки, практические задания и тесты. Вы абсолютно бесплатно можете попробовать все наши инструменты на платформе и затем записаться на понравившиеся вам курсы, которые наилучшим образом соответствуют вашим интересам, учебным целям и профессиональным потребностям.'
          />
          <Divider mb={2} />
          {renderFreeCourses()}
        </VStack>

        <VStack w='full' py={{ base: 6, lg: 8 }} spacing={{ base: 2, lg: 4 }}>
          <ServiceSection
            title='Библиотека'
            buttonTitle='Все курсы библиотеки'
            path='library'
            desc='Библиотека — это обновляемая коллекция онлайн-курсов на различные темы для самостоятельного прохождения в любое время'
            desc2='Вам предоставляются обучающие материалы, включая видео-лекции, текстовые уроки, практические задания и тесты. Вы можете выбирать курсы, которые наилучшим образом соответствуют вашим интересам, учебным целям и профессиональным потребностям.'
          />
          <Divider mb={2} />
          {renderLibraryCourses()}
        </VStack>

        <VStack w='full' py={8} spacing={6}>
          <ServiceSection
            title='Семинары'
            buttonTitle='Больше о семинарах'
            path='seminars'
            desc='Семинары — это отличная возможность позаниматься с преподавателем в удобное для вас время'
            desc2='Занятия проходят онлайн в группах до 10 человек. Записаться на семинар можно только по теме курса, который был куплен в Библиотеке. '
          />
        </VStack>
      </ContentContainer>
      <VStack maxW='802px' py={14} px={{ base: '4', lg: 0 }} bg='wood.100'>
        <Text
          maxW='556px'
          textStyle='h3'
          fontWeight={700}
          textAlign={'center'}
          color='sun.950'
        >
          Библиотека и Семинары позволяют гибко планировать свое обучение
        </Text>
        <Text color='black.400' textStyle={'h3'} textAlign={'center'}>
          Вы можете изучать курсы в удобное для вас время и отрабатывать знания
          на семинарах, в своем собственном темпе. Это особенно удобно для тех,
          кто имеет ограниченное время
        </Text>
      </VStack>
      <ContentContainer
        borderTopRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        py={8}
      >
        <VStack w='full' spacing={{ base: 2, lg: 4 }}>
          <ServiceSection
            title='Курсы'
            buttonTitle='Ближайшие курсы'
            path='courses'
            desc='Наши онлайн-курсы предоставляют уникальную возможность прокачать свой акцент и голос под руководством опытных преподавателей'
            desc2='Мы следуем строгому учебному плану с конкретными датами начала и окончания занятий. Курсы разработаны таким образом, чтобы обеспечить структурированное и последовательное изучение. Вы будете присоединяться к группе студентов, которые начинают курс вместе в определенное время.'
          />
          <Divider mb={2} />
          {renderCourses()}
        </VStack>
      </ContentContainer>

      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        py={{ base: 6, lg: 8 }}
      >
        <VStack w='full' spacing={{ base: 2, lg: 4 }}>
          <ServiceSection
            title='Вебинары'
            buttonTitle='Ближайшие вебинары'
            path='webinars'
            desc='Вебинары — это отличная возможность посмотреть в прямом эфире, как переподаватели разбирают по полочкам сложные темы понятным языком'
            desc2='Вебинары проводят опытные преподаватели и специалисты в различных областях знаний. Они подготавливают информативные презентации и демонстрируют живые примеры, что помогает студентам лучше понять материал и усвоить сложные концепции.'
          />
          <Divider mb={2} />
          {renderWebinar()}
        </VStack>
      </ContentContainer>
      {/* <AuthModal ref={authModalRef} onAuth={onAuth} /> */}
    </>
  )
}

export default Home
