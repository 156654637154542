import PageContentWrapper from 'shared/components/PageContentWrapper'
import { Divider, Text, VStack, Flex } from '@chakra-ui/react'
import ContactForm from 'shared/components/ContactForm'
import { useSelector } from 'model/hooks'
import { dbSaveFeedback } from 'controllers/feedback'

const Contacts = () => {
  const user = useSelector(state => state.user)
  return (
    <PageContentWrapper>
      <VStack
        bg='black'
        w='full'
        px={{ base: 4, lg: 32 }}
        py={{ base: 12, lg: 28 }}
        borderBottomRadius={{ base: '32px', lg: '64px' }}
        boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
        align={'flex-start'}
      >
        <Text textStyle={'title'} color='white'>
          Contacts
        </Text>
        <Divider color='white' />
        <Text
          color='white'
          fontWeight={{ base: 'normal', lg: 'medium' }}
          fontSize={{ base: 'lg', lg: '5xl' }}
          letterSpacing={{ base: 'normal', lg: '-1.44px' }}
          lineHeight={'114%'}
          pt={5}
        >
          Individual Entrepreneur Margarita Ostroukhova
          <br />
          Identification Number: 305562076
          <br />
          Georgia, Tbilisi, Saburtalo district,
          <br />
          Vazha-Pshavela avenue, N29a
        </Text>
      </VStack>
      <Flex pt={{ base: 12, lg: 20 }}>
        <ContactForm user={user} dbSaveFeedback={dbSaveFeedback} />
      </Flex>
    </PageContentWrapper>
  )
}

export default Contacts
