import { onSnapshot, query, collection } from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { ILevel } from 'shared/types'
import { receiveLevels } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchLevels = async () => {
  try {
    const q = query(collection(db, 'levels'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ILevel> = {}
        sn.forEach(doc => {
          const p = doc.data() as ILevel
          res[doc.id] = p
        })
        store.dispatch(receiveLevels(res))
      },
      err => {
        console.log(`dbFetchLevels error: ${err.message}`)
      }
    )
    addListener('levels', unsubscribe)
  } catch (e) {
    console.error('dbFetchLevels error', e)
  }
}
