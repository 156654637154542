import PageContentWrapper from 'shared/components/PageContentWrapper'
import { VStack, useBreakpointValue } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getSortedCourses } from 'model/selectors/courses'
import BigCard from 'components/BigCard'
import { ICourse, ICoursesPack } from 'shared/types'
import courseDefault from 'shared/assets/course-default.png'
import { useNavigate } from 'react-router-dom'
import ContentContainer from 'shared/components/ContentContainer'
import BlackPageHeader from 'components/BlackPageHeader'
import has from 'lodash/has'
import Cards, { ICard } from 'components/Cards'

const Courses = () => {
  const courses = useSelector(getSortedCourses)
  const navigate = useNavigate()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  })

  const onCardClick = (c: ICourse | ICoursesPack) => {
    const isPack = has(c, 'plans')
    if (isPack) {
      navigate(`/tariffs/${c.id}`)
    } else {
      navigate(`/courses/${c.id}`)
    }
  }

  const renderCourse = (c: ICourse | ICoursesPack) => {
    // const sDate = dayjs(c.startDate)
    // const eDate = dayjs(c.endDate)

    return (
      <BigCard
        onClick={() => onCardClick(c)}
        key={c.id}
        imageUrl={c.imageUrl || courseDefault}
        title={c.title}
        details={[]}
        // details={[
        //   `${sDate.format('MMM D')} - ${eDate.format('MMM D')}`,
        //   `${eDate.diff(sDate, 'weeks')} недель`
        // ]}
      />
    )
  }

  const renderFirstCard = () => {
    if (isDesktop && courses && courses.length > 0) {
      const c = courses[0]
      // const sDate = dayjs(c.startDate)
      // const eDate = dayjs(c.endDate)
      return (
        <BigCard
          onClick={() => onCardClick(c)}
          key={c.id}
          imageUrl={c.imageUrl || courseDefault}
          title={c.title}
          details={
            [
              // `${sDate.format('MMM D')} - ${eDate.format('MMM D')}`,
              // `${eDate.diff(sDate, 'weeks')} недель`
            ]
          }
        />
      )
    }
  }

  const renderTailCards = () => {
    if (isDesktop) {
      const tailCourses = courses
      const cards = tailCourses.map(c => {
        // const sDate = dayjs(c.startDate)
        // const eDate = dayjs(c.endDate)
        const card: ICard = {
          imageUrl: c.imageUrl || courseDefault,
          onClick: () => onCardClick(c),
          title: c.title,
          desc: [
            // `${sDate.format('MMM D')} - ${eDate.format('MMM D')}`,
            // `${eDate.diff(sDate, 'weeks')} недель`
          ]
        }
        return card
      })
      return <Cards cards={cards} amountPerRow={2} aspectRatio={16 / 9} />
    } else {
      return courses.map(renderCourse)
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='wood.100'
        backBg='white'
      >
        <BlackPageHeader
          title='Курсы'
          subtitle='Наши онлайн-курсы предоставляют уникальную возможность прокачать свой акцент и голос под руководством опытных преподавателей'
          description='Мы следуем строгому учебному плану с конкретными датами начала и окончания занятий. Курсы разработаны таким образом, чтобы обеспечить структурированное и последовательное изучение. Вы будете присоединяться к группе студентов, которые начинают курс вместе в определенное время.'
        />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        minH='80vh'
      >
        <VStack w='full' py={{ base: 4, lg: 8 }} spacing={{ base: 4, lg: 6 }}>
          {/* {renderFirstCard()} */}
          {renderTailCards()}
        </VStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Courses
