import { VStack } from '@chakra-ui/react'
import SigninForm from 'components/auth/SigninForm'
import SignupForm from 'components/auth/SignupForm'
import { FC, useState } from 'react'
import { User } from 'firebase/auth'
import ResetPasswordForm from 'components/auth/ResetPasswordForm'

enum Mode {
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  RESET_PASSWORD = 'reset_password'
}

type Props = {
  onAuth: (authData: User) => void
  title?: string
  subTitle?: string
  description?: string
}

const Auth: FC<Props> = ({ onAuth, title, subTitle, description }) => {
  const [mode, setMode] = useState(Mode.SIGNIN)

  const renderContent = () => {
    if (mode === Mode.SIGNIN) {
      return (
        <SigninForm
          toSignUp={() => setMode(Mode.SIGNUP)}
          toResetPassword={() => setMode(Mode.RESET_PASSWORD)}
          onAuth={onAuth}
          title={title}
          subTitle={subTitle}
          description={description}
        />
      )
    } else if (mode === Mode.RESET_PASSWORD) {
      return <ResetPasswordForm onBack={() => setMode(Mode.SIGNIN)} />
    } else {
      return (
        <SignupForm
          toSignin={() => setMode(Mode.SIGNIN)}
          onAuth={onAuth}
          title={title}
          subTitle={subTitle}
          description={description}
        />
      )
    }
  }

  return (
    <VStack
      maxW='540px'
      w='full'
      p={6}
      rounded={'8px'}
      boxShadow={{
        base: 'none',
        lg: 'shadow1'
      }}
    >
      {renderContent()}
    </VStack>
  )
}

export default Auth
