import { FC, ReactNode } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

type Props = ButtonProps & {
  children: ReactNode
}

const PrimaryButton: FC<Props> = ({ children, size, ...rest }) => {
  return (
    <Button
      bg='black'
      color='white'
      rounded={'full'}
      fontSize={{ base: '12px', lg: '14px' }}
      fontWeight={'500'}
      lineHeight={{ base: '15px', lg: '13px' }}
      letterSpacing={{ base: '-0.14px', lg: '-0.12px' }}
      px={{ base: 4, lg: 6 }}
      py={{ base: '9px', lg: 3 }}
      height={'auto'}
      _hover={{ opacity: 0.8 }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
