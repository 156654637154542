import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure
} from '@chakra-ui/react'
import { dbGetPayanywaySig } from 'controllers/payments'
import { useSelector } from 'model/hooks'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import get from 'lodash/get'
import { IItemInfo } from 'shared/types'

export interface IProdamusModal {
  open: (itemInfo: IItemInfo) => void
}

type Props = {}

const ProdamusModal: ForwardRefRenderFunction<IProdamusModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const authData = useSelector(state => state.authData)
  const user = useSelector(state => state.user)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)

  const renderPaymentForm = async (itemInfo: IItemInfo) => {
    if (authData && itemInfo.price > 0 && user) {
      setLoading(true)
      const sigRes = await dbGetPayanywaySig(itemInfo.id, itemInfo.type)
      setLoading(false)
      if (sigRes) {
        const searchParams = new URLSearchParams()
        searchParams.set('order_id', get(sigRes, 'transactionId', ''))
        searchParams.set('order_sum', itemInfo.price.toString())
        searchParams.set('currency', 'rub')
        searchParams.set('customer_email', authData.email || '')
        searchParams.set('customer_phone', user.phone || '')
        // searchParams.set('demo_mode', '1')
        searchParams.set('do', 'pay')

        searchParams.set('products[0][price]', itemInfo.price.toString())
        searchParams.set('products[0][quantity]', '1')
        searchParams.set('products[0][name]', itemInfo.title)
        searchParams.set('products[0][sku]', itemInfo.id)

        // searchParams.set('payment_method', 'ACEURNMBX')
        // searchParams.set('available_payment_methods', 'ACEURNMBX')
        searchParams.set('urlReturn', window.location.href)
        searchParams.set(
          'urlSuccess',
          `${window.location.origin}/profile/${itemInfo.type}/${itemInfo.id}`
        )

        const url = `https://thequeenenglish.payform.ru?${searchParams.toString()}`
        window.location.replace(url)
        // @ts-ignore
        // @ts-ignore

        // const init = window.payformInit
        // if (init) {
        //   init('thequeenenglish.payform.ru', {
        //     order_sum: itemInfo.price,
        //     currency: 'rub',
        //     customer_email: authData.email,
        //     demo_mode: 1,
        //     customer_phone: get(user, 'phone')
        //     // type: 'json',
        //     // callbackType: 'json',
        //     // order_id: get(sigRes, 'transactionId')
        //     // _param_entityId: itemInfo.id,
        //     // _param_entityType: itemInfo.type,
        //     // products: [
        //     //   {
        //     //     name: itemInfo.title,
        //     //     price: itemInfo.price,
        //     //     quantity: 1,
        //     //     sum: itemInfo.price,
        //     //     sku: itemInfo.id
        //     //   }
        //     // ]
        //   })
        // }
      } else {
        onClose()
      }
    }
  }

  useImperativeHandle(ref, () => ({
    open: (itemInfo: IItemInfo) => {
      onOpen()
      renderPaymentForm(itemInfo)
    }
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent w='lg' minH='60' rounded='30px'>
        <ModalBody py={5} px={5} h={'full'}>
          {loading && (
            <Flex w='full' h='60' justify='center' align='center'>
              <Spinner size='xs' />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ProdamusModal)
