import { onSnapshot, query, collection, where } from 'firebase/firestore'
import { db } from 'controllers/db'
import { ICourse } from 'shared/types'
import { receiveLibraryCourses } from 'model/actions'
import store from 'model/store'

export const dbFetchLibrary = async () => {
  try {
    const q = query(collection(db, 'library'), where('invisible', '==', false))
    onSnapshot(
      q,
      sn => {
        const res: Record<string, ICourse> = {}
        sn.forEach(doc => {
          const p = doc.data() as ICourse
          res[doc.id] = { ...p, id: doc.id }
        })
        store.dispatch(receiveLibraryCourses(res))
      },
      err => {
        console.log(`dbFetchLibrary error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchLibrary error', e)
  }
}
