import { useContext, useMemo } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import ContentContainer from 'shared/components/ContentContainer'
import find from 'lodash/find'
import get from 'lodash/get'
import UserEntityHeader from 'pages/userEntity/UserEntityHeader'
import { useParams } from 'react-router-dom'
import EntityElementsContainer from 'components/EntityElementsContainer'
import EntityRowStatus from 'components/EntityRowStatus'
import keyBy from 'lodash/keyBy'
import { ILesson, ILessonType } from 'shared/types'
import reduce from 'lodash/reduce'
import { isTestSection } from 'shared/utils/sectionName'
import { percToAmount } from 'shared/utils/tests'

const UserEntityDay = () => {
  const { entity, lessons, entityType, testResults } =
    useContext(LessonsContext)
  const { weekId, dayId } = useParams() as { weekId: string; dayId: string }

  const resultByLessonId = useMemo(() => {
    return keyBy(testResults, 'lessonId')
  }, [testResults])

  const week = useMemo(() => {
    if (entity && entity.content) {
      return find(entity.content, w => w.id === weekId)
    }
  }, [entity, weekId])

  const day = useMemo(() => {
    if (week && dayId) {
      return find(week.days, d => d.id === dayId)
    }
  }, [week, dayId])

  // console.log('day', day)

  const getTestQuestionsAmount = (l: ILesson) => {
    return reduce(
      l.sections,
      (res, s) => {
        return isTestSection(s.type) ? res + 1 : res
      },
      0
    )
  }

  const renderLesson = (lId: string) => {
    const l = get(lessons, lId)
    // console.log('renderLesson', l)
    if (entity && week && day && l) {
      const r = get(resultByLessonId, l.id)
      const correctAnswersAmount = get(r, 'correctAnswersAmount', 0)
      const questionsAmount = getTestQuestionsAmount(l)
      const lastTimeComplete =
        percToAmount(questionsAmount, l.perc) <= correctAnswersAmount
      const isTest =
        l.type === ILessonType.TEST || l.type === ILessonType.EXAMINATION
      return (
        <EntityRowStatus
          key={lId}
          title={l.name}
          toPath={`/profile/${entityType}/${entity.id}/${week.id}/${day.id}/${lId}`}
          isDisabled={false}
          status={
            isTest && r && r.isComplete
              ? 'success'
              : isTest && r
              ? 'failed'
              : isTest
              ? 'checkmark'
              : 'none'
          }
          progress={
            isTest
              ? {
                  correctAnswersAmount: correctAnswersAmount,
                  questionsAmount: getTestQuestionsAmount(l),
                  status: r ? (lastTimeComplete ? 'success' : 'failed') : 'none'
                }
              : undefined
          }
        />
      )
    } else {
      console.warn('object does not exist', entity, week, day, l)
    }
  }

  const renderLessons = () => {
    if (week && day && day.lessons) {
      return (
        <EntityElementsContainer>
          {day.lessons.map(renderLesson)}
        </EntityElementsContainer>
      )
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        {entity && week && day && (
          <UserEntityHeader
            title={day.title || ''}
            backUrl={`/profile/${entityType}/${entity.id}/${week.id}`}
          />
        )}
      </ContentContainer>
      <ContentContainer
        bg='white'
        backBg='black.950'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={8}
        full
      >
        {renderLessons()}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default UserEntityDay
