import { Link as RRLink } from 'react-router-dom'
import { HStack, Link, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { startsWith } from 'lodash'

const Menu = () => {
  const location = useLocation()

  const renderLink = (
    title: string,
    path: string,
    solid: boolean,
    outlined: boolean
  ) => {
    return (
      <Flex
        px={{ base: 1, lg: 2 }}
        py={{ base: 0.5, lg: 1 }}
        role='group'
        bg={solid ? 'black.950' : 'white'}
        _hover={{ bg: 'black.950' }}
        rounded={'full'}
        borderWidth={1}
        borderColor={outlined ? 'black.950' : 'white'}
      >
        <Link
          sx={{
            ':active': { textDecoration: 'none' },
            ':hover': { textDecoration: 'none' }
          }}
          textDecor={'none'}
          to={path}
          fontSize={{ base: 'xs', lg: 'sm' }}
          color={solid ? 'white' : 'black.950'}
          letterSpacing={{ base: '-0.24px', lg: '0.14px' }}
          _groupHover={{
            color: 'white'
          }}
          as={RRLink}
        >
          {title}
        </Link>
      </Flex>
    )
  }

  return (
    <HStack position='fixed' bottom={6} justify={'center'}>
      <HStack
        bg='white'
        boxShadow={'0px 1px 5px 1px rgba(0, 0, 0, 0.12);'}
        h={{ base: 7, lg: '42px' }}
        spacing={0}
        rounded='full'
        px={{ base: 1, lg: 2 }}
      >
        {renderLink('Главная', '/', location.pathname === '/', false)}
        {renderLink(
          'Бесплатное',
          '/free',
          location.pathname === '/free',
          startsWith(location.pathname, '/free/')
        )}
        {renderLink(
          'Библиотека',
          '/library',
          location.pathname === '/library',
          startsWith(location.pathname, '/library/')
        )}
        {renderLink(
          'Семинары',
          '/seminars',
          location.pathname === '/seminars',
          startsWith(location.pathname, '/seminars/')
        )}

        {renderLink(
          'Курсы',
          '/courses',
          location.pathname === '/courses',
          startsWith(location.pathname, '/courses/') ||
            startsWith(location.pathname, '/tariffs/')
        )}
        {/* {renderLink(
          'Вебинары',
          '/webinars',
          location.pathname === '/webinars',
          startsWith(location.pathname, '/webinars/')
        )} */}

        {renderLink(
          'Аккаунт',
          '/profile',
          location.pathname === '/profile',
          startsWith(location.pathname, '/profile/') ||
            startsWith(location.pathname, '/login')
        )}
      </HStack>
    </HStack>
  )
}

export default Menu
