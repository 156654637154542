import {
  query,
  collection,
  where,
  getDocs,
  documentId,
  onSnapshot
} from 'firebase/firestore'
import { db } from 'controllers/db'
import { ILesson } from 'shared/types'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'

export const dbGetLessonsById = async (lessonsIds: string[]) => {
  try {
    const chunks = chunk(lessonsIds, 10)
    const promises = map(chunks, async ids => {
      const q = query(collection(db, 'lessons'), where(documentId(), 'in', ids))
      const sn = await getDocs(q)
      return sn.docs
    })
    const resAr = await Promise.all(promises)
    const docs = flatten(resAr)
    return keyBy(
      map(docs, doc => ({ ...doc.data(), id: doc.id } as ILesson)),
      'id'
    )
  } catch (e) {
    console.error('dbGetLessonsById error', e)
    return null
  }
}

export const dbSubscribeToLessons = (
  entityId: string,
  callback: (lessons: Record<string, ILesson>) => void
) => {
  try {
    const q = query(
      collection(db, 'lessons'),
      where('entityId', '==', entityId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ILesson> = {}
        sn.forEach(doc => {
          const p = doc.data() as ILesson
          res[doc.id] = { ...p, id: doc.id }
        })
        callback(res)
      },
      err => {
        console.log(`dbSubscribeToLessons error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToLessons error', e)
    return null
  }
}
