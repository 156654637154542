import { useEffect, useMemo } from 'react'
import { useContext } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { useNavigate, useParams } from 'react-router-dom'
import LessonPage from 'shared/pages/LessonPage'
import { storageRef } from 'controllers/db'
import { useSelector } from 'model/hooks'
import { ILessonType, SectionType } from 'shared/types'
import { dbGetWordCards } from 'controllers/cards'

const UserLesson = () => {
  const { lessonId } = useParams() as { lessonId: string }
  const { lessons, entity, entityType } = useContext(LessonsContext)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const wordCards = useSelector(state => state.wordCards)
  const cardTags = useSelector(state => state.cardTags)

  const lesson = useMemo(() => {
    return get(lessons, lessonId)
  }, [lessons, lessonId])

  useEffect(() => {
    if (lesson?.sections) {
      if (lesson.type === ILessonType.DICTIONARY) {
        const rawCardsIds = map(lesson.sections, s => {
          if (s.type === SectionType.WORD_CARD) {
            return s.cardId || null
          } else {
            return null
          }
        })
        const ids = compact(rawCardsIds)
        dbGetWordCards(ids)
      }
    }
  }, [lesson?.sections, lesson?.type])

  const prevLessonId = useMemo(() => {
    if (entity) {
      const curIndex = entity.lessons.indexOf(lessonId)
      if (curIndex > 0) {
        return entity.lessons[curIndex - 1]
      }
    }
  }, [lessonId, entity])

  const nextLessonId = useMemo(() => {
    if (entity) {
      const curIndex = entity.lessons.indexOf(lessonId)
      if (curIndex < entity.lessons.length - 1) {
        return entity.lessons[curIndex + 1]
      }
    }
  }, [lessonId, entity])

  const nextUrl = useMemo(() => {
    return nextLessonId && entity
      ? `/profile/${entityType}/${entity.id}/l/${nextLessonId}`
      : undefined
  }, [nextLessonId, entity, entityType])

  const prevUrl = useMemo(() => {
    return prevLessonId && entity
      ? `/profile/${entityType}/${entity.id}/l/${prevLessonId}`
      : undefined
  }, [prevLessonId, entity, entityType])

  if (!entity || !lesson) {
    return null
  } else {
    return (
      <LessonPage
        key={lesson.id}
        lesson={lesson}
        storageRef={storageRef}
        entity={entity}
        entityType={entityType}
        toNext={nextUrl ? () => navigate(nextUrl) : undefined}
        toPrev={prevUrl ? () => navigate(prevUrl) : undefined}
        backUrl={
          entity
            ? `/profile/${entityType}/${entity.id}`
            : `/profile/${entityType}`
        }
        examinationAttempt={null}
        testResult={null}
        dbFinishExaminationAttempt={async () => undefined}
        submitTestAnswers={async () => null}
        user={user}
        dbStartExaminationAttempt={async () => undefined}
        weekId={''}
        dayId={''}
        teachers={teachers || {}}
        partitions={partitions}
        wordCards={wordCards}
        cardTags={cardTags}
      />
    )
  }
  //   return (
  //     <LessonOld
  //       entityId={entity.id}
  //       lesson={lesson}
  //       backUrl={entity ? `/profile/${entityType}/${entity.id}` : undefined}
  //       prevUrl={
  //         prevLessonId && entity
  //           ? `/profile/${entityType}/${entity.id}/l/${prevLessonId}`
  //           : undefined
  //       }
  //       nextUrl={
  //         nextLessonId && entity
  //           ? `/profile/${entityType}/${entity.id}/l/${nextLessonId}`
  //           : undefined
  //       }
  //     />
  //   )
  // } else {
  //   return (
  //     <LessonOld
  //       entityId={entity.id}
  //       lesson={lesson}
  //       backUrl={entity ? `/profile/${entityType}/${entity.id}` : undefined}
  //       prevUrl={
  //         prevLessonId && entity
  //           ? `/profile/${entityType}/${entity.id}/l/${prevLessonId}`
  //           : undefined
  //       }
  //       nextUrl={
  //         nextLessonId && entity
  //           ? `/profile/${entityType}/${entity.id}/l/${nextLessonId}`
  //           : undefined
  //       }
  //     />
  //   )
  // }
}

export default UserLesson
