import { onSnapshot, query, collection, where } from 'firebase/firestore'
import { db } from 'controllers/db'
import { ICoursesPack } from 'shared/types'
import { receiveCoursesPacks } from 'model/actions'
import store from 'model/store'

export const dbFetchCoursesPacks = async () => {
  try {
    const q = query(
      collection(db, 'coursesPacks'),
      where('invisible', '==', false)
    )
    onSnapshot(
      q,
      sn => {
        const res: Record<string, ICoursesPack> = {}
        sn.forEach(doc => {
          const p = doc.data() as ICoursesPack
          res[doc.id] = p
        })
        store.dispatch(receiveCoursesPacks(res))
      },
      err => {
        console.log(`dbFetchCoursesPacks error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchCoursesPacks error', e)
  }
}
