import { Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'

const InfoSection = () => {
  const desc = useBreakpointValue({
    base: (
      <span>
        это онлайн-школа, где
        <br /> вы научитесь говорить
        <br /> и думать на английском
      </span>
    ),
    lg: (
      <span>
        это авторская онлайн-школа, где вы научитесь
        <br />
        говорить и думать на английском
      </span>
    )
  })

  return (
    <ContentContainer
      bg='wood.100'
      backBg='white'
      borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      pt={{ base: 12, lg: 24 }}
      pb={{ base: 6, lg: 8 }}
    >
      <VStack w='full' align={'flex-start'} spacing={0}>
        <Text textStyle={'h1'} as='h1' color='sun.950' fontWeight={'bold'}>
          thequeenenglish —
        </Text>
        <Text textStyle={'h2'} as='h2' color='black.800'>
          {desc}
        </Text>
        {/* <AspectRatio
          w='full'
          ratio={{ base: 1, lg: 16 / 9 }}
          mt={{ base: 2, lg: 6 }}
        >
          <Image
            src={homePageBanner}
            objectFit='cover'
            borderRadius={{ base: 'xs', lg: 'sm' }}
          />
        </AspectRatio> */}
        <Text
          maxW={{ base: '360px', lg: '600px' }}
          textStyle='h3'
          color='black.400'
          pt={{ base: 6, lg: 12 }}
        >
          Здесь учат живому языку, с помощью которого вы сможете свободно
          общаться и доносить свои мысли четко и красиво
        </Text>
      </VStack>
    </ContentContainer>
  )
}

export default InfoSection
