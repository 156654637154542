import { Grid, HStack, Text, VStack, WrapItem } from '@chakra-ui/react'
import SeminarCalendarCard from 'components/seminars/SeminarCalendarCard'
import dayjs, { Dayjs } from 'dayjs'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import sortBy from 'lodash/sortBy'
import { FC, useMemo } from 'react'
import { ISeminar } from 'shared/types'

type Props = {
  startDate: Dayjs
  seminars: ISeminar[]
}

const SeminarsMonth: FC<Props> = ({ startDate, seminars }) => {
  const { calendarStartDate, calendarEndDate } = useMemo(() => {
    const weekdayStart = startDate.weekday()
    // console.log('weekdayStart', weekdayStart)
    const endDate = startDate.add(1, 'month')
    const weekdayEnd = endDate.weekday()
    // console.log('endDate', endDate.format())
    // console.log('weekdayEnd', weekdayEnd)
    return {
      calendarStartDate: startDate.subtract(weekdayStart, 'days'),
      calendarEndDate: endDate
        .add(7 - weekdayEnd, 'days')
        .subtract(1, 'seconds')
    }
  }, [startDate])

  const data = useMemo(() => {
    const res: Record<string, ISeminar[]> = {}
    for (const s of seminars) {
      const dy = dayjs(s.startDate).dayOfYear().toString()
      if (!isArray(res[dy])) {
        res[dy] = []
      }
      res[dy].push(s)
    }
    return res
  }, [seminars])

  // console.log('data', data)

  // console.log('calendar start Date', calendarStartDate.format())
  // console.log('calendar end Date', calendarEndDate.format())

  const renderItems = () => {
    const daysAmount = calendarEndDate.diff(calendarStartDate, 'day') + 1
    console.log('daysAmount', daysAmount)
    return Array(daysAmount)
      .fill(0)
      .map((v, i) => {
        const d = calendarStartDate.add(i, 'days')
        const daynum = d.dayOfYear()
        const sameMonth = d.isSame(startDate, 'month')
        const daySeminars = sortBy(get(data, daynum, []), 'startDate')
        const dayOfWeek = d.weekday()
        return (
          <WrapItem
            key={i}
            borderBottomWidth={1}
            borderRightWidth={1}
            borderColor='wood.100'
            minH={'170px'}
            flexDirection={'column'}
            display={'flex'}
            p={2}
            bg={dayOfWeek >= 5 ? 'wood.50' : 'white'}
          >
            <HStack pb={4} justify={'flex-end'} w='full'>
              <Text
                textStyle={'small'}
                color={sameMonth ? 'black.950' : 'black.500'}
              >
                {d.format('dd, D')}
              </Text>
            </HStack>
            <VStack w='full' spacing={'3px'}>
              {daySeminars.map(s => {
                return <SeminarCalendarCard key={s.id} s={s} />
              })}
            </VStack>
          </WrapItem>
        )
      })
  }

  return (
    <Grid
      templateColumns='repeat(7, 1fr)'
      w='full'
      gap={0}
      borderTopWidth={1}
      borderLeftWidth={1}
      borderColor='wood.100'
    >
      {renderItems()}
    </Grid>
  )
}

export default SeminarsMonth
