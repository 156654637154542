import { Flex, HStack, Spinner } from '@chakra-ui/react'
import { dbGetPayanywaySig } from 'controllers/payments'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IItemInfo, IPayanywaySig } from 'shared/types'

export interface IPayanywayModal {
  open: (itemInfo: IItemInfo) => void
}

type Props = {
  itemInfo: IItemInfo
  onSuccess?: () => void
  onClose?: () => void
}

const PayanywayModalContent: FC<Props> = ({ itemInfo, onSuccess, onClose }) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const run = async () => {
      setLoading(true)
      const r = (await dbGetPayanywaySig(
        itemInfo.id,
        itemInfo.type
      )) as IPayanywaySig | null
      setLoading(false)
      if (r) {
        renderPaymentForm(r)
      }
    }
    run()
  }, [itemInfo])

  const renderPaymentForm = async (sigRes: IPayanywaySig) => {
    // @ts-ignore
    const assistant = new window.Assistant.Builder()

    // платёж прошёл успешно
    assistant.setOnSuccessCallback(
      (operationId: string, transactionId: string) => {
        console.log('on success', operationId, transactionId)
        onSuccess && onSuccess()
        onClose && onClose()
        itemInfo.successPath && navigate(itemInfo.successPath)
      }
    )

    // платёж не прошёл
    assistant.setOnFailCallback(
      (operationId: string, transactionId: string) => {
        console.log('on fail', operationId, transactionId)
        onClose && setTimeout(onClose, 2000)
      }
    )

    // платёж обрабатывается
    assistant.setOnInProgressCallback(
      (operationId: string, transactionId: string) => {
        console.log('on progress', operationId, transactionId)
      }
    )

    const options = {
      ...sigRes,
      description: itemInfo.title
    }

    console.log('options', options)

    assistant.build(options, 'payment_form')
  }

  return (
    <HStack w='full' minH={40} justify={'center'}>
      <Flex
        w='full'
        id='payment_form'
        sx={{ boxShadow: 'none !important', bg: 'white !important' }}
      />
      {loading && (
        <Flex w='full' h='60' justify='center' align='center'>
          <Spinner size='xs' />
        </Flex>
      )}
    </HStack>
  )
}

export default PayanywayModalContent
