import { FC } from 'react'
import { Divider, Text, VStack, Stack, Flex, HStack } from '@chakra-ui/react'
import { ReactComponent as ChevronRightIcon } from 'shared/assets/bsChevronRight.svg'

type Props = {
  title: string
  value: string
  onClick?: () => void
}

const SettingsRow: FC<Props> = ({ title, onClick, value }) => {
  return (
    <VStack w='full' align='flex-start' pt={{ base: 2, lg: 12 }} spacing={2}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='full'
        spacing={{ base: 5, lg: 6 }}
      >
        <Flex flex={1}>
          <Text textStyle={'h3'} color='black.950'>
            {title}
          </Text>
        </Flex>
        <HStack flex={1} justify={'space-between'}>
          <Text textStyle='body' color={onClick ? 'black.950' : 'black.200'}>
            {value}
          </Text>
          {onClick && (
            <HStack
              as='button'
              color='wood.600'
              _hover={{ cursor: 'pointer' }}
              onClick={onClick}
            >
              <Text textStyle={'small'} color='wood.600'>
                Изменить
              </Text>
              <ChevronRightIcon width='12' height='12' />
            </HStack>
          )}
        </HStack>
      </Stack>
      <Divider />
    </VStack>
  )
}

export default SettingsRow
