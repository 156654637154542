import { doc, setDoc } from 'firebase/firestore'
import { db, generateId } from 'controllers/db'
import { IFeedback } from 'shared/types'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import dayjs from 'dayjs'

export const dbSaveFeedback = async (feedback: Partial<IFeedback>) => {
  try {
    const id = generateId()
    const ref = doc(db, 'feedback', id)
    const f: IFeedback = {
      id,
      createdAt: +dayjs(),
      ...feedback
    } as IFeedback
    setDoc(ref, omitBy(f, isNil))
  } catch (e) {
    console.log('dbSaveFeedback error', e)
  }
}
