import { Text, Link } from '@chakra-ui/react'

const SimpleAgreement = () => {
  return (
    <Text textStyle={'tag'}>
      Продолжая, вы принимаете условия
      <Link href='/privacy' target='_blank' color='orange'>
        {' '}
        Положения о конфиденциальности
      </Link>{' '}
      сайта и{' '}
      <Link href='/agreement' target='_blank' color='orange'>
        Договора-оферты
      </Link>
    </Text>
  )
}

export default SimpleAgreement
