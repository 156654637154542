import { useEffect } from 'react'
import { VStack, Button, Text, HStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { dbSignOut } from 'controllers/auth'
import { useSelector } from 'model/hooks'
import MenuItem from 'pages/profile/MenuItem'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SignOutIcon } from 'shared/assets/bsBoxArrowRight.svg'
import BlackPageHeader from 'components/BlackPageHeader'

const Profile = () => {
  const navigate = useNavigate()
  const authData = useSelector(state => state.authData)

  useEffect(() => {
    if (authData === null) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData])

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        <BlackPageHeader
          title='Аккаунт'
          hideDivider
          titleProps={{ textStyle: 'h1' }}
        />
      </ContentContainer>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        pb={{ base: 20, lg: 8 }}
        minH='80vh'
      >
        <VStack w='full' align='flex-start' spacing={6} py={8}>
          <MenuItem
            title='Бесплатное'
            onClick={() => navigate('/profile/free')}
          />
          <MenuItem
            title='Моя Библиотека'
            onClick={() => navigate('/profile/library')}
          />
          <MenuItem
            title='Мои Семинары'
            onClick={() => navigate('/profile/seminars')}
          />
          <MenuItem
            title='Мои Курсы'
            onClick={() => navigate('/profile/courses')}
          />
          <MenuItem
            title='Мои Вебинары'
            onClick={() => navigate('/profile/webinars')}
          />
          <MenuItem
            title='Мои Конференции'
            onClick={() => navigate('/profile/conferences')}
          />
          <MenuItem
            title='Настройки'
            onClick={() => navigate('/profile/settings')}
          />
        </VStack>
        <HStack justify={'center'} w='full'>
          <Button
            color='wood.400'
            variant={'ghost'}
            size='sm'
            onClick={() => dbSignOut(navigate)}
            rightIcon={<SignOutIcon />}
          >
            <Text textStyle={'small'} color='wood.400'>
              Выход
            </Text>
          </Button>
        </HStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Profile
