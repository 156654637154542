import { ReactNode, FC, useEffect, useRef } from 'react'
import { VStack } from '@chakra-ui/react'
import Header from 'components/Header'
import { appInitialized } from 'controllers/init'
import { useNavigate } from 'react-router-dom'
import Footer from 'shared/components/Footer'
import ScrollToTop from 'shared/components/ScrollToTop'
import Menu from 'components/Menu'
import MainContext from 'contexts/MainContext'
import PaymentModal, { IPaymentModal } from 'modals/PaymentModal'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const appInitializedRef = useRef<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const paymentModalRef = useRef<IPaymentModal>(null)

  useEffect(() => {
    if (!appInitializedRef.current) {
      appInitialized(navigate)
      appInitializedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const providerValue = {
    openPayment: paymentModalRef.current
      ? paymentModalRef.current?.open
      : () => console.error('payment modal is not initialized')
  }

  return (
    <MainContext.Provider value={providerValue}>
      <VStack
        ref={containerRef}
        w='full'
        h='full'
        justify={'flex-start'}
        spacing={0}
        overflowX={'hidden'}
        overflowY={'auto'}
        minH='full'
        bg='black.950'
      >
        <ScrollToTop containerRef={containerRef} />
        <VStack
          w='full'
          bg={'wood.100'}
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        >
          <Header />
          <VStack w='full' justify={'flex-start'} flex={1} spacing={0}>
            {children}
          </VStack>
        </VStack>
        <Footer />
        <Menu />
        <PaymentModal ref={paymentModalRef} />
      </VStack>
    </MainContext.Provider>
  )
}

export default NavWrapper
