import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'model/hooks'
import Loading from 'components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import NotFound from 'shared/components/NotFound'
import get from 'lodash/get'
import map from 'lodash/map'
import MainContext from 'contexts/MainContext'
import { ICourse, ICoursesPack, IEntityType, IItemInfo } from 'shared/types'
import { storageRef } from 'controllers/db'
import { dbFetchCoursesList } from 'controllers/courses'
import CoursesPackPage from 'shared/pages/CoursesPackPage'
import { dbSaveFeedback } from 'controllers/feedback'

const CoursesPack = () => {
  const { packId } = useParams()
  const user = useSelector(state => state.user)
  const { openPayment } = useContext(MainContext)
  const [courses, setCourses] = useState<Record<string, ICourse>>({})
  const userCourses = useSelector(state =>
    get(state.userEntities, 'courses', {})
  )
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const navigate = useNavigate()

  const coursesPack: ICoursesPack | undefined = useSelector(state =>
    state.coursesPacks && packId ? state.coursesPacks[packId] : undefined
  )

  useEffect(() => {
    const coursesIds = map(coursesPack?.plans, p => p.courseId)
    const run = async () => {
      const lst = await dbFetchCoursesList(coursesIds)
      if (lst) {
        setCourses(lst)
      }
    }
    run()
  }, [coursesPack])

  const onPay = (course: ICourse) => {
    if (course) {
      const itemInfo: IItemInfo = {
        title: course.title,
        id: course.id,
        type: IEntityType.COURSES,
        price: course.price,
        paymentLink: course.paymentLink
      }
      openPayment(itemInfo)
    }
  }

  if (courses === null) {
    return <Loading />
  } else if (!coursesPack) {
    return <NotFound message='Курс не существует' />
  } else {
    return (
      <CoursesPackPage
        storageRef={storageRef}
        coursesPack={coursesPack}
        courses={courses}
        onPay={(c: ICourse) => onPay(c)}
        toCourse={(courseId: string) =>
          navigate(`/profile/courses/${courseId}`)
        }
        userCourses={userCourses}
        user={user}
        dbSaveFeedback={dbSaveFeedback}
        teachers={teachers || {}}
        partitions={partitions}
      />
    )
  }
}

export default CoursesPack
