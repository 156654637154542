import { createSelector } from '@reduxjs/toolkit'
import { getCourses, getCoursesPacks } from 'model/selectors/base'
import orderBy from 'lodash/orderBy'

export const getSortedCourses = createSelector(
  [getCourses, getCoursesPacks],
  (courses, coursesPacks) => {
    const all = { ...(courses || {}), ...(coursesPacks || {}) }
    return orderBy(all, 'createdAt', 'desc')
  }
)
