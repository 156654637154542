import { useEffect, RefObject, FC } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  containerRef: RefObject<HTMLDivElement>
}

const ScrollToTop: FC<Props> = ({ containerRef }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    // console.log('pathname', pathname)
    containerRef.current.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
