import { VStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { useContext } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import BlackPageHeader from 'components/BlackPageHeader'
import CourseRow from 'pages/user/CourseRow'
import UserEntityMain from 'pages/userEntity/UserEntityMain'

const UserEntity = () => {
  const { entity, lessons, entityType } = useContext(LessonsContext)
  const navigate = useNavigate()

  const renderLessons = () => {
    if (lessons && entity && entity.lessons) {
      return entity.lessons.map(lId => {
        const l = lessons[lId]
        // console.log('l', l)

        return (
          <CourseRow
            key={lId}
            title={l?.name}
            description={''}
            description2={''}
            onClick={() =>
              navigate(`/profile/${entityType}/${entity.id}/l/${lId}`)
            }
          />
        )
      })
    }
  }

  if (!entity) {
    return null
  } else if (entity && entity.content && entity.content.length > 0) {
    return <UserEntityMain />
  } else {
    return (
      <PageContentWrapper>
        <ContentContainer
          bg='wood.100'
          backBg='white'
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        >
          <BlackPageHeader
            title={get(entity, 'title', '')}
            backUrl={entity ? `/profile/${entityType}/` : undefined}
            hideDivider
            titleProps={{ textStyle: 'body', fontWeight: 700 }}
          />
        </ContentContainer>
        <ContentContainer
          bg='white'
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
          py={{ base: 6, lg: 8 }}
          full
          minH='80vh'
        >
          <VStack w='full' align='flex-start' spacing={4}>
            {renderLessons()}
          </VStack>
        </ContentContainer>
      </PageContentWrapper>
    )
  }
}

export default UserEntity
