import { auth, db } from 'controllers/db'
import {
  collection,
  doc,
  documentId,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where
} from 'firebase/firestore'
import _ from 'lodash'
import { receiveWordCards } from 'model/actions'
import store from 'model/store'
import { IWordCard } from 'shared/types'

export const dbGetWordCards = async (cardIds: string[]) => {
  try {
    const chunks = _.chunk(cardIds, 10)
    const promises = _.map(chunks, async ids => {
      const q = query(collection(db, 'cards'), where(documentId(), 'in', ids))
      const sn = await getDocs(q)
      return sn.docs
    })
    const resAr = await Promise.all(promises)
    const docs = _.flatten(resAr)
    const cards = _.keyBy(
      _.map(docs, doc => ({ ...doc.data(), id: doc.id } as IWordCard)),
      'id'
    )
    store.dispatch(receiveWordCards(cards))
  } catch (e) {
    console.error('dbGetWordCards error', e)
    return null
  }
}

export const dbSetUserCardStatus = async (
  lessonId: string,
  cardId: string,
  known: boolean
) => {
  const currentUser = auth.currentUser
  if (!currentUser) {
    return null
  }
  const userId = currentUser.uid
  const recordId = userId + '_' + lessonId
  const ref = doc(db, `userCardsStatus/${recordId}`)
  setDoc(ref, { [cardId]: known }, { merge: true })
}

export const dbSubscribeToCardStatuses = (
  lessonId: string,
  callback: (statuses: Record<string, boolean>) => void
) => {
  const currentUser = auth.currentUser
  if (!currentUser) {
    return null
  }
  const userId = currentUser.uid
  const recordId = userId + '_' + lessonId
  try {
    const ref = doc(db, `userCardsStatus/${recordId}`)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        callback(sn.data() || {})
      },
      err => {
        console.log(`dbSubscribeToCardStatuses error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToCardStatuses error', e)
    return null
  }
}
