import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Input,
  VStack
} from '@chakra-ui/react'
import PrimaryButton from 'shared/components/PrimaryButton'
import { dbUpdateUserParams } from 'controllers/user'
import { useSelector } from 'model/hooks'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import { IItemInfo } from 'shared/types'
import Label from 'shared/components/Label'

export interface IUserParamsModal {
  open: () => void
}

type Props = {}

const UserParamsModal: ForwardRefRenderFunction<IUserParamsModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector(state => state.user)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [telegram, setTelegram] = useState('')

  useImperativeHandle(ref, () => ({
    open: (itemInfo: IItemInfo) => {
      setName(user?.name || '')
      setPhone(user?.phone || '')
      setTelegram(user?.telegram || '')
      onOpen()
    }
  }))

  const apply = async () => {
    dbUpdateUserParams(name, phone, telegram)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent w='96' minH='60' rounded='8px'>
        <ModalBody p={6} h={'full'}>
          <VStack spacing={6} w='full' align={'flex-start'}>
            <Text textStyle={'h3'} fontWeight={700}>
              Введите новые данные
            </Text>

            <VStack w='full' spacing={1} align={'start'}>
              <Label>Имя</Label>
              <Input
                variant={'flushed'}
                placeholder='Иван Петров'
                type='text'
                h='22px'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </VStack>

            <VStack w='full' spacing={1} align={'start'}>
              <Label>Телефон</Label>
              <Input
                variant={'flushed'}
                placeholder='+7 953 123 1212'
                type='tel'
                h='22px'
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </VStack>

            <VStack w='full' spacing={1} align={'start'}>
              <Label>Telegram</Label>
              <Input
                variant={'flushed'}
                placeholder='@username'
                type='text'
                h='22px'
                value={telegram}
                onChange={e => setTelegram(e.target.value)}
              />
            </VStack>

            <PrimaryButton w={'full'} onClick={apply}>
              Сохранить
            </PrimaryButton>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(UserParamsModal)
