import { useRef, useState, FC } from 'react'
import {
  Text,
  VStack,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  HStack,
  Checkbox,
  Link
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import isNil from 'lodash/isNil'
import { dbSignUp } from 'controllers/auth'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import { User } from 'firebase/auth'
import AuthBackButton from 'components/auth/AuthBackButton'
import AuthTitle from 'components/auth/AuthTitle'
import GeneralError from 'components/auth/GeneralError'
import StyledFormLabel from 'components/auth/StyledFormLabel'

type Props = {
  toSignin: () => void
  onAuth: (authData: User) => void
  title?: string
  subTitle?: string
  description?: string
}

const SignupForm: FC<Props> = ({
  toSignin,
  onAuth,
  title = 'Регистрация',
  subTitle,
  description
}) => {
  const [data, setData] = useState({
    email: '',
    password: '',
    password2: '',
    name: '',
    phone: '',
    telegram: '',
    termsAgree: false
  })
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    email: undefined,
    name: undefined,
    password: undefined,
    password2: undefined,
    general: undefined,
    phone: undefined,
    telegram: undefined,
    terms: undefined
  })
  const nameInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const telegramInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const password2InputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  const onChange = (k: string) => (v: string | boolean) => {
    setData({
      ...data,
      [k]: v
    })
  }

  const getErrors = () => {
    const res: Record<string, string> = {}
    if (isEmpty(data.email)) {
      res.email = 'Введите email'
    } else if (!isEmail(data.email)) {
      res.email = 'Неверный формат email'
    }

    if (isEmpty(data.name)) {
      res.name = 'Введите ваше имя и фамилию'
    }

    if (isEmpty(data.phone)) {
      res.phone = 'Введите номер телефона'
    }

    if (isEmpty(data.password)) {
      res.password = 'Введите пароль'
    }

    if (data.password2 !== data.password) {
      res.password2 = 'Пароли не совпадают'
    }

    if (!data.termsAgree) {
      res.terms = 'Необходимо ваше согласие'
    }

    return res
  }

  const resetError = (k: string) => {
    setErrors(errors => ({ ...errors, [k]: undefined }))
  }

  // const checkErrors = () => { }

  const submit = async (): Promise<void> => {
    const errs = getErrors()

    if (isEmpty(errs)) {
      setLoading(true)
      const { authUser, error } = await dbSignUp(
        data.name,
        data.email,
        data.password,
        data.phone,
        data.telegram
      )

      if (!isNil(error)) {
        switch (error) {
          case 'auth/wrong-password':
            errs.password =
              'The email and password you entered did not match our records. Please double-check and try again.'
            break
          default:
            errs.general = authErrorToString(error)
        }
      } else if (authUser) {
        onAuth(authUser)
      }
    }
    setErrors(errs)
    setLoading(false)
  }

  const nameInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.name)}>
      <StyledFormLabel>Имя Фамилия</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='text'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='Иван Иванов'
        value={data.name}
        onFocus={() => resetError('name')}
        ref={nameInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            emailInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('name')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.name}</FormErrorMessage>
    </FormControl>
  )

  const emailInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.email)}>
      <StyledFormLabel>Email</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='example@domain.com'
        value={data.email}
        onFocus={() => resetError('email')}
        ref={phoneInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            passwordInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('email')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.email}</FormErrorMessage>
    </FormControl>
  )

  const phoneInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.phone)}>
      <StyledFormLabel>Телефон</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='+79990000000'
        value={data.phone}
        onFocus={() => resetError('phone')}
        ref={phoneInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            telegramInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('phone')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.phone}</FormErrorMessage>
    </FormControl>
  )

  const telegramInput = (
    <FormControl isInvalid={!isEmpty(errors.telegram)}>
      <StyledFormLabel>Telegram</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='@username'
        value={data.telegram}
        onFocus={() => resetError('telegram')}
        ref={telegramInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            passwordInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('telegram')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.telegram}</FormErrorMessage>
    </FormControl>
  )

  const passwordInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.password)}>
      <StyledFormLabel>Пароль</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='password'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='******'
        value={data.password}
        onFocus={() => resetError('password')}
        ref={password2InputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submit()
          }
        }}
        onChange={e => onChange('password')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.password}</FormErrorMessage>
    </FormControl>
  )

  const password2Input = (
    <FormControl isRequired isInvalid={!isEmpty(errors.password2)}>
      <StyledFormLabel>Подтверждение пароля</StyledFormLabel>
      <Input
        textStyle={'small'}
        type='password'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='******'
        value={data.password2}
        onFocus={() => resetError('password2')}
        ref={password2InputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submit()
          }
        }}
        onChange={e => onChange('password2')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.password2}</FormErrorMessage>
    </FormControl>
  )

  const renderGeneralError = () => {
    if (errors.general) {
      return (
        <GeneralError
          msg={errors.general}
          onReset={() => resetError('general')}
        />
      )
    }
  }

  const checkboxes = (
    <VStack w='full' pt={2}>
      <FormControl isInvalid={!isEmpty(errors.terms)}>
        <HStack align='flex-start'>
          <Checkbox
            size='md'
            // pt={1}
            isChecked={data.termsAgree}
            onChange={e => {
              resetError('terms')
              onChange('termsAgree')(e.target.checked)
            }}
          />
          <Text textStyle={'tag'}>
            Подтверждаю, что ознакомлен/а и принимаю условия
            <Link href='/privacy' target='_blank' color='orange'>
              {' '}
              Положения о конфиденциальности
            </Link>{' '}
            сайта и{' '}
            <Link href='/agreement' target='_blank' color='orange'>
              Договора-оферты
            </Link>
          </Text>
        </HStack>
        <FormErrorMessage fontSize={'xs'} pl={5}>
          {errors.terms}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  )

  return (
    <VStack w='full' flexShrink={0} align='flex-start' spacing={6}>
      <VStack spacing={1} w='full'>
        <AuthBackButton onClick={toSignin} />
        <AuthTitle title={title} subTitle={subTitle} />
      </VStack>
      {renderGeneralError()}
      <VStack w='full' spacing={4}>
        {nameInput}
        {emailInput}
        {phoneInput}
        {telegramInput}
        {passwordInput}
        {password2Input}
      </VStack>
      {checkboxes}
      <VStack w='full' spacing={4}>
        <Button
          w='full'
          variant='primary'
          onClick={submit}
          isLoading={loading}
          size='md'
        >
          Зарегистрироваться
        </Button>
      </VStack>
    </VStack>
  )
}

export default SignupForm
