import { VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import TeacherCard from 'shared/components/TeacherCard'
import map from 'lodash/map'

const TeachersList = () => {
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)

  return (
    <VStack w='full' spacing={6}>
      {map(teachers, t => (
        <TeacherCard key={t.id} t={t} partitions={partitions || {}} />
      ))}
    </VStack>
  )
}

export default TeachersList
