import { HStack, VStack, Text, Box, Stack, Show } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useSelector } from 'model/hooks'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ISeminar } from 'shared/types'
import numeral from 'numeral'

type Props = {
  s: ISeminar
}

const SeminarCalendarCard: FC<Props> = ({ s }) => {
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const teachers = useSelector(state => state.teachers)
  const navigate = useNavigate()

  const sDate = dayjs(s.startDate)
  const eDate = dayjs(s.endDate)

  return (
    <VStack
      w='full'
      h='full'
      rounded={{ base: '8px', lg: '4px' }}
      _hover={{ cursor: 'pointer', boxShadow: 'base' }}
      as='button'
      onClick={() => navigate(`/seminars/${s.id}`)}
    >
      <VStack
        w='full'
        h='full'
        p={2}
        justify={'space-between'}
        bg='wood.50'
        rounded={'4px'}
        spacing={{ base: 5, lg: 2 }}
      >
        <HStack
          w='full'
          justify={'space-between'}
          align={'flex-start'}
          color={{ base: 'black.500', lg: 'black.500' }}
        >
          <Box>
            <Show below='lg'>
              <Text textStyle={'tag'} color='black.500'>
                {sDate.format('DD MMM, dd')}
              </Text>
            </Show>
          </Box>
          <Stack
            align={'flex-end'}
            spacing={{ base: 2, lg: 0 }}
            direction={{ base: 'row', lg: 'column' }}
          >
            <Text textStyle={'tag'} color='black.500'>
              {sDate.format('HH:mm')} - {eDate.format('HH:mm')}
            </Text>
            <Text textStyle={'tag'} color='black.500'>
              {dayjs.tz.guess()}
            </Text>
          </Stack>
        </HStack>
        <VStack
          w='full'
          h='full'
          justify={'center'}
          align={'flex-start'}
          spacing={1}
        >
          <Text
            textStyle={{ base: 'small', lg: 'tag' }}
            fontWeight={{ base: 700, lg: 600 }}
            color='black.950'
            textAlign={'start'}
          >
            {s.title}
          </Text>
          <Stack
            w='full'
            spacing={{ base: 4, lg: 0 }}
            align={'flex-start'}
            direction={{ base: 'row', lg: 'column' }}
          >
            {partitions && s.partitionId && (
              <Text textStyle={'tag'} color='black.500' textAlign={'start'}>
                {partitions[s.partitionId].name}
              </Text>
            )}
            {levels && s.levelId && (
              <Text textStyle={'tag'} color='black.500' textAlign={'start'}>
                {levels[s.levelId].name}
              </Text>
            )}
          </Stack>
        </VStack>
        <Stack
          w='full'
          align={'flex-end'}
          spacing={0.5}
          justify={'space-between'}
          direction={{ base: 'row', lg: 'column' }}
        >
          <Text textStyle={'tag'} color='black.500'>
            {teachers && s.teacherId && teachers[s.teacherId].name}
          </Text>
          <Text textStyle={'tag'} fontWeight={530}>
            {numeral(s.price).format('0 0.[00]')}₽
          </Text>
        </Stack>
      </VStack>
    </VStack>
  )
}

export default SeminarCalendarCard
