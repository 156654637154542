import { FC, ReactNode } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

type Props = ButtonProps & {
  children: ReactNode
}

const StyledButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Button
      bg='brand.900'
      color='brand.50'
      rounded={'full'}
      fontSize={'14px'}
      fontWeight={'500'}
      lineHeight={'15px'}
      px={6}
      py={2}
      height={'auto'}
      letterSpacing={'-0.14px'}
      // h={{ base: '35px', lg: '42px' }}
      // w={{ base: 'full', lg: 'auto' }}
      _hover={{ bg: 'blackAlpha.800', textDecor: 'underline' }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default StyledButton
