import PageContentWrapper from 'shared/components/PageContentWrapper'
import { VStack, useBreakpointValue } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import BigCard from 'components/BigCard'
import { IWebinar } from 'shared/types'
import webinarDefault from 'shared/assets/webinar-default.png'
import { useNavigate } from 'react-router-dom'
import ContentContainer from 'shared/components/ContentContainer'
import { getSortedWebinars } from 'model/selectors/webinars'
import dayjs from 'dayjs'
import BlackPageHeader from 'components/BlackPageHeader'
import Cards, { ICard } from 'components/Cards'

const Webinars = () => {
  const webinars = useSelector(getSortedWebinars)
  const navigate = useNavigate()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  })

  const renderWebinar = (c: IWebinar) => {
    const description = [
      `${dayjs(c.startDate).format('D MMMM')}`,
      `${dayjs(c.startDate).format('HH:mm')} - ${dayjs(c.endDate).format(
        'HH:mm'
      )}`
    ]
    return (
      <BigCard
        onClick={() => navigate(`/webinars/${c.id}`)}
        key={c.id}
        imageUrl={c.imageUrl || webinarDefault}
        title={c.title}
        details={description}
      />
    )
  }

  const renderCards = () => {
    if (isDesktop) {
      const cards = webinars.map(c => {
        const description = [
          `${dayjs(c.startDate).format('D MMMM')}`,
          `${dayjs(c.startDate).format('HH:mm')} - ${dayjs(c.endDate).format(
            'HH:mm'
          )}`
        ]
        const card: ICard = {
          imageUrl: c.imageUrl || webinarDefault,
          onClick: () => navigate(`/webinars/${c.id}`),
          title: c.title,
          desc: description
        }
        return card
      })
      return <Cards cards={cards} amountPerRow={2} aspectRatio={16 / 9} />
    } else {
      return webinars.map(renderWebinar)
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='wood.100'
        backBg='white'
      >
        <BlackPageHeader
          title='Вебинары'
          subtitle='Вебинары — это отличная возможность посмотреть в прямом эфире, как переподаватели разбирают по полочкам сложные темы понятным языком'
          description='Вебинары проводят опытные преподаватели и специалисты в различных областях знаний. Они подготавливают информативные презентации и демонстрируют живые примеры, что помогает студентам лучше понять материал и усвоить сложные концепции. '
        />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        minH='80vh'
      >
        <VStack w='full' py={{ base: 4, lg: 8 }} spacing={{ base: 4, lg: 6 }}>
          {renderCards()}
        </VStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Webinars
