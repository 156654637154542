import { Divider, Text, VStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import SeminarsCalendar from 'pages/seminars/SeminarsCalendar'
import TeachersList from 'components/TeachersList'
import BlackPageHeader from 'components/BlackPageHeader'

const Seminars = () => {
  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='wood.100'
        backBg='white'
      >
        <BlackPageHeader
          title='Семинары'
          subtitle='Семинары — это отличная возможность позаниматься  с преподавателем в удобное для вас время'
          description='Занятия проходят онлайн в группах до 10 человек. Записаться на семинар можно только по теме курса, который был куплен в Библиотеке.'
        />
      </ContentContainer>

      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        pt={{ base: 8, lg: 10 }}
        pb={{ base: 6, lg: 8 }}
        minH='80vh'
      >
        <SeminarsCalendar />
        <VStack
          w='full'
          align={'flex-start'}
          pt={{ base: 9, lg: 16 }}
          spacing={0}
        >
          <Text textStyle={'h2'} fontWeight={700}>
            Преподаватели
          </Text>
          <Divider mt={1.5} mb={{ base: 6, lg: 6 }} />
          <TeachersList />
        </VStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Seminars
