import { useEffect, useMemo, FC, useRef } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  IDay,
  IEntity,
  IEntityType,
  IExaminationAttempt,
  ILesson,
  ITestResult,
  IWeek
} from 'shared/types'
import get from 'lodash/get'
// import has from 'lodash/has'
import forEach from 'lodash/forEach'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import { dbSubscribeToLessons } from 'controllers/lessons'
import { Unsubscribe } from 'firebase/auth'
import {
  dbSubscribeToExaminationAttempts,
  dbSubscribeToTestResults
} from 'controllers/tests'
import { percToAmount, getTestQuestionsAmount } from 'shared/utils/tests'
import Loading from 'components/Loading'
import { dbSubscribeToEntity } from 'controllers/entity'
import { useSelector } from 'model/hooks'
import { getPaymentsByEntityId } from 'model/selectors/userPayments'
// import { buyFreeCourse } from 'controllers/userPayments'

type Props = {}

const LessonsDataProvider: FC<Props> = () => {
  const user = useSelector(state => state.user)
  const [lessons, setLessons] = useState<Record<string, ILesson> | null>(null)
  const [testResults, setTestResults] = useState<ITestResult[] | null>(null)
  const [examinationAttempts, setExaminationAttempts] = useState<Record<
    string,
    IExaminationAttempt
  > | null>(null)
  const userPaymentsByEntity = useSelector(getPaymentsByEntityId)
  const [entity, setEntity] = useState<IEntity | null>(null)
  const entityUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const lessonsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const examinationAttemptsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const testResultsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const { entityId, entityType } = useParams() as {
    entityId: string
    entityType: IEntityType
  }

  // useEffect(() => {
  //   const run = async () => {
  //     if (
  //       entityType === IEntityType.FREE &&
  //       !has(userPaymentsByEntity, entityId)
  //     ) {
  //       const success = await buyFreeCourse(entityId)
  //       if (success) {
  //         if (lessonsUnsubscribeRef.current) lessonsUnsubscribeRef.current()
  //         lessonsUnsubscribeRef.current = dbSubscribeToLessons(
  //           entityId,
  //           lessons => setLessons(lessons)
  //         )
  //       }
  //     }
  //   }
  //   run()
  // }, [entityId, entityType, userPaymentsByEntity])

  const onEntityReceived = (e: IEntity | null) => {
    if (e) {
      if (e.content && e.content.length > 0) {
        const entityContent: IWeek[] = []
        forEach(e.content, w => {
          if (w.enabled) {
            const newW: IWeek = {
              ...w,
              days: filter(w.days, d => get(d, 'disabled', true))
            }
            entityContent.push(newW)
          }
        })
        setEntity({
          ...e,
          content: entityContent
        })
      } else {
        setEntity(e)
      }
    } else {
      setEntity(null)
    }
  }

  useEffect(() => {
    if (entityUnsubscribeRef.current) {
      entityUnsubscribeRef.current()
    }
    entityUnsubscribeRef.current = dbSubscribeToEntity(
      entityId,
      entityType,
      onEntityReceived
    )
    return () => {
      if (entityUnsubscribeRef.current) {
        entityUnsubscribeRef.current()
      }
    }
  }, [entityId, entityType])

  useEffect(() => {
    if (lessonsUnsubscribeRef.current) lessonsUnsubscribeRef.current()
    lessonsUnsubscribeRef.current = dbSubscribeToLessons(entityId, lessons =>
      setLessons(lessons)
    )

    if (user) {
      if (examinationAttemptsUnsubscribeRef.current)
        examinationAttemptsUnsubscribeRef.current()
      examinationAttemptsUnsubscribeRef.current =
        dbSubscribeToExaminationAttempts(entityId, user.id, ea =>
          setExaminationAttempts(ea)
        )
    }

    if (testResultsUnsubscribeRef.current) testResultsUnsubscribeRef.current()
    testResultsUnsubscribeRef.current = dbSubscribeToTestResults(
      entityId,
      r => {
        // console.log('test results', r)
        setTestResults(r)
      }
    )

    return () => {
      if (lessonsUnsubscribeRef.current) lessonsUnsubscribeRef.current()
      if (testResultsUnsubscribeRef.current) testResultsUnsubscribeRef.current()
    }
  }, [entityId, userPaymentsByEntity, user])

  const resultsByLessonId = useMemo(() => {
    if (testResults === null) {
      return null
    } else {
      return keyBy(testResults, 'lessonId')
    }
  }, [testResults])

  const [passedWeeks, passedDays] = useMemo(() => {
    const weeks: Record<string, boolean> = {}
    const days: Record<string, boolean> = {}

    if (entity && entity.content && resultsByLessonId !== null) {
      forEach(entity.content, (w: IWeek, weekNum: number) => {
        // console.log('%c------ start process Weeek', 'color: gold', w)
        // if (weekNum === 0) {
        //   availableWeeks[w.id] = true
        // } else {
        //   const prevWeekId = get(entity.content, [weekNum - 1, 'id'], '')
        //   if (get(availableWeeks, prevWeekId, false)) {
        //     availableWeeks[w.id] = true
        //   }
        // }

        const daysAmount = w.days.length
        let passedDaysAmount = 0

        forEach(w.days, (d: IDay, dayNum: number) => {
          let lessonsAmount = 0
          let passedLessonsAmount = 0
          forEach(d.lessons, (lessonId: string) => {
            const l = get(lessons, lessonId)
            const isNeeded = l && getTestQuestionsAmount(l) > 0
            if (isNeeded) {
              lessonsAmount += 1

              const lessonIsPassed = get(
                resultsByLessonId,
                [lessonId, 'isComplete'],
                false
              )
              if (lessonIsPassed) {
                passedLessonsAmount += 1
              }
            }
          })
          const dayIsPassed =
            lessonsAmount === 0 ||
            percToAmount(lessonsAmount, d.perc) <= passedLessonsAmount

          if (dayIsPassed) {
            passedDaysAmount += 1
            days[d.id] = true
          } else if (dayNum > 0) {
            const prevDayId = get(w.days, [dayNum - 1, 'id'], '')
            const prevDayIsPassed = get(days, prevDayId, false)
            if (prevDayIsPassed) {
              days[d.id] = false
            }
          } else if (dayNum === 0) {
            if (weekNum === 0) {
              days[d.id] = false
            } else {
              const prevWeekId = get(entity.content, [weekNum - 1, 'id'], '')
              const prevWeekIsPassed = get(weeks, prevWeekId, false)
              if (prevWeekIsPassed) {
                days[d.id] = false
              }
            }
          }

          // console.log(
          //   w.id,
          //   ': process day',
          //   d,
          //   'lessonsAmount',
          //   lessonsAmount,
          //   'passedLessonsAmount',
          //   passedLessonsAmount,
          //   'dayIsPassed',
          //   dayIsPassed
          // )
        })

        const weekIsPassed =
          daysAmount === 0 ||
          percToAmount(daysAmount, w.perc) <= passedDaysAmount

        // console.log(
        //   'process week',
        //   w,
        //   'passedDaysAmount',
        //   passedDaysAmount,
        //   'daysAmount',
        //   daysAmount,
        //   'weekIsPassed',
        //   weekIsPassed
        // )

        if (weekNum === 0) {
          weeks[w.id] = weekIsPassed
        } else if (weekNum > 0) {
          const prevWeekId = get(entity.content, [weekNum - 1, 'id'], '')
          const prevWeekIsPassed = get(weeks, prevWeekId, false)
          if (prevWeekIsPassed) {
            weeks[w.id] = weekIsPassed
          }
        }
      })
    }
    return [weeks, days]
  }, [entity, lessons, resultsByLessonId])

  const providerValue = useMemo(() => {
    return {
      lessons,
      testResults: testResults || [],
      entity,
      entityType,
      passedWeeks,
      passedDays,
      examinationAttempts
    }
  }, [
    lessons,
    entity,
    entityType,
    testResults,
    passedWeeks,
    passedDays,
    examinationAttempts
  ])

  return (
    <LessonsContext.Provider value={providerValue}>
      {testResults === null ? <Loading /> : <Outlet />}
    </LessonsContext.Provider>
  )
}

export default LessonsDataProvider
