import { useState, useMemo, useEffect } from 'react'
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  Show,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import { ReactComponent as BsFilterLeft } from 'shared/assets/bsFilterLeft.svg'
import { ReactComponent as BsChevronLeft } from 'shared/assets/bsChevronLeft.svg'
import { ReactComponent as BsChevronRight } from 'shared/assets/bsChevronRight.svg'
import { ReactComponent as BsChevronUp } from 'shared/assets/bsChevronUp.svg'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import get from 'lodash/get'
import has from 'lodash/has'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import capitalize from 'lodash/capitalize'
import { getAvailableSeminars } from 'model/selectors/seminars'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import last from 'lodash/last'
import { ISeminar } from 'shared/types'
import SeminarDatePicker from 'pages/seminars/SeminarDatePicker'
import compact from 'lodash/compact'
import SeminarsMonth from 'pages/seminars/SeminarsMonth'
import SeminarsWeek from 'pages/seminars/SeminarsWeek'
import SeminarCalendarCard from 'components/seminars/SeminarCalendarCard'
import { useSearchParams } from 'react-router-dom'

const SeminarsCalendar = () => {
  const { isOpen, onToggle } = useDisclosure()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const seminars: ISeminar[] = useSelector(getAvailableSeminars)
  const [showMonth, setShowMonth] = useState(
    searchParams.get('m') !== null ? searchParams.get('m') === 'true' : true
  )
  const [startDate, setStartDate] = useState(
    dayjs(
      searchParams.get('t') !== null ? Number(searchParams.get('t')) : undefined
    )
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
  )
  const [selectedTeachers, setSelectedTeachers] = useState<
    Record<string, boolean>
  >({})
  const [selectedPartitions, setSelectedPartitions] = useState<
    Record<string, boolean>
  >({})
  const [selectedLevels, setSelectedLevels] = useState<Record<string, boolean>>(
    {}
  )

  useEffect(() => {
    setSearchParams({ t: (+startDate).toString(), m: showMonth.toString() })
  }, [showMonth, startDate])

  const { firstSeminarDate, lastSeminarDate } = useMemo(() => {
    return {
      firstSeminarDate: dayjs(get(first(seminars), 'startDate')),
      lastSeminarDate: dayjs(get(last(seminars), 'startDate'))
    }
  }, [seminars])

  useEffect(() => {
    if (showMonth) {
      setStartDate(startDate.date(1).hour(0).minute(0).second(0))
    } else if (isDesktop) {
      const newStartDate = startDate.isBefore(firstSeminarDate)
        ? firstSeminarDate
        : startDate
      setStartDate(
        newStartDate
          .subtract(newStartDate.weekday(), 'days')
          .hour(0)
          .minute(0)
          .second(0)
      )
    }
  }, [showMonth, firstSeminarDate, isDesktop])

  const availableTeachers = useMemo(() => {
    return keyBy(seminars, 'teacherId')
  }, [seminars])
  const availablePartitions = useMemo(() => {
    return keyBy(seminars, 'partitionId')
  }, [seminars])
  const availableLevels = useMemo(() => {
    return keyBy(seminars, 'levelId')
  }, [seminars])

  const filteredSeminars = useMemo(() => {
    console.log('selectedTeachers', selectedTeachers)
    return seminars.filter(s => {
      const teacherMatches =
        isEmpty(selectedTeachers) ||
        (s.teacherId && get(selectedTeachers, s.teacherId, false))
      const partitionMatches =
        isEmpty(selectedPartitions) ||
        (s.partitionId && get(selectedPartitions, s.partitionId, false))
      const levelMatches =
        isEmpty(selectedLevels) ||
        (s.levelId && get(selectedLevels, s.levelId, false))
      return teacherMatches && partitionMatches && levelMatches
    })
  }, [seminars, selectedTeachers, selectedPartitions, selectedLevels])

  const sortedPartitions = useMemo(
    () => sortBy(partitions, 'name'),
    [partitions]
  )

  const sortedLevels = useMemo(() => sortBy(levels, 'name'), [levels])
  const sortedTeachers = useMemo(() => sortBy(teachers, 'name'), [teachers])

  const filterSwitch = (
    <HStack
      spacing={0}
      as={isEmpty(seminars) ? undefined : 'button'}
      _hover={{ cursor: isEmpty(seminars) ? 'auto' : 'pointer' }}
      onClick={isEmpty(seminars) ? undefined : onToggle}
      color={isEmpty(seminars) ? 'darkGray' : 'black'}
    >
      <BsFilterLeft />
      <Text textStyle={'body'}>Фильтры</Text>
      <Box
        pl={4}
        pt={1}
        sx={{
          svg: {
            transition: 'transform 200ms ease-out',
            transform: ` rotate(${isOpen ? 0 : 180}deg)`
          }
        }}
      >
        <BsChevronUp />
      </Box>
    </HStack>
  )

  const teacherFilter = (
    <VStack w='full' align={'flex-start'} spacing={{ base: 4, lg: 2 }}>
      <Text textStyle={'body'} color='black.500'>
        Преподаватель
      </Text>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: 4, lg: 8 }}
      >
        {map(sortedTeachers, (t, i) => {
          return (
            <HStack key={t.id} spacing={2}>
              <Checkbox
                isDisabled={!has(availableTeachers, t.id)}
                isChecked={get(selectedTeachers, t.id, false)}
                onChange={e =>
                  setSelectedTeachers(selected => {
                    if (e.target.checked) {
                      return {
                        ...selected,
                        [t.id]: e.target.checked
                      }
                    } else {
                      const newV = { ...selected }
                      delete newV[t.id]
                      return newV
                    }
                  })
                }
              >
                <Text textStyle={'body'}>{t.name}</Text>
              </Checkbox>
            </HStack>
          )
        })}
      </Stack>
    </VStack>
  )

  const partitionFilter = (
    <VStack w='full' align={'flex-start'} spacing={{ base: 4, lg: 2 }}>
      <Text textStyle={'body'} color='black.500'>
        Раздел
      </Text>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: 4, lg: 8 }}
      >
        {map(sortedPartitions, (t, i) => {
          return (
            <HStack key={t.id} spacing={2}>
              <Checkbox
                isDisabled={!has(availablePartitions, t.id)}
                isChecked={get(selectedPartitions, t.id, false)}
                onChange={e =>
                  setSelectedPartitions(selected => {
                    if (e.target.checked) {
                      return {
                        ...selected,
                        [t.id]: e.target.checked
                      }
                    } else {
                      const newV = { ...selected }
                      delete newV[t.id]
                      return newV
                    }
                  })
                }
              >
                <Text textStyle={'body'}>{t.name}</Text>
              </Checkbox>
            </HStack>
          )
        })}
      </Stack>
    </VStack>
  )

  const levelFilter = (
    <VStack w='full' align={'flex-start'} spacing={{ base: 4, lg: 2 }}>
      <Text textStyle={'body'} color='black.500'>
        Уровень
      </Text>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: 4, lg: 8 }}
      >
        {map(sortedLevels, (t, i) => {
          return (
            <HStack key={t.id} spacing={2}>
              <Checkbox
                isDisabled={!has(availableLevels, t.id)}
                isChecked={get(selectedLevels, t.id, false)}
                onChange={e =>
                  setSelectedLevels(selected => {
                    if (e.target.checked) {
                      return {
                        ...selected,
                        [t.id]: e.target.checked
                      }
                    } else {
                      const newV = { ...selected }
                      delete newV[t.id]
                      return newV
                    }
                  })
                }
              >
                <Text textStyle={'body'}>{t.name}</Text>
              </Checkbox>
            </HStack>
          )
        })}
      </Stack>
    </VStack>
  )

  const filtersContent = (
    <VStack w='full' pt={4} spacing={0}>
      <Show above='lg'>
        <Divider m={0} />
        <VStack spacing={{ base: 6, lg: 4 }} w='full' pt={4}>
          {teacherFilter}
          {partitionFilter}
          {levelFilter}
        </VStack>
      </Show>
      <Show below='lg'>
        <HStack w='full' align={'flex-start'}>
          <VStack spacing={{ base: 6, lg: 4 }}>
            {teacherFilter}
            {levelFilter}
          </VStack>
          <Flex flex={0.5}>{partitionFilter}</Flex>
        </HStack>
      </Show>
    </VStack>
  )

  const renderModeSwitch = () => {
    return (
      <HStack spacing={2} pr={2}>
        <Box
          borderWidth={1}
          borderColor={showMonth ? 'black' : 'darkGray'}
          px={2}
          py={1}
          rounded={'4px'}
          as='button'
          _hover={{ cursor: 'pointer' }}
          onClick={showMonth ? undefined : () => setShowMonth(true)}
        >
          <Text textStyle={'small'} color={showMonth ? 'black' : 'darkGray'}>
            Месяц
          </Text>
        </Box>
        <Box
          borderWidth={1}
          borderColor={!showMonth ? 'black' : 'darkGray'}
          px={2}
          py={1}
          rounded={'4px'}
          as='button'
          _hover={{ cursor: 'pointer' }}
          onClick={!showMonth ? undefined : () => setShowMonth(false)}
        >
          <Text textStyle={'small'} color={!showMonth ? 'black' : 'darkGray'}>
            Неделя
          </Text>
        </Box>
      </HStack>
    )
  }

  const renderDesktopTimeSelect = () => {
    console.log('startDate', startDate.format())
    if (showMonth) {
      const datePrev = startDate.subtract(1, 'months')
      const canSwitchBack =
        dayjs().month() === datePrev.month() ||
        firstSeminarDate.isBefore(startDate)
      const dateNext = startDate.add(1, 'months')
      const canSwitchNext = lastSeminarDate.isAfter(dateNext)
      return (
        <HStack w='52' justify={'flex-end'}>
          <IconButton
            size='sm'
            aria-label='prevMonth'
            icon={<BsChevronLeft />}
            variant={'ghost'}
            onClick={() => setStartDate(datePrev)}
            isDisabled={!canSwitchBack}
          />
          <Text textStyle={'body'}>{capitalize(startDate.format('MMMM'))}</Text>
          <IconButton
            size='sm'
            aria-label='prevMonth'
            icon={<BsChevronRight />}
            variant={'ghost'}
            onClick={() => setStartDate(dateNext)}
            isDisabled={!canSwitchNext}
          />
        </HStack>
      )
    } else {
      const datePrev = startDate.subtract(1, 'weeks')
      const canSwitchBack = firstSeminarDate.isBefore(startDate)
      const dateNext = startDate.add(1, 'weeks')
      const canSwitchNext = lastSeminarDate.isAfter(dateNext)
      return (
        <HStack w='60' justify={'flex-end'}>
          <IconButton
            size='sm'
            aria-label='prevMonth'
            icon={<BsChevronLeft />}
            variant={'ghost'}
            onClick={() => setStartDate(datePrev)}
            isDisabled={!canSwitchBack}
          />
          <Text textStyle={'body'}>
            {startDate.format('MMM D').split('.').join(' ')} -{' '}
            {startDate
              .add(1, 'weeks')
              .subtract(1, 'seconds')
              .format('MMM D')
              .split('.')
              .join(' ')}
          </Text>
          <IconButton
            size='sm'
            aria-label='prevMonth'
            icon={<BsChevronRight />}
            variant={'ghost'}
            onClick={() => setStartDate(dateNext)}
            isDisabled={!canSwitchNext}
          />
        </HStack>
      )
    }
  }

  const timeSelect = () => {
    return (
      <>
        <Show above='lg'>
          <HStack>
            {renderModeSwitch()}
            {renderDesktopTimeSelect()}
          </HStack>
        </Show>
        <Show below='lg'>
          <SeminarDatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            showMonth={showMonth}
            setShowMonth={setShowMonth}
            seminars={filteredSeminars}
            firstSeminarDate={firstSeminarDate}
            lastSeminarDate={lastSeminarDate}
          />
        </Show>
      </>
    )
  }

  const renderDesktopCalendar = () => {
    if (showMonth) {
      return <SeminarsMonth startDate={startDate} seminars={filteredSeminars} />
    } else {
      return <SeminarsWeek startDate={startDate} seminars={filteredSeminars} />
    }
  }

  const noSeminarsMobile = (
    <VStack px={10} py={8}>
      <Text textStyle={'small'} textAlign={'center'}>
        На данный момент в этом месяце нет доступных семинаров
      </Text>
    </VStack>
  )

  const renderMobileCalendar = () => {
    const endDate = showMonth
      ? startDate.add(1, 'month')
      : startDate.add(1, 'days')
    const cards = compact(
      map(filteredSeminars, s => {
        const d = dayjs(s.startDate)
        if (d.isAfter(startDate) && d.isBefore(endDate)) {
          return <SeminarCalendarCard s={s} key={s.id} />
        }
      })
    )
    return <VStack w='full'>{isEmpty(cards) ? noSeminarsMobile : cards}</VStack>
  }

  return (
    <VStack
      spacing={0}
      w='full'
      align='flex-start'
      sx={{ '.chakra-collapse': { w: 'full' } }}
    >
      <Text textStyle={'h2'} fontWeight={700}>
        Календарь
      </Text>
      <HStack w='full' pt={{ base: '18px', lg: 8 }} justify={'space-between'}>
        {filterSwitch}
        {timeSelect()}
      </HStack>
      {/* <Divider mt={{ base: '6px', lg: '14px' }} mb={4} /> */}
      <Collapse in={isOpen} animateOpacity>
        {filtersContent}
      </Collapse>
      <Flex w='full' pt={4}>
        <Show above='lg'>{renderDesktopCalendar()}</Show>
        <Show below='lg'>{renderMobileCalendar()}</Show>
      </Flex>

      {/* {renderCalendarOld()} */}
    </VStack>
  )
}

export default SeminarsCalendar
