import { functions } from 'controllers/db'
import { httpsCallable } from 'firebase/functions'

export const dbGetPayanywaySig = async (
  entityId: string,
  entityType: string
) => {
  try {
    const f = httpsCallable(functions, 'payanywaySig')
    const res = await f({ entityId, entityType })
    console.log('dbGetPayanywaySig function res:', res)
    return res.data
  } catch (e) {
    console.error('dbGetPayanywaySig error', e)
    return null
  }
}
