import PageContentWrapper from 'shared/components/PageContentWrapper'
import { Show, VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import courseDefault from 'shared/assets/course-default.png'
import { useNavigate } from 'react-router-dom'
import ContentContainer from 'shared/components/ContentContainer'
import BlackPageHeader from 'components/BlackPageHeader'
import Cards, { ICard } from 'components/Cards'
import { getSortedFreeCourses } from 'model/selectors/free'

const Frees = () => {
  const frees = useSelector(getSortedFreeCourses)
  const navigate = useNavigate()

  const renderCards = () => {
    const cards = frees.map(c => {
      const card: ICard = {
        imageUrl: c.imageUrl || courseDefault,
        onClick: () => navigate(`/free/${c.id}`),
        title: c.title,
        desc: []
      }
      return card
    })
    return (
      <>
        <Show above='lg'>
          <Cards cards={cards} amountPerRow={4} aspectRatio={1} />
        </Show>
        <Show below='lg'>
          <Cards cards={cards} amountPerRow={2} aspectRatio={1} />
        </Show>
      </>
    )
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='wood.100'
        backBg='white'
      >
        <BlackPageHeader
          title='Бесплатное'
          subtitle='Раздел «Бесплатное» — это уникальная возможность пройти демо версии всех наших онлайн-курсов, а также интересные уроки на различные темы'
          description='Вам предоставляется доступ к 1-2 неделям каждого курса: обучающим материалам, включая видео лекции, пробные уроки, практические задания и тесты. Вы абсолютно бесплатно можете попробовать все наши инструменты на платформе и затем записаться на понравившиеся вам курсы, которые наилучшим образом соответствуют вашим интересам, учебным целям и профессиональным потребностям.'
        />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        full
        minH='80vh'
      >
        <VStack w='full' py={{ base: 4, lg: 8 }} spacing={{ base: 4, lg: 6 }}>
          {renderCards()}
        </VStack>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Frees
