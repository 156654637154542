import { HStack, Text, Box } from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconCheckmarkFilled } from 'shared/assets/bsFillCheckmark.svg'
import { ReactComponent as IconX } from 'shared/assets/bsFillXCircle.svg'
import { ReactComponent as IconCheckmark } from 'shared/assets/bsCheck.svg'

type Props = {
  title: string
  toPath: string
  isDisabled?: boolean
  status: 'success' | 'failed' | 'checkmark' | 'none'
  progress?: {
    correctAnswersAmount: number
    questionsAmount: number
    status: 'success' | 'failed' | 'none'
  }
}

const EntityRowStatus: FC<Props> = ({
  title,
  toPath,
  isDisabled,
  status,
  progress
}) => {
  const navigate = useNavigate()

  const color = useMemo(() => {
    switch (status) {
      case 'success':
        return 'green.800'
      case 'failed':
        return 'red.800'
      case 'checkmark':
        return 'black.200'
      case 'none':
        return 'black.200'
    }
  }, [status])

  const renderIcon = () => {
    switch (status) {
      case 'success':
        return (
          <Box color={color}>
            <IconCheckmarkFilled />
          </Box>
        )
      case 'failed':
        return (
          <Box color={color}>
            <IconX />
          </Box>
        )
      case 'checkmark':
        return (
          <Box color={color}>
            <IconCheckmark width={20} height={20} />
          </Box>
        )
      default:
        break
    }
  }

  const renderProgress = () => {
    if (progress) {
      return (
        <Box
          bg={
            progress.status === 'success'
              ? 'green.100'
              : progress.status === 'failed'
              ? 'red.100'
              : undefined
          }
          borderRadius={'2px'}
          p={1}
        >
          <Text
            textStyle={'tag'}
            color={
              isDisabled || progress.status === 'none'
                ? 'black.200'
                : progress.status === 'success'
                ? 'green.800'
                : progress.status === 'failed'
                ? 'red.800'
                : undefined
            }
            // color={
            //   isDisabled || progress.status === 'none' ? 'midGray' : 'white'
            // }
          >
            {progress.correctAnswersAmount}/{progress.questionsAmount}
          </Text>
        </Box>
      )
    }
  }

  return (
    <HStack
      w='full'
      py={4}
      as='button'
      _hover={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={isDisabled ? undefined : () => navigate(toPath)}
      justify={'space-between'}
    >
      <Text
        textStyle='body'
        textAlign={'start'}
        color={
          progress && status === 'checkmark'
            ? 'black.950'
            : progress && progress.status === 'success'
            ? 'green.800'
            : progress && progress.status === 'failed'
            ? 'red.800'
            : isDisabled
            ? 'black.200'
            : 'black.950'
        }
      >
        {title}
      </Text>
      <HStack spacing={6}>
        {renderProgress()}
        {renderIcon()}
      </HStack>
    </HStack>
  )
}

export default EntityRowStatus
