import PageContentWrapper from 'shared/components/PageContentWrapper'
import BlackPageHeader from 'components/BlackPageHeader'
import LessonsCards from 'pages/home/LessonsCards'
import ContentContainer from 'shared/components/ContentContainer'

const Library = () => {
  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='wood.100'
        backBg='white'
      >
        <BlackPageHeader
          title='Библиотека'
          subtitle='Библиотека — это обновляемая коллекция онлайн-курсов на различные темы для самостоятельного прохождения в любое время'
          description='Вам предоставляются обучающие материалы, включая видео-лекции, текстовые уроки, практические задания и тесты. Вы можете выбирать курсы, которые наилучшим образом соответствуют вашим интересам, учебным целям и профессиональным потребностям.'
        />
      </ContentContainer>
      <ContentContainer
        py={8}
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        minH='80vh'
      >
        <LessonsCards />
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Library
