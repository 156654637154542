import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  FormControl,
  Input,
  VStack,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react'
import PrimaryButton from 'shared/components/PrimaryButton'
import { dbChangePassword } from 'controllers/auth'
import isEmpty from 'lodash/isEmpty'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import { IItemInfo } from 'shared/types'
import { authErrorToString } from 'shared/utils/stringFirebaseError'

export interface IChangePasswordModal {
  open: () => void
}

type ErrorsT = {
  password?: string
  newPassword?: string
  newPassword2?: string
}

type Props = {}

const ChangePasswordModal: ForwardRefRenderFunction<
  IChangePasswordModal,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [errors, setErrors] = useState<ErrorsT>({})
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    open: (itemInfo: IItemInfo) => {
      setPassword('')
      setNewPassword('')
      setNewPassword2('')
      setErrors({})
      setLoading(false)
      onOpen()
      // renderPaymentForm(itemInfo)
    }
  }))

  const apply = async () => {
    const newErrors: ErrorsT = {}
    if (password === '') {
      newErrors.password = 'Введите текущий пароль'
    }

    if (newPassword === '') {
      newErrors.newPassword = 'Введите новый пароль'
    } else if (newPassword.length < 3) {
      newErrors.newPassword = 'Новый пароль слишком короткий'
    }

    if (newPassword !== newPassword2) {
      newErrors.newPassword2 = 'Пароли не совпадают'
    }

    setErrors(newErrors)
    if (isEmpty(newErrors)) {
      setLoading(true)
      const errorCode = await dbChangePassword(password, newPassword)
      if (errorCode !== null) {
        setErrors({ password: authErrorToString(errorCode) })
      } else {
        onClose()
        toast({
          title: 'Пароль изменен',
          description: 'С этого момента используйте новый пароль для входа.',
          status: 'success',
          isClosable: true,
          duration: 3000
        })
      }
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent w='96' minH='60' rounded='8px'>
        <ModalBody p={6} h={'full'}>
          <VStack spacing={6} w='full' align={'flex-start'}>
            <Text textStyle={'h3'} fontWeight={700}>
              Введите новые данные
            </Text>

            <FormControl isInvalid={!!errors.password}>
              <Input
                variant={'flushed'}
                placeholder='Текущий пароль'
                type='password'
                h={'22px'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                onFocus={() =>
                  setErrors(errors => ({ ...errors, password: undefined }))
                }
              />
              <FormErrorMessage fontSize='xs'>
                {errors.password}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.newPassword}>
              <Input
                variant={'flushed'}
                placeholder='Новый пароль'
                type='password'
                h={'22px'}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                onFocus={() =>
                  setErrors(errors => ({ ...errors, newPassword: undefined }))
                }
              />
              <FormErrorMessage fontSize='xs'>
                {errors.newPassword}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.newPassword2}>
              <Input
                variant={'flushed'}
                placeholder='Повторите новый пароль'
                type='password'
                h='22px'
                value={newPassword2}
                onChange={e => setNewPassword2(e.target.value)}
                onFocus={() =>
                  setErrors(errors => ({ ...errors, newPassword2: undefined }))
                }
              />
              <FormErrorMessage fontSize='xs'>
                {errors.newPassword2}
              </FormErrorMessage>
            </FormControl>

            <PrimaryButton w={'full'} onClick={apply} isLoading={loading}>
              Сохранить
            </PrimaryButton>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(ChangePasswordModal)
