import {
  Box,
  Center,
  HStack,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  Flex
} from '@chakra-ui/react'
import { PickerPanel } from 'rc-picker'
import ruRu from 'rc-picker/lib/locale/ru_RU'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import { FC, useRef, useMemo, useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { ISeminar } from 'shared/types'
import isArray from 'lodash/isArray'
import { ReactComponent as BsChevronDown } from 'shared/assets/bsChevronDown.svg'
import { ReactComponent as BsCalendar } from 'shared/assets/bsCalendar.svg'
import { ReactComponent as BsChevronLeft } from 'shared/assets/bsChevronLeft.svg'
import { ReactComponent as BsChevronRight } from 'shared/assets/bsChevronRight.svg'

type Props = {
  setStartDate: (d: Dayjs) => void
  startDate: Dayjs
  showMonth: boolean
  setShowMonth: (v: boolean) => void
  seminars: ISeminar[]
  firstSeminarDate: Dayjs
  lastSeminarDate: Dayjs
}

const SeminarDatePicker: FC<Props> = ({
  setStartDate,
  startDate,
  showMonth,
  setShowMonth,
  firstSeminarDate,
  lastSeminarDate,
  seminars
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [value, setValue] = useState(startDate)
  const initialFocusRef = useRef(null)

  const curMonth = useMemo(() => value.month(), [value])

  const data = useMemo(() => {
    const res: Record<string, ISeminar[]> = {}
    for (const s of seminars) {
      const dy = dayjs(s.startDate).dayOfYear().toString()
      if (!isArray(res[dy])) {
        res[dy] = []
      }
      res[dy].push(s)
    }
    return res
  }, [seminars])

  useEffect(() => {
    setValue(startDate)
  }, [startDate])

  const onDateSelected = (d: Dayjs) => {
    onClose()
    setStartDate(d)
    setShowMonth(false)
  }

  const onSelectMonth = () => {
    onClose()
    setStartDate(value)
    setShowMonth(true)
  }

  const renderMonthSwitch = () => {
    const datePrev = value.date(1).subtract(1, 'months')
    const canSwitchBack =
      dayjs().month() === datePrev.month() || firstSeminarDate.isBefore(value)
    const dateNext = value.date(1).add(1, 'months')
    const canSwitchNext = lastSeminarDate.isAfter(dateNext)
    return (
      <HStack w='52' justify={'flex-end'}>
        <IconButton
          size='sm'
          aria-label='prevMonth'
          icon={<BsChevronLeft />}
          variant={'ghost'}
          onClick={() => setValue(datePrev)}
          isDisabled={!canSwitchBack}
        />
        <Text textStyle={'body'}>{capitalize(value.format('MMMM, YYYY'))}</Text>
        <IconButton
          size='sm'
          aria-label='prevMonth'
          icon={<BsChevronRight />}
          variant={'ghost'}
          onClick={() => setValue(dateNext)}
          isDisabled={!canSwitchNext}
        />
      </HStack>
    )
  }

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement='auto'
      // closeOnBlur={false}
      // matchWidth
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverAnchor>
        <Box />
      </PopoverAnchor>
      <PopoverTrigger>
        <HStack as='button' onClick={onToggle}>
          <BsCalendar />
          <Text textStyle='body'>
            {showMonth
              ? capitalize(startDate.format('MMMM'))
              : startDate.format('D MMMM, dd')}
          </Text>
          <BsChevronDown />
        </HStack>
      </PopoverTrigger>
      <PopoverContent
        color='black'
        bg='white'
        // borderColor='blue.800 '
        w='fit-content'
        boxShadow='0px 45.868106842041016px 117.21849822998047px 0px rgba(0, 0, 0, 0.18)'
        border='1.274px solid lightGray'
        rounded={'sm'}
      >
        <PopoverBody
          p={8}
          sx={{
            '.rc-picker-header': {
              display: 'none'
              // display: 'flex',
              // flexDirection: 'row',
              // width: 'full',
              // justifyContent: 'center',
              // textStyle: 'body'
            },
            // '.rc-picker-header-super-prev-btn': {
            //   display: 'none'
            // },
            // '.rc-picker-header-prev-btn': {
            //   display: 'none'
            // },
            // '.rc-picker-header-next-btn': {
            //   display: 'none'
            // },
            // '.rc-picker-header-super-next-btn': {
            //   display: 'none'
            // },
            '.rc-picker-body': {
              pt: 4
            },
            '.rc-picker-content th': {
              textStyle: 'tag',
              pb: 4
            },
            '.rc-picker-cell': {
              w: 9,
              h: 9,
              textAlign: 'center'
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center'
            }
          }}
        >
          <HStack w='full' justify='center'>
            {renderMonthSwitch()}
          </HStack>
          <PickerPanel<Dayjs>
            value={value}
            locale={ruRu}
            picker='date'
            generateConfig={dayjsGenerateConfig}
            cellRender={(d: Dayjs) => {
              const isCurrent = d.month() === curMonth
              const daynum = d.dayOfYear()
              const hasSeminars = isArray(get(data, daynum))
              // const weekNum = ~~(d.date() / 7) + 1
              // const sDayNum = d.day() === 0 ? 6 : d.day() - 1
              // const hasSeminars = !isEmpty(
              //   get(seminarsByWeek, [weekNum, sDayNum])
              // )
              const sameDay =
                isCurrent && !showMonth && d.isSame(startDate, 'day')
              return (
                <Center
                  boxSize={9}
                  bg={sameDay ? 'black' : 'white'}
                  borderRadius={'4px'}
                >
                  <Text
                    textStyle={'body'}
                    color={
                      sameDay ? 'white' : isCurrent ? 'black' : 'lightGray'
                    }
                    textDecoration={hasSeminars ? 'underline' : undefined}
                  >
                    {d.date()}
                  </Text>
                </Center>
              )
            }}
            disabledDate={(d: Dayjs) => {
              const daynum = d.dayOfYear()
              const hasSeminars = isArray(get(data, daynum))
              return !hasSeminars
            }}
            onSelect={onDateSelected}
          />
          <HStack w='full' justify={'center'} pt={8}>
            <Flex
              bg={
                showMonth && value.isSame(startDate, 'month')
                  ? 'black'
                  : 'white'
              }
              borderWidth={1}
              borderColor='black'
              as='button'
              onClick={onSelectMonth}
              py={'5px'}
              px={4}
              rounded={'full'}
            >
              <Text
                textStyle={'tag'}
                fontWeight={500}
                color={
                  showMonth && value.isSame(startDate, 'month')
                    ? 'white'
                    : 'black'
                }
              >
                Выбрать весь месяц
              </Text>
            </Flex>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SeminarDatePicker
