import { createSelector } from '@reduxjs/toolkit'
import { getUserEntities } from 'model/selectors/base'
import { IEntityType } from 'shared/types'

export const getAllUserEntities = createSelector(
  [getUserEntities],
  userEntities => {
    return {
      ...userEntities[IEntityType.COURSES],
      ...userEntities[IEntityType.LIBRARY],
      ...userEntities[IEntityType.SEMINARS],
      ...userEntities[IEntityType.WEBINARS],
      ...userEntities[IEntityType.CONFERENCES],
      ...userEntities[IEntityType.FREE]
    }
  }
)
