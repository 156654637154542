import { auth, db } from 'controllers/db'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import get from 'lodash/get'
import { receiveChannels } from 'model/actions'
import store from 'model/store'
import { IChannel, IMessage } from 'shared/types'

export const dbSendMessage = async (m: IMessage, entityId: string) => {
  console.log('dbSendMessage', m, entityId)
  const ref = doc(collection(db, 'chat'), m.id)
  try {
    await setDoc(ref, m)
  } catch (e) {
    console.error('cannot save message to db', e)
    throw e
  }
  const channels = store.getState().channels
  const ch = get(channels, m.channelId)
  const chRef = doc(collection(db, 'chatChannel'), m.channelId)
  if (ch) {
    const upd = {
      [`readBy.${m.userId}`]: +dayjs(),
      lastMessage: m
    }
    try {
      await updateDoc(chRef, upd)
    } catch (e) {
      console.error('cannot update channel', e)
      throw e
    }
  } else {
    const newCh: IChannel = {
      id: m.channelId,
      lessonId: m.lessonId,
      userId: m.userId,
      entityId,
      readBy: {
        [m.userId]: +dayjs()
      },
      unread: {},
      lastMessage: m
    }
    try {
      await setDoc(chRef, newCh)
    } catch (e) {
      console.error('cannot save channel', e)
      throw e
    }
  }
}

export const dbSubscribeToChat = (
  lessonId: string,
  callback: (messages: IMessage[]) => void
) => {
  try {
    const userId = auth.currentUser?.uid
    if (userId) {
      const q = query(
        collection(db, 'chat'),
        where('lessonId', '==', lessonId),
        where('userId', '==', userId),
        orderBy('createdAt')
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res: IMessage[] = []
          sn.forEach(doc => {
            const m = doc.data() as IMessage
            res.push(m)
          })
          callback(res)
        },
        err => {
          console.log(`dbSubscribeToChat error: ${err.message}`)
        }
      )
      return unsubscribe
    } else {
      return null
    }
  } catch (e) {
    console.error('dbSubscribeToChat error', e)
    return null
  }
}

export const dbFetchChatChannels = (userId: string) => {
  try {
    console.log('dbFetchChatChannels', userId)
    const q = query(
      collection(db, 'chatChannel'),
      where('userId', '==', userId)
    )
    const listener = onSnapshot(
      q,
      sn => {
        const res: Record<string, IChannel> = {}
        sn.forEach(doc => {
          const p = doc.data() as IChannel
          res[doc.id] = p
        })
        console.log('receive chant channels', res)
        store.dispatch(receiveChannels(res))
      },
      err => {
        console.log(`dbFetchChatChannels error: ${err.message}`)
      }
    )
    addListener('channels', listener)
  } catch (e) {
    console.error('dbFetchChatChannels error', e)
  }
}
