import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import get from 'lodash/get'
import find from 'lodash/find'
import indexOf from 'lodash/indexOf'
import findIndex from 'lodash/findIndex'
import last from 'lodash/last'
import has from 'lodash/has'
import first from 'lodash/first'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { useNavigate, useParams } from 'react-router-dom'
import LessonPage from 'shared/pages/LessonPage'
import { storageRef } from 'controllers/db'
import {
  dbFinishExaminationAttempt,
  dbStartExaminationAttempt,
  submitTestAnswers
} from 'controllers/tests'
import { useSelector } from 'model/hooks'
import { ILessonType, SectionType } from 'shared/types'
import { dbGetWordCards, dbSubscribeToCardStatuses } from 'controllers/cards'
import { Unsubscribe } from 'firebase/firestore'

const Lesson: FC = () => {
  const {
    lessons,
    entity,
    entityType,
    passedDays,
    examinationAttempts,
    testResults
  } = useContext(LessonsContext)
  const wordCards = useSelector(state => state.wordCards)
  const cardTags = useSelector(state => state.cardTags)
  const partitions = useSelector(state => state.partitions)
  const teachers = useSelector(state => state.teachers)
  const user = useSelector(state => state.user)
  const [cardStatuses, setCardStatuses] = useState<Record<string, boolean>>({})
  const { weekId, dayId, lessonId } = useParams() as {
    weekId: string
    dayId: string
    lessonId: string
  }
  const cardStatusesUnsubscribeRef = useRef<Unsubscribe | null>(null)

  const navigate = useNavigate()

  const lesson = useMemo(() => {
    return get(lessons, lessonId)
  }, [lessons, lessonId])

  useEffect(() => {
    if (lesson?.sections) {
      if (lesson.type === ILessonType.DICTIONARY) {
        const rawCardsIds = map(lesson.sections, s => {
          if (s.type === SectionType.WORD_CARD) {
            return s.cardId || null
          } else {
            return null
          }
        })
        const ids = compact(rawCardsIds)
        dbGetWordCards(ids)
      }
    }
  }, [lesson?.sections, lesson?.type])

  useEffect(() => {
    if (lesson?.id && lesson.type === ILessonType.DICTIONARY) {
      if (cardStatusesUnsubscribeRef.current) {
        cardStatusesUnsubscribeRef.current()
      }
      cardStatusesUnsubscribeRef.current = dbSubscribeToCardStatuses(
        lesson.id,
        setCardStatuses
      )
    }
  }, [lesson?.id, lesson?.type])

  const testResult = useMemo(() => {
    return get(keyBy(testResults, 'lessonId'), lessonId, null)
  }, [testResults, lessonId])

  const week = useMemo(() => {
    if (entity && entity.content) {
      return find(entity.content, w => w.id === weekId)
    }
  }, [entity, weekId])

  const examinationAttempt = useMemo(() => {
    return get(examinationAttempts, lessonId, null)
  }, [examinationAttempts, lessonId])

  const day = useMemo(() => {
    if (week && dayId) {
      return find(week.days, d => d.id === dayId)
    }
  }, [week, dayId])

  const prevUrl = useMemo(() => {
    if (entity && week && day) {
      const lessonNum = indexOf(day.lessons, lessonId)
      if (lessonNum > 0) {
        const prevLessonId = get(day.lessons, [lessonNum - 1])
        if (prevLessonId) {
          return `/profile/${entityType}/${entity.id}/${week.id}/${day.id}/${prevLessonId}`
        }
      } else if (lessonNum === 0) {
        const dayNum = findIndex(week.days, d => d.id === day.id)
        if (dayNum > 0) {
          const prevDay = get(week.days, [dayNum - 1])
          if (prevDay) {
            const prevLessonId = last(prevDay.lessons)
            if (prevLessonId) {
              return `/profile/${entityType}/${entity.id}/${week.id}/${prevDay.id}/${prevLessonId}`
            }
          }
        } else if (dayNum === 0) {
          const weekNum = findIndex(entity.content, w => w.id === week.id)
          if (weekNum > 0) {
            const prevWeek = get(entity.content, [weekNum - 1])
            if (prevWeek) {
              return `/profile/${entityType}/${entity.id}`
              // const prevDay = last(prevWeek?.days)
              // if (prevDay) {
              //   const prevLessonId = last(prevDay.lessons)
              //   if (prevLessonId) {
              //     return `/profile/${entityType}/${entity.id}/${prevWeek.id}/${prevDay.id}/${prevLessonId}`
              //   }
              // }
            }
          }
        }
      }
    }
  }, [lessonId, day, week, entity, entityType])

  const nextUrl = useMemo(() => {
    if (entity && entity.content && week && day) {
      const lessonNum = indexOf(day.lessons, lessonId)
      if (lessonNum < day.lessons.length - 1) {
        const nextLessonId = get(day.lessons, [lessonNum + 1])
        if (nextLessonId) {
          return `/profile/${entityType}/${entity.id}/${week.id}/${day.id}/${nextLessonId}`
        }
      } else if (lessonNum === day.lessons.length - 1) {
        const dayNum = findIndex(week.days, d => d.id === day.id)
        if (dayNum < week.days.length - 1) {
          const nextDay = get(week.days, [dayNum + 1])
          if (nextDay && has(passedDays, nextDay.id)) {
            const nextLessonId = first(nextDay.lessons)
            if (nextLessonId) {
              return `/profile/${entityType}/${entity.id}/${week.id}/${nextDay.id}/${nextLessonId}`
            }
          }
        } else if (dayNum === week.days.length - 1) {
          return `/profile/${entityType}/${entity.id}`
          // const weekNum = findIndex(entity.content, w => w.id === week.id)
          // if (weekNum < entity.content.length - 1) {
          //   const nextWeek = get(entity.content, [weekNum + 1])
          //   if (nextWeek && has(passedWeeks, nextWeek.id)) {
          //     const nextDay = first(nextWeek.days)
          //     if (nextDay && has(passedDays, nextDay.id)) {
          //       const nextLessonId = first(nextDay.lessons)
          //       if (nextLessonId) {
          //         return `/profile/${entityType}/${entity.id}/${nextWeek.id}/${nextDay.id}/${nextLessonId}`
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }, [lessonId, day, week, entity, entityType, passedDays])

  if (lesson && entity && week && day && examinationAttempts !== null && user) {
    return (
      <LessonPage
        key={lesson.id}
        lesson={lesson}
        storageRef={storageRef}
        entity={entity}
        entityType={entityType}
        toNext={nextUrl ? () => navigate(nextUrl) : undefined}
        toPrev={prevUrl ? () => navigate(prevUrl) : undefined}
        backUrl={`/profile/${entityType}/${entity.id}/${week.id}/${day.id}`}
        examinationAttempt={examinationAttempt}
        testResult={testResult}
        dbFinishExaminationAttempt={dbFinishExaminationAttempt}
        submitTestAnswers={submitTestAnswers}
        user={user}
        dbStartExaminationAttempt={dbStartExaminationAttempt}
        weekId={week.id}
        dayId={day.id}
        teachers={teachers || {}}
        partitions={partitions}
        wordCards={wordCards}
        cardTags={cardTags}
        toLearnDictionary={() => navigate('training')}
        cardStatuses={cardStatuses}
      />
    )
  } else {
    return null
  }
}

export default Lesson
