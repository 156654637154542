import {
  IConference,
  ICourse,
  ISeminar,
  IUserPayment,
  IWebinar,
  IUser,
  IPartition,
  ILevel,
  ITeacher,
  IChannel,
  IFree,
  ICoursesPack,
  IWordCardTag,
  IWordCard
} from 'shared/types'
import { createAction } from '@reduxjs/toolkit'

export const receiveCourses =
  createAction<Record<string, ICourse>>('RECEIVE_COURSES')
export const receiveCoursesPacks = createAction<Record<string, ICoursesPack>>(
  'RECEIVE_COURSES_PACKS'
)
export const receiveFrees = createAction<Record<string, IFree>>('RECEIVE_FREES')
export const receiveSeminars =
  createAction<Record<string, ISeminar>>('RECEIVE_SEMINARS')
export const receiveWebinars =
  createAction<Record<string, IWebinar>>('RECEIVE_WEBINARS')
export const receiveLibraryCourses = createAction<Record<string, ICourse>>(
  'RECEIVE_LIBRARY_COURSES'
)
export const receiveUserPayments = createAction<Record<string, IUserPayment>>(
  'RECEIVE_USER_PAYMENTS'
)
export const receiveUserCourses = createAction<Record<string, ICourse>>(
  'RECEIVE_USER_COURSES'
)
export const receiveUserFrees =
  createAction<Record<string, IFree>>('RECEIVE_USER_FREES')
export const receiveUserWebinars = createAction<Record<string, IWebinar>>(
  'RECEIVE_USER_WEBINARS'
)
export const receiveUserSeminars = createAction<Record<string, ISeminar>>(
  'RECEIVE_USER_SEMINARS'
)
export const receiveUserConferences = createAction<Record<string, IConference>>(
  'RECEIVE_USER_CONFERENCES'
)
export const receiveUserLibraryCourses = createAction<Record<string, ICourse>>(
  'RECEIVE_USER_LIBRARY_COURSES'
)

export const receiveUser = createAction<IUser | null>('RECEIVE_USER')

export const logout = createAction('LOGOUT')
export const receiveAuthData = createAction<{
  uid: string
  email: string | null
} | null>('RECEIVE_AUTH_DATA')

export const receivePartitions =
  createAction<Record<string, IPartition>>('RECEIVE_PARTITIONS')

export const receiveLevels =
  createAction<Record<string, ILevel>>('RECEIVE_LEVELS')

export const receiveTeachers =
  createAction<Record<string, ITeacher>>('RECEIVE_TEACHERS')

export const receiveAdmins = createAction<string[]>('RECEIVE_ADMINS')

export const receiveChannels =
  createAction<Record<string, IChannel>>('RECEIVE_CHANNELS')

export const receiveCardTags =
  createAction<Record<string, IWordCardTag>>('RECEIVE_CARD_TAGS')

export const receiveWordCards =
  createAction<Record<string, IWordCard>>('RECEIVE_WORD_CARDS')

export const appendWordCard = createAction<IWordCard>('APPEND_WORD_CARD')
