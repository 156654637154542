import { RootState } from 'model/store'
import { createSelector } from '@reduxjs/toolkit'
import reduce from 'lodash/reduce'
import isNil from 'lodash/isNil'
import size from 'lodash/size'

export const getCourses = (state: RootState) => state.courses
export const getCoursesPacks = (state: RootState) => state.coursesPacks
export const getWebinars = (state: RootState) => state.webinars
export const getLibrary = (state: RootState) => state.library
export const getSeminars = (state: RootState) => state.seminars
export const getFrees = (state: RootState) => state.frees
export const getUserPayments = (state: RootState) => state.userPayments
export const getUserEntities = (state: RootState) => state.userEntities

export const getAppLoaded = createSelector(
  [getCourses, getCoursesPacks, getWebinars, getLibrary, getFrees],
  (courses, coursesPacks, webinars, library, frees) => {
    const lst = [courses, coursesPacks, webinars, library, frees]
    const doneAmount = reduce(
      lst,
      (res, elt) => {
        return isNil(elt) ? res : res + 1
      },
      0
    )
    // console.table([
    //   ['courses', !_.isNil(courses)],
    // ])
    return doneAmount === size(lst) ? null : (doneAmount / size(lst)) * 100
  }
)
