import { useRef, useState, FC } from 'react'
import {
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Alert,
  HStack,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
  Divider,
  AbsoluteCenter
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import isNil from 'lodash/isNil'
import { dbSignIn } from 'controllers/auth'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import { User } from 'firebase/auth'
import AuthTitle from 'components/auth/AuthTitle'
import GeneralError from 'components/auth/GeneralError'
import SimpleAgreement from 'components/auth/SimpleAgreement'

type Props = {
  toSignUp: () => void
  toResetPassword: () => void
  onAuth: (authData: User) => void
  title?: string
  subTitle?: string
  description?: string
}

const SigninForm: FC<Props> = ({
  toSignUp,
  toResetPassword,
  onAuth,
  title = 'Вход',
  subTitle,
  description
}) => {
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    email: undefined,
    password: undefined,
    general: undefined
  })
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [showPasswordResetedAlert, setShowPasswordResetedAlert] =
    useState(false)

  const onChange = (k: string) => (v: string) => {
    setData({
      ...data,
      [k]: v
    })
  }

  const getErrors = () => {
    const res: Record<string, string> = {}
    if (isEmpty(data.email)) {
      res.email = 'Введите email'
    } else if (!isEmail(data.email)) {
      res.email = 'Неверный формат email'
    }
    if (isEmpty(data.password)) {
      res.password = 'Введите пароль'
    }
    return res
  }

  const resetError = (k: string) => {
    setErrors(errors => ({ ...errors, [k]: undefined }))
  }

  const submit = async (): Promise<void> => {
    const errs = getErrors()

    if (isEmpty(errs)) {
      setLoading(true)
      const { error, authUser } = await dbSignIn(data.email, data.password)
      if (!isNil(error)) {
        switch (error) {
          case 'auth/wrong-password':
            errs.password =
              'The email and password you entered did not match our records. Please double-check and try again.'
            break
          case 'auth/user-not-found':
            errs.email =
              'An account with this email does not exist. To create a new account please sign up.'
            break
          default:
            errs.general = authErrorToString(error)
        }
      } else if (authUser) {
        onAuth(authUser)
      }
    }
    setErrors(errs)
    setLoading(false)
  }

  const renderPasswordResetedAlert = () => {
    if (showPasswordResetedAlert) {
      return (
        <Alert status='info' colorScheme='gray' rounded={'md'} mb={8}>
          <HStack color='blue.600' spacing={4}>
            <VStack align='flex-start' color='gray.700' spacing={1}>
              <AlertTitle>Ваш пароль был сброшен</AlertTitle>
              <AlertDescription fontSize={'sm'} lineHeight={1.4}>
                На email {data.email} отправлено письмо, следуйте инструкциям в
                этом письме чтобы сбросить пароль.
              </AlertDescription>
            </VStack>
            <Box>
              <CloseButton
                color='gray.600'
                onClick={() => setShowPasswordResetedAlert(false)}
              />
            </Box>
          </HStack>
        </Alert>
      )
    }
  }

  const emailInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.email)}>
      <FormLabel mb={1}>
        <Text textStyle={'small'} as='span'>
          Email
        </Text>
      </FormLabel>
      <Input
        textStyle={'small'}
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='example@domain.com'
        value={data.email}
        onFocus={() => resetError('email')}
        ref={emailInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            passwordInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('email')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.email}</FormErrorMessage>
    </FormControl>
  )

  const phoneInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.password)}>
      <FormLabel mb={1}>
        <Text textStyle={'small'} as='span'>
          Пароль
        </Text>
      </FormLabel>
      <Input
        textStyle={'small'}
        type='password'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='******'
        value={data.password}
        onFocus={() => resetError('password')}
        ref={passwordInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submit()
          }
        }}
        onChange={e => onChange('password')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.password}</FormErrorMessage>
    </FormControl>
  )

  const renderGeneralError = () => {
    if (errors.general) {
      return (
        <GeneralError
          msg={errors.general}
          onReset={() => resetError('general')}
        />
      )
    }
  }

  const forgotPassword = (
    <VStack w='full' justify={'center'}>
      <VStack as='button' onClick={toResetPassword}>
        <Text color='darkGray' textStyle='tag'>
          Забыли пароль?
        </Text>
      </VStack>
    </VStack>
  )

  return (
    <VStack w='full' flexShrink={0} align='flex-start' spacing={6}>
      <AuthTitle title={title} subTitle={subTitle} />
      {description && (
        <Text textStyle='small' color='darkGray'>
          {description}
        </Text>
      )}
      <VStack spacing={4} w='full'>
        {renderGeneralError()}
        {renderPasswordResetedAlert()}
        {emailInput}
        {phoneInput}
        {forgotPassword}
        <SimpleAgreement />
        <Button
          w='full'
          variant='primary'
          onClick={submit}
          isLoading={loading}
          size='md'
        >
          Войти в аккаунт
        </Button>
        <Box position='relative' w='full' py='2'>
          <Divider />
          <AbsoluteCenter bg='white' px='4'>
            <Text color='darkGray' textStyle='small'>
              или
            </Text>
          </AbsoluteCenter>
        </Box>
        <Button
          variant={'outline'}
          size='md'
          colorScheme='black'
          onClick={toSignUp}
          w='full'
          rounded='full'
        >
          Зарегистрироваться
        </Button>
      </VStack>
    </VStack>
  )
}

export default SigninForm
