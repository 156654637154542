import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { IItemInfo } from 'shared/types'
import { useNavigate } from 'react-router-dom'
import PaymentModalContent from 'modals/paymentModal/PaymentModalContent'

export interface IPaymentModal {
  open: (itemInfo: IItemInfo) => void
}

type Props = {
  onSuccess?: () => void
}

const PaymentModal: ForwardRefRenderFunction<IPaymentModal, Props> = (
  { onSuccess },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [itemInfo, setItemInfo] = useState<IItemInfo>()
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    open: (iInfo: IItemInfo) => {
      setItemInfo(iInfo)
      onOpen()
    }
  }))

  const navigateOnSucess = () => {
    if (itemInfo?.successPath) {
      navigate(itemInfo.successPath)
    } else {
      navigate('/profile')
    }
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg='blackAlpha.600'
          backdropFilter='auto'
          // backdropInvert='80%'
          backdropBlur='2px'
        />
        <ModalContent rounded={'8px'} maxW='540px' w='full'>
          <ModalBody
            p={0}
            borderWidth={0}
            boxShadow={'none'}
            rounded={0}
            maxW='540px'
            w='full'
          >
            {itemInfo && (
              <PaymentModalContent
                itemInfo={itemInfo}
                navigateOnSucess={navigateOnSucess}
                onClose={onClose}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default forwardRef(PaymentModal)
