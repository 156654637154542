import {
  Box,
  HStack,
  Text,
  VStack,
  Show,
  Stack,
  Divider
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { TEST_MAX_WIDTH } from 'shared/constants/main'
import { IWordCard, IWordCardTag } from 'shared/types'
import { ReactComponent as IconEye } from 'shared/assets/bsEye.svg'
import { ReactComponent as IconEyeSlash } from 'shared/assets/bsEyeSlash.svg'
import { ReactComponent as IconChevronLeft } from 'shared/assets/bsChevronLeft.svg'
import { ReactComponent as IconChevronRight } from 'shared/assets/bsChevronRight.svg'
import { motion } from 'framer-motion'
import ReactCardFlip from 'react-card-flip'

import get from 'lodash/get'
import map from 'lodash/map'
import AudioPlayerIconMode from 'shared/components/AudioPlayerIconMode'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  c: IWordCard
  cardTags: Record<string, IWordCardTag>
  num: number
  amount: number
  toNext: () => void
  toPrev: () => void
  status: boolean | null
}

const TrainingCard: FC<Props> = ({
  c,
  cardTags,
  num,
  amount,
  toNext,
  toPrev,
  status
}) => {
  const [isBack, setIsBack] = useState(false)

  const renderTags = () => {
    return (
      <HStack spacing={2} position='absolute' top={4} left={4}>
        {map(c.tags, t => {
          const title = get(cardTags, [t, 'title'])
          if (title) {
            return (
              <Box
                rounded='full'
                borderColor='black'
                borderWidth={1}
                key={t}
                py={1}
                px={5}
              >
                <Text textStyle='tag'>{title}</Text>
              </Box>
            )
          }
        })}
      </HStack>
    )
  }

  const renderProgress = () => {
    return (
      <Text textStyle={'tag'} color='black.400' ml={{ base: '-50%', lg: 0 }}>
        {num} / {amount}
      </Text>
    )
  }

  const renderWord = (isBack: boolean) => {
    return (
      <HStack
        textStyle={'h3'}
        color='sun.950'
        fontWeight={'bold'}
        w='full'
        justify={!isBack ? 'center' : { lg: 'center', md: 'start' }}
      >
        <Text>{isBack ? c.translate.join(' / ') : c.word}</Text>
      </HStack>
    )
  }

  const renderTranscript = (isBack: boolean) => {
    if (c.transcription && !isBack) {
      return (
        <Text key='transcript' color='black.950' textStyle='body' pt={1}>
          [{c.transcription}]
        </Text>
      )
    }
  }

  const renderAudio = () => {
    if (c.audio && c.audio.url) {
      return <AudioPlayerIconMode url={c.audio.url} />
    }
  }

  const arrowLeft = (
    <VStack
      h='full'
      w={12}
      align={'center'}
      justify={'center'}
      as='button'
      borderLeftRadius={'xs'}
      color='black.400'
      _hover={{
        bg: 'linear-gradient(-90deg, rgba(255,255,255, 0), rgba(239,237,229,1));'
      }}
      onClick={toPrev}
    >
      <IconChevronLeft width={16} height={16} />
    </VStack>
  )

  const arrowRight = (
    <VStack
      h='full'
      w={12}
      align={'center'}
      justify={'center'}
      as='button'
      borderRightRadius={'xs'}
      color='black.400'
      _hover={{
        bg: 'linear-gradient(90deg, rgba(255,255,255, 0), rgba(239,237,229,1));'
      }}
      onClick={toNext}
    >
      <IconChevronRight width={16} height={16} />
    </VStack>
  )

  const renderDefinition = (isBack: boolean) => {
    if (c.definition && isBack) {
      return (
        <HStack
          key='definition'
          color='black.500'
          w='full'
          justify={!isBack ? 'center' : { lg: 'center', md: 'start' }}
        >
          <Text color='black.500' textStyle={'body'}>
            <i>{c.definition}</i>
          </Text>
        </HStack>
      )
    }
  }

  const onCardClick = () => {
    setIsBack(back => !back)
  }

  const renderParagraphs = () => {
    if (c.paragraphs) {
      return (
        <VStack w='full' align='flex-start' py={4} spacing={4}>
          <Divider />
          <VStack w='full' spacing={{ base: 4, lg: 2 }}>
            {map(c.paragraphs, (p, i) => {
              return (
                <Stack
                  key={i}
                  direction={{ base: 'column', lg: 'row' }}
                  w='full'
                  spacing={{ base: 0, lg: 6 }}
                >
                  <Box flex={1}>
                    <SectionTextContent
                      r={{ text: p.left, textStyle: 'small' }}
                    />
                  </Box>
                  <Box flex={1}>
                    <SectionTextContent
                      r={{ text: p.right, textStyle: 'small' }}
                    />
                  </Box>
                </Stack>
              )
            })}
          </VStack>
        </VStack>
      )
    }
  }

  const renderContent = (isBack: boolean) => {
    return (
      <VStack
        key={'card' + isBack}
        w='full'
        maxW={TEST_MAX_WIDTH}
        bg='white'
        boxShadow={'large'}
        rounded={'xs'}
        h={!isBack ? { base: '60vh', lg: '360px' } : 'full'}
        minH={isBack ? { base: '60vh', lg: '360px' } : undefined}
        position={'relative'}
        borderWidth={2}
        borderColor={
          status === null ? 'transparent' : status ? 'green.800' : 'red.700'
        }
      >
        {renderTags()}
        <Show above='lg'>
          <Box position={'absolute'} top={4} right={4}>
            {renderProgress()}
          </Box>
        </Show>
        <Show below='lg'>
          <Box position={'absolute'} bottom={4} left={'50%'}>
            {renderProgress()}
          </Box>
        </Show>

        <HStack w='full' h='full' rounded={'xs'}>
          {arrowLeft}
          <VStack
            spacing={1}
            h='full'
            w='full'
            onClick={() => onCardClick()}
            justify={{ base: 'center', lg: 'center' }}
          >
            <Box _hover={{ cursor: 'pointer' }} pt={isBack ? 1 : 0}>
              {isBack ? <IconEyeSlash /> : <IconEye />}
            </Box>
            {renderWord(isBack)}
            {renderDefinition(isBack)}
            {renderTranscript(isBack)}
            {!isBack && renderAudio()}
            {isBack && renderParagraphs()}
          </VStack>
          {arrowRight}
        </HStack>
      </VStack>
    )
  }

  return (
    <ReactCardFlip
      isFlipped={isBack}
      flipDirection='vertical'
      containerStyle={{ width: '100%', maxWidth: TEST_MAX_WIDTH }}
    >
      {renderContent(false)}
      {renderContent(true)}
    </ReactCardFlip>
  )
}

export default TrainingCard
