import {
  VStack,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import AuthTitle from 'components/auth/AuthTitle'
import { FC, useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import { dbResetPassword } from 'controllers/auth'
import GeneralError from 'components/auth/GeneralError'
import AuthBackButton from 'components/auth/AuthBackButton'

type Props = {
  onBack: () => void
}

const ResetPasswordForm: FC<Props> = ({ onBack }) => {
  const [sent, setSent] = useState(false)
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    email: undefined,
    password: undefined,
    general: undefined
  })
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  const getErrors = () => {
    const res: Record<string, string> = {}
    if (isEmpty(data.email)) {
      res.email = 'Введите email'
    } else if (!isEmail(data.email)) {
      res.email = 'Неверный формат email'
    }
    if (isEmpty(data.password)) {
      res.password = 'Введите пароль'
    }
    return res
  }

  const submit = async () => {
    const errs = getErrors()

    if (!errs.email) {
      setLoading(true)
      const isSent = await dbResetPassword(data.email)
      if (isSent) {
        setSent(true)
      } else {
        errs.general = 'Мы не смогли отправить письмо на указанный вами email.'
      }
    }
    setErrors(errs)
    setLoading(false)
  }

  const resetError = (k: string) => {
    setErrors(errors => ({ ...errors, [k]: undefined }))
  }

  const onChange = (k: string) => (v: string) => {
    setData({
      ...data,
      [k]: v
    })
  }

  const renderGeneralError = () => {
    if (errors.general) {
      return (
        <GeneralError
          msg={errors.general}
          onReset={() => resetError('general')}
        />
      )
    }
  }

  const emailInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.email)}>
      <FormLabel mb={1}>
        <Text textStyle={'small'} as='span'>
          Email
        </Text>
      </FormLabel>
      <Input
        textStyle={'small'}
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='example@domain.com'
        value={data.email}
        onFocus={() => resetError('email')}
        // onBlur={() => {
        //   const errs = getErrors()
        //   setErrors(errors => ({ ...errors, email: errs.email }))
        // }}
        ref={emailInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            passwordInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('email')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.email}</FormErrorMessage>
    </FormControl>
  )

  if (sent) {
    return (
      <VStack w='full' flexShrink={0} align='flex-start' spacing={6}>
        <AuthTitle title='Сброс пароля' />
        <Alert status='info' rounded='md'>
          <AlertIcon />
          Email для сброса пароля отправлен
        </Alert>
        <HStack justify={'center'} w='full'>
          <HStack as='button' onClick={onBack}>
            <Text textStyle={'tag'} color='darkGray'>
              Вернуться на страницу входа
            </Text>
          </HStack>
        </HStack>
      </VStack>
    )
  } else {
    return (
      <VStack w='full' flexShrink={0} align='flex-start' spacing={6}>
        <VStack spacing={1} w='full'>
          <AuthBackButton onClick={onBack} />
          <AuthTitle title='Сброс пароля' />
        </VStack>
        {renderGeneralError()}
        <Text textStyle='small' color='darkGray'>
          Введите email, к которому привязан Аккаунт. Вам на почту придёт ссылка
          для создания нового пароля.
        </Text>
        {emailInput}
        <Button
          variant={'primary'}
          w='full'
          onClick={submit}
          isLoading={loading}
        >
          Сбросить пароль
        </Button>
      </VStack>
    )
  }
}

export default ResetPasswordForm
