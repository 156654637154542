import { FC, useMemo } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import NavWrapper from 'navigation/NavWrapper'
import Home from 'pages/Home'
import Contacts from 'pages/Contacts'
import Courses from 'pages/Courses'
import Course from 'pages/Course'
import Webinars from 'pages/Webinars'
import NotFound from 'shared/components/NotFound'
import Webinar from 'pages/Webinar'
import Library from 'pages/Library'
import LibraryCourse from 'pages/LibraryCourse'
import Seminars from 'pages/Seminars'
import SignIn from 'pages/SignIn'
import Profile from 'pages/Profile'
import Settings from 'pages/Settings'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import Loading from 'components/Loading'
import Privacy from 'pages/Privacy'
import Agreement from 'pages/Agreement'
import LessonsDataProvider from 'contexts/LessonsDataProvider'
import UserLesson from 'pages/UserLesson'
import UserEntity from 'pages/UserEntity'
import Seminar from 'pages/Seminar'
import UserEntitites from 'pages/UserEntities'
import UserEntityWeek from 'pages/userEntity/UserEntityWeek'
import UserEntityDay from 'pages/userEntity/UserEntityDay'
import Lesson from 'pages/user/Lesson'
import Contract from 'pages/Contract'
import Info from 'pages/Info'
import Frees from 'pages/Frees'
import Free from 'pages/Free'
import CoursesPack from 'pages/CoursesPack'
import PaymentLink from 'pages/PaymentLink'
import DictionaryTraining from 'pages/user/DictionaryTraining'

const AppRouter: FC = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const privateRoutes = useMemo(() => {
    if (authData === null || appLoaded !== null) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/profile/settings' element={<Settings />} />
          <Route
            path='/profile/:entityType/:entityId'
            element={<LessonsDataProvider />}
          >
            <Route path='l/:lessonId' element={<UserLesson />} />
            <Route
              path=':weekId/:dayId/:lessonId/training'
              element={<DictionaryTraining />}
            />
            <Route path=':weekId/:dayId/:lessonId' element={<Lesson />} />
            <Route path=':weekId/:dayId' element={<UserEntityDay />} />
            <Route path=':weekId' element={<UserEntityWeek />} />

            <Route path='' element={<UserEntity />} />
          </Route>
          <Route path='/profile/:entityType' element={<UserEntitites />} />
        </>
      )
    }
  }, [authData, appLoaded])

  const publicRoutes = useMemo(() => {
    if (appLoaded !== null) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/profile' element={<Profile />} />
          <Route path='/login' element={<SignIn />} />
          <Route path='/seminars/:seminarId' element={<Seminar />} />
          <Route path='/seminars' element={<Seminars />} />
          <Route path='/library/:courseId' element={<LibraryCourse />} />
          <Route path='/library' element={<Library />} />
          <Route path='/webinars/:webinarId' element={<Webinar />} />
          <Route path='/webinars' element={<Webinars />} />
          <Route path='/tariffs/:packId' element={<CoursesPack />} />
          <Route path='/courses/:courseId' element={<Course />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/free' element={<Frees />} />
          <Route path='/free/:courseId' element={<Free />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/pl/:linkId' element={<PaymentLink />} />

          <Route path='/' element={<Home />} />
          <Route
            path='*'
            element={<NotFound message='Такая страница не существует' />}
          />
        </>
      )
    }
  }, [appLoaded])

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          <Route path='/privacy' element={<Privacy />}></Route>
          <Route path='/agreement' element={<Agreement />}></Route>
          <Route path='/contract' element={<Contract />}></Route>
          <Route path='/info' element={<Info />}></Route>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
