import { Grid, GridItem, VStack, Image, Text, Stack } from '@chakra-ui/react'
import { FC } from 'react'

export type ICard = {
  imageUrl: string
  title: string
  desc: string[]
  onClick: () => void
}

type Props = {
  amountPerRow: number
  cards: ICard[]

  aspectRatio?: number
}

const Cards: FC<Props> = ({ amountPerRow, cards, aspectRatio }) => {
  const renderCard = (c: ICard, i: number) => {
    return (
      <GridItem key={i} onClick={c.onClick} _hover={{ cursor: 'pointer' }}>
        <VStack align='flex-start' spacing={0}>
          <Image
            aspectRatio={aspectRatio}
            boxSize={'full'}
            objectFit={'cover'}
            src={c.imageUrl}
            rounded={'xs'}
          />
          <Text textStyle={'small'} pt={3} noOfLines={1}>
            {c.title}
          </Text>
          <Stack
            textStyle={'small'}
            color='darkGray'
            pt={1}
            spacing={0}
            direction={{ base: 'column', lg: 'row' }}
          >
            {c.desc.map(d => (
              <Text key={d} as='span' pr={4}>
                {d}
              </Text>
            ))}
          </Stack>
        </VStack>
      </GridItem>
    )
  }

  return (
    <Grid
      templateColumns={`repeat(${amountPerRow}, 1fr)`}
      rowGap={{ base: 2, lg: 6 }}
      columnGap={{ base: 2, lg: 6 }}
      w='full'
    >
      {cards.map(renderCard)}
    </Grid>
  )
}

export default Cards
