import React from 'react'
import { IItemInfo } from 'shared/types'

export interface IMainContext {
  openPayment: (itemInfo: IItemInfo) => void
}

const Ctx = React.createContext<IMainContext>({
  openPayment: () => null
})

export default Ctx
