import { Box, HStack, Text, VStack, Stack } from '@chakra-ui/react'
import EntityBreadcrumb from 'pages/userEntity/EntityBreadcrumb'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconChevronLeft } from 'shared/assets/bsChevronLeft.svg'

type Props = {
  title: string
  backUrl: string
  children?: ReactNode
}

const UserEntityHeader: FC<Props> = ({ title, backUrl, children }) => {
  const navigate = useNavigate()
  return (
    <VStack
      w='full'
      pt={{ base: 14, lg: '72px' }}
      spacing={{ base: 6, lg: 6 }}
      pb={{ base: 4, lg: 8 }}
    >
      <EntityBreadcrumb />
      <Stack
        w='full'
        direction={{ base: 'column', lg: 'row' }}
        align={{ base: 'flex-end', lg: 'flex-end' }}
        justify={{ base: 'flex-start', lg: 'space-between' }}
      >
        <HStack w='full' spacing={2}>
          <Box as='button' onClick={() => navigate(backUrl)}>
            <IconChevronLeft color='black.500' width={24} />
          </Box>
          <Text textStyle='h2' fontWeight={700}>
            {title}
          </Text>
        </HStack>
        {children}
      </Stack>
    </VStack>
  )
}

export default UserEntityHeader
