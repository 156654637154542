import { createReducer } from '@reduxjs/toolkit'
import { IUserPayment } from 'shared/types'
import { receiveUserPayments, logout } from 'model/actions'

const initialState = null as Record<string, IUserPayment> | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveUserPayments, (state, action) => action.payload)
    .addCase(logout, () => initialState)
})

export default reducer
