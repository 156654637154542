import { FormLabel, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const StyledFormLabel: FC<Props> = ({ children }) => {
  return (
    <FormLabel textStyle={'body'}>
      <Text textStyle={'small'} as='span'>
        {children}
      </Text>
    </FormLabel>
  )
}

export default StyledFormLabel
