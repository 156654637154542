import BlackPageHeader from 'components/BlackPageHeader'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { useSelector } from 'model/hooks'
import SettingsRow from 'pages/settings/SettingsRow'
import { useRef } from 'react'
import ChangePasswordModal, {
  IChangePasswordModal
} from 'modals/ChangePasswordModal'
import UserParamsModal, { IUserParamsModal } from 'modals/UserParamsModal'

const SignIn = () => {
  const authData = useSelector(state => state.authData)
  const user = useSelector(state => state.user)
  const changePasswordModalRef = useRef<IChangePasswordModal>(null)
  const userParamsModalRef = useRef<IUserParamsModal>(null)

  const onChangePasswordClick = () => {
    console.log('onChangePasswordClick')
    changePasswordModalRef.current?.open()
  }

  const onEditUserClick = () => {
    console.log('onEditUserClick')
    userParamsModalRef.current?.open()
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg={'white'}
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        <BlackPageHeader
          backUrl='/profile'
          title={'Настройки'}
          hideDivider
          titleProps={{ textStyle: 'h2' }}
        />
      </ContentContainer>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        minH='80vh'
      >
        <SettingsRow title='Почта' value={authData?.email || ''} />
        <SettingsRow
          title='Пароль'
          value={'••••••••'}
          onClick={onChangePasswordClick}
        />
        <SettingsRow
          title='Имя'
          value={user?.name || ''}
          onClick={onEditUserClick}
        />
        <SettingsRow
          title='Телефон'
          value={user?.phone || ''}
          onClick={onEditUserClick}
        />
        <SettingsRow
          title='Telegram'
          value={user?.telegram || ''}
          onClick={onEditUserClick}
        />
      </ContentContainer>
      <ChangePasswordModal ref={changePasswordModalRef} />
      <UserParamsModal ref={userParamsModalRef} />
    </PageContentWrapper>
  )
}

export default SignIn
