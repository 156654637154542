import { useSelector } from 'model/hooks'
import Loading from 'components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import NotFound from 'shared/components/NotFound'
import { IFree } from 'shared/types'
import { storageRef } from 'controllers/db'
import AuthModal, { IAuthModal } from 'modals/AuthModal'
import { useRef } from 'react'
import FreePage from 'shared/pages/FreePage'

const Free = () => {
  const { courseId } = useParams() as { courseId: string }
  const authData = useSelector(state => state.authData)
  const navigate = useNavigate()
  const authModalRef = useRef<IAuthModal>(null)
  const free: IFree | undefined = useSelector(state =>
    state.frees && courseId ? state.frees[courseId] : undefined
  )
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)

  const onApplyClick = () => {
    if (authData) {
      onAuth()
    } else {
      authModalRef.current?.open()
    }
  }

  const onAuth = () => {
    navigate(`/profile/free/${courseId}`)
  }

  if (free === null) {
    return <Loading />
  } else if (!free) {
    return <NotFound message='Курс не существует' />
  } else {
    return (
      <>
        <FreePage
          course={free}
          onApplyClick={onApplyClick}
          storageRef={storageRef}
          teachers={teachers || {}}
          partitions={partitions}
        />
        <AuthModal ref={authModalRef} onAuth={onAuth} />
      </>
    )
  }
}

export default Free
