import { onSnapshot, doc } from 'firebase/firestore'
import { db } from 'controllers/db'
import { IEntity, IEntityType } from 'shared/types'

export const dbSubscribeToEntity = (
  entityId: string,
  entityType: IEntityType,
  callback: (entity: IEntity | null) => void
) => {
  try {
    const unsubscribe = onSnapshot(
      doc(db, entityType, entityId),
      entitySN => {
        if (entitySN.exists()) {
          const entity = entitySN.data() as IEntity
          callback(entity)
        } else {
          callback(null)
        }
      },
      err => {
        console.log('dbSubscribeToEntity error', err)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToEntity error', e)
    return null
  }
}
