// import { useState } from 'react'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { Flex } from '@chakra-ui/react'
// import SigninForm from 'components/auth/SigninForm'
// import SignupForm from 'components/auth/SignupForm'
import { User } from 'firebase/auth'
import ContentContainer from 'shared/components/ContentContainer'
import BlackPageHeader from 'components/BlackPageHeader'
import { useNavigate } from 'react-router-dom'
import Auth from 'components/Auth'

const SignIn = () => {
  const navigate = useNavigate()
  // const [isSignin, setIsSignin] = useState(true)

  const onAuth = (authData: User) => {
    navigate('/profile')
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        <BlackPageHeader
          title='Личный кабинет'
          hideDivider
          titleProps={{ textStyle: 'h1' }}
        />
      </ContentContainer>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        pb={{ base: 20, lg: 8 }}
        full
        minH='80vh'
      >
        <Flex pt={{ base: 12, lg: 20 }} w='full' justify='center'>
          <Auth onAuth={onAuth} />
          {/* <Flex
            maxW='596px'
            w='full'
            boxShadow={{
              base: 'none',
              lg: '0px 3px 10px 0px rgba(0, 0, 0, 0.20)'
            }}
            borderRadius={{ lg: '16px' }}
            bgColor={'white'}
          >
            {isSignin ? (
              <SigninForm toSignUp={() => setIsSignin(false)} onAuth={onAuth} />
            ) : (
              <SignupForm toSignin={() => setIsSignin(true)} onAuth={onAuth} />
            )}

          </Flex> */}
        </Flex>
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default SignIn
