import { auth, db, functions } from 'controllers/db'
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import {
  ISubmitAnswerResponse,
  ISubmitAnswerRequest,
  ITestResult,
  IExaminationAttempt
} from 'shared/types'

export const submitTestAnswers = async (req: ISubmitAnswerRequest) => {
  try {
    console.log('%cSubmitTestAnswer', 'color: yellow', req)
    const f = httpsCallable(functions, 'submitTestAnswers')
    const res = await f(req)
    console.log('submitTestAnswer function res:', res)
    return res.data as ISubmitAnswerResponse
  } catch (e) {
    console.error('submitTestAnswer error', e)
    return null
  }
}

export const dbSubscribeToTestResults = (
  entityId: string,
  callback: (testResults: ITestResult[]) => void
) => {
  const currentUser = auth.currentUser
  if (!currentUser) {
    return null
  }
  const userId = currentUser.uid
  try {
    const q = query(
      collection(db, 'testResults'),
      where('userId', '==', userId),
      where('entityId', '==', entityId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: ITestResult[] = []
        sn.forEach(doc => {
          const p = doc.data() as ITestResult
          res.push(p)
        })
        callback(res)
      },
      err => {
        console.log(`dbSubscribeToTestResults error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToTestResults error', e)
    return null
  }
}

export const dbStartExaminationAttempt = async (ea: IExaminationAttempt) => {
  console.log('dbStartExaminationAttempt', ea)
  const ref = doc(db, `examinationAttempts/${ea.id}`)
  await setDoc(ref, ea)
}

export const dbFinishExaminationAttempt = async (attemptId: string) => {
  console.log('dbFinishExaminationAttempt', attemptId)
  const ref = doc(db, `examinationAttempts/${attemptId}`)
  await updateDoc(ref, { finished: true })
}

export const dbSubscribeToExaminationAttempts = (
  entityId: string,
  userId: string,
  callback: (examinationAttempts: Record<string, IExaminationAttempt>) => void
) => {
  try {
    const q = query(
      collection(db, 'examinationAttempts'),
      where('entityId', '==', entityId),
      where('userId', '==', userId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IExaminationAttempt> = {}
        sn.forEach(doc => {
          const p = doc.data() as IExaminationAttempt
          res[p.lessonId] = p
        })
        callback(res)
      },
      err => {
        console.log(`dbSubscribeToLessons error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToLessons error', e)
    return null
  }
}
