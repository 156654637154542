import {
  VStack,
  Text,
  Divider,
  Stack,
  Button,
  Show,
  ButtonProps
} from '@chakra-ui/react'
// import Button from 'shared/components/PrimaryButton'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  buttons?: ReactNode
  buttonTitle: string
  buttonProps?: ButtonProps
  path: string
  desc: string
  desc2?: string
  secondaryButtonTitle?: string
  secondaryPath?: string
}

const ServiceSection: FC<Props> = ({
  title,
  buttons,
  buttonTitle,
  buttonProps,
  path,
  desc,
  desc2,
  secondaryButtonTitle,
  secondaryPath
}) => {
  const navigate = useNavigate()

  const renderButtons = () => {
    return (
      <VStack
        flex='1'
        align='flex-start'
        spacing={{ base: 2, lg: '6' }}
        py={{ base: 4, lg: 0 }}
        w='full'
      >
        <VStack w='full' align='flex-start' spacing={2}>
          {buttons}
          <Button
            variant={'primary'}
            onClick={() => navigate(path)}
            w={{ base: 'full', lg: 'auto' }}
            size={{ base: 'sm', lg: 'md' }}
            {...buttonProps}
          >
            {buttonTitle}
          </Button>
        </VStack>
        {secondaryButtonTitle && (
          <Button
            // bg='black'
            colorScheme='black'
            variant='outline'
            // color='white'
            rounded={'full'}
            fontSize={'14px'}
            fontWeight={'normal'}
            px='5'
            size={{ base: 'sm', lg: 'md' }}
            h={{ base: '35px', lg: '42px' }}
            w={{ base: 'full', lg: 'auto' }}
            // _hover={{ bg: 'blackAlpha.800', textDecor: 'underline' }}
            onClick={() => (secondaryPath ? navigate(secondaryPath) : null)}
          >
            {secondaryButtonTitle}
          </Button>
        )}
      </VStack>
    )
  }
  return (
    <VStack w='full' align={'flex-start'} spacing={0}>
      <Text textStyle={'h2'} fontWeight={700}>
        {title}
      </Text>
      <Divider mt={2} mb={{ base: 2, lg: 0 }} />
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        w='full'
        pt={{ base: 2, lg: 6 }}
        // pb={{ base: 8, lg: '60px' }}
        spacing={{ base: 4, lg: 4 }}
      >
        <Show above='lg'>{renderButtons()}</Show>
        <VStack flex={1} spacing={2} align={'flex-start'}>
          <Text textStyle={'h3'}>{desc}</Text>
          <Text textStyle={'body'} color='black.500'>
            {desc2}
          </Text>
          <Show below='lg'>{renderButtons()}</Show>
        </VStack>
      </Stack>
    </VStack>
  )
}

export default ServiceSection
