import PageContentWrapper from 'shared/components/PageContentWrapper'
import { VStack, Text, HStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import { Link } from 'react-router-dom'
import BlackPageHeader from 'components/BlackPageHeader'

const Contract = () => {
  const content = (
    <VStack
      w='full'
      align={'start'}
      color='black'
      textStyle={'body'}
      spacing={6}
      pt={12}
    >
      <Text>
        Московская область
        <br />
        Дата утверждения: 01.01.2023
      </Text>
      <Text>
        ИП Остроухова Маргарита Сергеевна (ОГРНИП: 320508100147062), именуемая в
        дальнейшем Исполнитель, действующий от собственного имени, адресует
        настоящий Договор-оферту (далее по тексту – Договор-оферта, Договор)
        любому лицу (неопределенному кругу лиц), чья воля будет выражена им
        лично либо через уполномоченного представителя (ст. 182, 185 ГК РФ),
        выразившему готовность воспользоваться услугами Исполнителя (далее по
        тексту – Заказчик).
      </Text>
      <HStack textStyle={'h3'} pt={6}>
        <Text>
          ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕМ ДОГОВОРЕ-ОФЕРТЕ:
        </Text>
      </HStack>
      <Text>
        <b>Акцепт</b> - это ответ лица о полном и безоговорочном принятии
        оферты, путем оформления и оплаты Заказа в порядке и на условиях,
        установленных настоящим Договором-офертой.
      </Text>
      <Text>
        <b>Услуги</b> – образовательные услуги в сфере обучения иностранному
        языку, предоставляемые в виде вебинаров, уроков, курсов, тренингов,
        семинаров, видео-, аудио записей, консультаций, тестов, электронных
        книг, пособий, брошюр или в любых иных формах, как при помощи различных
        он-лайн сервисов, электронной почты, мессенджеров или иными другими
        способами, включающие в себя прямые трансляции в сети Интернет, рассылки
        информации и обучающих материалов, предоставление материалов в записи,
        оказываемые как непосредственно Исполнителем, так и с помощью третьих
        лиц. Перечень, регламент и условия оказания Услуги описан на
        соответствующем Сайте, описывающую услугу.
      </Text>
      <Text>
        <b>Заказчик</b> – физическое лицо с базовым уровнем владения английским
        языком(А1-А2), оплатившее Услугу и получающее ее на условиях настоящего
        договора.
      </Text>
      <Text>
        <b>Курс, Онлайн-курс</b> - разработанный и утвержденный Исполнителем
        комплекс дистанционно оказываемых Услуг, представляющий собой
        совокупность нескольких объединенных одной темой форматов Услуг,
        разработанных Исполнителем с целью передачи Заказчику определяемой
        программой консультационной информации.
      </Text>
      <Text>
        <b>Сайт</b> - совокупность информации, текстов, графических элементов,
        дизайна, изображений, фото и видеоматериалов, и иных результатов
        интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в
        информационной системе, обеспечивающей доступность такой информации в
        сети Интернет по сетевому адресу{' '}
        <Link to='/'>https://thequeenenglish.com</Link> и его поддоменах.
      </Text>
      <Text>
        <b>Программное обеспечение, ПО</b>- интернет-браузер (Internet Explorer,
        FireFox, Google Chrome и/или аналогичные) для доступа к информационным
        ресурсам, находящимся в сети Интернет, иные программы для передачи,
        хранения, обработки предоставляемой информации. Заказчик обязуется
        самостоятельно обеспечить наличие ПО, необходимого для получения услуг
        Исполнителя, на своем персональном компьютере или любом ином устройстве.
        Заказчик подтверждает, что оказание Исполнителем услуг по настоящему
        договору дистанционно с использованием программного обеспечения
        полностью соответствует возможности Заказчика пользоваться услугами,
        оказываемыми таким способом.
      </Text>
      <Text>
        <b>Доступ в личный кабинет</b>- сообщение, направляемое Исполнителем
        Заказчику, путем электронного письма, содержащее информацию, необходимую
        для получения Заказчиком Услуг.
      </Text>
      <Text>
        <b>Обратная связь</b> – услуга, выражающаяся в получении Заказчиком
        ответов от Исполнителя, привлеченным им третьими лицами на вопросы
        Заказчика, проводимая в виде вебинара, общения в личном кабинете на
        Платформе, с помощью мессенджеров.
      </Text>
      <Text>
        <b>Чат</b> - закрытые сообщества в мессенджере Telegram (Телеграм),
        предназначенные для обмена сообщениями между Заказчиком и Исполнителем и
        кураторами в рамках оказания услуг по настоящему договору.
      </Text>
      <Text>
        <b>Рассылка</b> - автоматизированная отправка электронных писем на
        электронный адрес (e-mail) Заказчика.
      </Text>
      <Text>
        <b>Куратор</b> – привлекаемое к оказанию услуг Исполнителем лицо,
        осуществляющее практическое взаимодействие с Заказчиком (-ами) в форме
        проведения дистанционных консультаций посредством электронных средств
        коммуникации (Интернет, мессенджеры).
      </Text>
      <Text>
        <b>Приглашенный эксперт, Эксперт</b> – привлекаемое к оказанию услуг
        Исполнителем лицо, осуществляющее закрепление полученных в ходе оказания
        услуг (проведения Курса) навыков.
      </Text>
      <Text>
        <b>Платформа</b>- он-лайн сервис, служащий для размещения
        образовательных материалов Исполнителем и на котором осуществляется
        дистанционное оказание услуг Исполнителем Заказчику.
      </Text>
      <Text>
        <b>Тариф</b> - стоимость и состав оказываемых Исполнителем Заказчику
        услуг в рамках программы Курса, информация о которых указана на Сайте
        Исполнителя.
      </Text>
      <Text>
        <b>Библиотека</b> – обновляемая и пополняемая коллекция онлайн-курсов
        Исполнителя на различные темы для самостоятельного прохождения в любое
        время.
      </Text>
      <HStack textStyle={'h3'} pt={6}>
        <Text>1. ОБЩИЕ ПОЛОЖЕНИЯ И ПРАВОВОЕ ОСНОВАНИЕ ДОГОВОРА-ОФЕРТЫ</Text>
      </HStack>
      <Text>
        1.1. Договор-оферта является официальным предложением Исполнителя
        (офертой) к заключению договора оказания образовательных услуг (далее по
        тексту – Услуги) и содержит все существенные условия договора оказания
        образовательных услуг (далее по тексту – Договор).
      </Text>
      <Text>
        1.2. Акцептом Договора-оферты является осуществление следующих действий:
        оплата услуг Исполнителя в соответствии с действующим у него на текущий
        момент ценами.
      </Text>
      <Text>
        1.3. Осуществляя акцепт Договора-оферты в порядке, определенном в п.
        1.2. Договора-оферты, Заказчик гарантирует, что ознакомлен, соглашается,
        полностью и безоговорочно принимает все условия Договора в том виде, в
        каком они изложены в тексте Договора-оферты.
      </Text>
      <Text>
        1.4. Заказчик понимает, что акцепт Договора-оферты в порядке, указанном
        в п. 1.2. Договора-оферты равносилен заключению Договора на условиях,
        изложенных в Договоре-оферте в соответствии с п.3 ст.438 ГК РФ.
      </Text>
      <Text>
        1.5. Совершая действия по акцепту Договора-оферты Заказчик подтверждает,
        что он является совершеннолетним дееспособным лицом и имеет законные
        права на вступление в договорные отношения с Исполнителем.
      </Text>
      <Text>
        1.6. Исполнитель вправе в любое время вносить изменения в условия
        Договора-оферты без предварительного согласования с Заказчиком,
        обеспечивая при этом публикацию измененного Договора.
      </Text>
      <Text>
        1.7. Договор-оферта не требует скрепления печатями и/или подписания
        Заказчиком и Исполнителем, сохраняя при этом юридическую силу.
      </Text>
      <Text>
        1.8. Осуществляя акцепт настоящей оферты, Заказчик подтверждает, что
        имеет доступ к сети Интернет и имеет возможность получать Услуги в
        онлайн-формате, в том числе имеет возможность использования Платформы.
      </Text>
      <Text>
        1.9. Заказчик обязуется внимательно прочитать текст данной оферты и,
        если он не согласен с каким-либо пунктом, то Исполнитель предлагает
        отказаться от каких-либо действий, необходимых для акцепта или заключить
        персональный договор на отдельно обсуждаемых с Исполнителем условиях.
      </Text>
      <HStack textStyle={'h3'} pt={6}>
        <Text>2. ПРЕДМЕТ ДОГОВОРА</Text>
      </HStack>
      <Text>
        2.1. Предметом настоящей Оферты является возмездное предоставление
        Заказчику Услуг по выбранной Заказчиком цене и на выбранную Заказчиком
        тему, которые Заказчик обязуется принять и оплатить в порядке,
        предусмотренном настоящим Договором.
      </Text>
      <Text>
        2.2. Дата начала предоставления Услуг Заказчику (дата старта курса
        указана на сайте)
      </Text>
      <Text>
        2.3. В случае приобретения услуг в соответствии с Тарифом «С куратором»
        срок оказания Услуг по настоящему договору – 10 (десять) недель. В
        случае приобретения услуг в соответствии с Тарифом «С Маргарет» срок
        оказания Услуг по настоящему договору – 10 (десять) недели + 2 (две)
        личные консультации не более 1 часа каждая, предусмотренные в течение
        курса или после него.
      </Text>
      <Text>
        2.4. В случае приобретения услуг в формате «Библиотеки» доступ к
        материалам Библиотеки предоставляется с даты оплаты сроком на 1 (один)
        год.
      </Text>
      <Text>
        2.4.1. Групповые или индивидуальные семинары в семинарской комнате,
        доступ к которой Заказчик получает в случае приобретения Услуг в формате
        «Библиотеки», оплачивается Заказчиком отдельно на условиях, обозначенных
        Исполнителем, и в стоимость услуг в формате «Библиотеки» не входят.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>3. СРОК ДЕЙСТВИЯ ДОГОВОРА </Text>
      </HStack>
      <Text>
        3.1. Договор считается заключенным с момента поступления денежных
        средств Заказчика в качестве оплаты (предоплаты) за выбранную Услугу на
        расчетный счет Исполнителя и действует до момента исполнения Сторонами
        своих обязательств.
      </Text>
      <Text>
        3.2. В случае если Заказчик не получает Услугу своевременно по
        собственной вине или собственному желанию, в силу личных обстоятельств,
        Услуга считается оказанной надлежащим образом и полностью.
      </Text>
      <Text>
        3.3. Заказчик самостоятельно знакомится с графиком и содержанием
        программы Курса на Платформе и/или в чате Telegram (Телеграм) и несет
        ответственность за своевременное получение информации и выполнение
        предусмотренных программой заданий Курса.
      </Text>
      <Text>
        3.4. Доступ к образовательным материалам (вне зависимости от этапности
        их получения) сохраняется за Заказчиком в течение срока, указанного в
        описании Тарифа. Доступ к материалам после завершения оказания услуг
        предоставляется в течение 1 (одного) месяц с даты окончания Курса, при
        приобретении услуг в соответствии с Тарифом «С куратором». Доступ к
        материалам после завершения оказания услуг предоставляется в течение 3
        (трех) месяцев с даты окончания Курса, при приобретении услуг в
        соответствии с Тарифом «С Маргарет».
      </Text>
      <Text>
        3.5. Услуги по настоящему Договору оказываются и принимаются поэтапно.
        Услуги по каждому из этапов считаются оказанными надлежащим образом, и
        принятыми Заказчиком, если:
      </Text>
      <Text>
        3.5.1. В течение 2 (двух) дней с момента получения Заказчиком доступа к
        новым материалам, Заказчик не заявил письменного мотивированного
        возражения в отношении качества и/или объема оказанных услуг путем
        отправки соответствующего обращения на адрес электронной почты
        Исполнителя, указанный в настоящем Договоре.
      </Text>
      <Text>
        3.5.2. Если в течение 2 (двух) дней с момента получения Заказчиком
        доступа к Библиотеки, Заказчик не заявил письменного мотивированного
        возражения в отношении качества и/или объема оказанных услуг путем
        отправки соответствующего обращения на адрес электронной почты
        Исполнителя, указанный на Сайте или в настоящем Договоре, услуги
        считаются оказанными надлежащим образом.
      </Text>
      <Text>
        3.6. По истечении 2 (двух) дней с момента получения всех материалов
        Исполнитель принимает претензии только на предмет технических неполадок
        и отсутствия доступа к материалам в рамках срока, указанных в настоящем
        Договоре.
      </Text>
      <Text>
        3.7. Настоящий Договор является актом об оказании услуги. Услуги
        считаются оказанными с надлежащим качеством по истечении сроков,
        указанных в п. 3.5 настоящего Договора, и оказанными в срок при
        отсутствии в письменном виде претензий Заказчика в течение срока доступа
        к материалам Курса.
      </Text>
      <Text>3.8. Приостановление срока получения Услуг не предусмотрено.</Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>4. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГ</Text>
      </HStack>
      <Text>
        4.1. Заказчик вносит указанную и зафиксированную на сумму оплаты/часть
        оплаты до даты старта Курса. Исполнитель предоставляет Заказчику доступ
        к материалам, по оплаченной Услуге, после старта Курса поэтапно в
        соответствии с графиком Курса.
      </Text>
      <Text>
        4.2. Заказчик не вправе передавать третьим лицам свой доступ к
        материалам, предоставленный Исполнителем. При нарушении данного пункта
        настоящего Договора Исполнитель вправе запретить Заказчику доступ к уже
        оплаченным Услугам без возврата ранее оплаченных денежных средств.
      </Text>
      <Text>
        4.3. Заказчик соглашается с тем, что Исполнитель предоставляет доступ к
        услугам или цифровым продуктам как есть, и не вправе требовать
        дополнительной доработки оплаченной им услуги или продукта, а также его
        отдельных компонентов, образовательных материалов, а также изменения
        правил предоставления услуг или доступа к продуктам, описанных
        положениями настоящего Договора.
      </Text>
      <Text>
        4.4. Условия оказания Услуг могут также сообщаться Исполнителем
        Заказчику, путем направления соответствующего уведомления на контактный
        е-mail, и/или SMS сообщения на контактный номер телефона Заказчика, на
        Платформе, в мессенджерах, при этом Заказчик обязан самостоятельно и
        заблаговременно проверять получение уведомления, исходящего от
        Исполнителя.
      </Text>
      <Text>
        4.5. Исполнитель имеет право привлекать к оказанию Услуг третьих лиц без
        предварительного получения на то согласия Заказчика. Привлекаемые третьи
        лица выбираются по усмотрению Исполнителя. Исполнитель оставляет за
        собой право замены третьих лиц при оказании Услуг при условии сохранения
        тематики, экспертности и перечня вопросов, которые эти привлеченные
        третьи лица будут освещать.
      </Text>
      <Text>
        4.6. По окончании получения Услуги Заказчиком, Исполнитель высылает
        Заказчику на адрес его электронной почты сертификат о прохождении Услуги
        при условии выполнения Заказчиком всех заданий Курса не менее 80%,
        предусмотренных Курсом.
      </Text>
      <Text>
        4.7. В случае, если Услугой предусмотрена обратная связь Исполнителя, то
        Куратор или Исполнитель предоставляет ее в течение 48 (сорока восьми)
        часов с момента получения соответствующего запроса от Заказчика за
        исключением выходных дней – субботы и воскресенья.
      </Text>
      <Text>
        4.8. Услуга получения обратной связи от Куратора, закрепленного
        Исполнителем за Заказчиком, осуществляется в течение всей длительности
        Курса.
      </Text>
      <Text>
        4.9. Заказчик обязуется своевременно выполнять задания Курса, получаемые
        от Исполнителя в рамках оказания услуг по настоящему Договору,
        своевременно отчитываться о выполнении данных заданий Исполнителю (в
        случае если это предусмотрено тарифом курса) в полном объеме и по форме,
        установленной Исполнителем и размещенной в личном кабинете.
      </Text>
      <Text>
        4.10. Исполнитель имеет право не приступать к оказанию услуг, а также
        приостанавливать оказание услуг, к которым он фактически приступил, в
        случаях нарушения Заказчиком своих обязательств по настоящему Договору,
        а именно: неполной (ненадлежащей, несвоевременной) оплаты, сообщения
        неполной (недостоверной) информации, непредставления (несвоевременного
        представления) регистрационных или иных данных необходимых для оказания
        услуг, нарушения правил поведения в чате.
      </Text>
      <Text>
        4.11. Любое копирование, сохранение, размещение, публикация и т.д.
        материалов, предоставляемых Исполнителем в рамках оказания услуг, для
        личного или иного использования, на персональные компьютеры, электронные
        устройства, серверы, сайты, видеохостинги, электронные и
        интернет-ресурсы, предоставление данных доступа к платформе, иного
        предоставления материалов курса Исполнителя третьим лицам категорически
        запрещено. Установление указанных фактов влечет блокировку личного
        кабинета Заказчика и прекращение оказания услуг по Договору без возврата
        денежных средств.
      </Text>
      <Text>
        4.12. Исполнителем обязуется принимать все необходимые меры, однако не
        гарантирует 100% бесперебойности предоставления услуг по настоящему
        Договору. В случае неудовлетворительного качества Интернет-соединения со
        стороны Заказчика, наличия технических неполадок в устройствах, или
        программном обеспечении, используемом им для получения услуг,
        технических неполадок со стороны сервисов оплаты, дата/сроки оказания
        услуг могут быть изменены Исполнителем в одностороннем порядке без
        согласования с Заказчиком.
      </Text>
      <Text>
        4.13. Заказчик обязуется неукоснительно и безоговорочно соблюдать
        следующие Правила поведения при оказании ему Услуг Исполнителем
        (онлайн):
        <br />- соблюдать дисциплину и общепринятые нормы поведения, в
        частности, проявлять уважение к Исполнителю и другим Заказчикам, не
        посягать на их честь и достоинство;
        <br />- не допускать агрессивного поведения во время оказания услуг, не
        мешать Исполнителю, представителю (-ям) Исполнителя или другим
        Заказчикам при оказании/получении Услуг, не допускать высказываний
        (устно, письменно), не относящихся к теме курса и др.;
        <br />- не использовать информацию, полученную от Исполнителя,
        способами, которые могут привести или приведут к нанесению ущерба
        интересам Исполнителя;
        <br />- не использовать предоставленные Исполнителем материалы с целью
        извлечения прибыли путем их тиражирования и многократного
        воспроизведения (публикации в прессе и других изданиях, публичные
        выступления и т.п.) и иными способами;
        <br />- не распространять любым способом, в т.ч. третьим лицам, не
        копировать, не сохранять, не размещать, не публиковать в общедоступных,
        закрытых, открытых источниках для любого круга лиц (в т.ч. для
        собственного использования) предоставленные Исполнителем: информацию,
        материалы, методички, записи услуг, оказываемых Исполнителем, а также не
        предоставлять данные доступа к личному кабинету Заказчика третьим лицам;
        <br />- не использовать ненормативную лексику, не употреблять в общении
        выражения, которые могут оскорбить представителя Исполнителя или других
        Заказчиков;
        <br />- не распространять рекламу и не предлагать услуги сторонних
        ресурсов, свои услуги или услуги третьих лиц.
      </Text>
      <Text>
        4.14. Для качественного оказания услуг другим заказчикам, Исполнитель
        обязуется расторгнуть Договор с Заказчиком, нарушившим условия п. 4.13
        настоящего Договора, в том числе удалить Заказчика из чатов, при этом у
        Исполнителя не возникает обязанности возвращать денежные средства/
        компенсировать убытки Заказчика.
      </Text>
      <Text>
        4.15. Исполнитель обязуется оказывать услуги на высоком профессиональном
        уровне, подтвержденном опытом, Исполнителя, в соответствии с
        собственным, авторским видением и при использовании приемов/методик,
        отражающих видение и опыт Исполнителя. Несогласие Заказчика с
        результатами оказания услуг, выражающееся в субъективном непринятии
        стиля работы Исполнителя, собственных результатов и т.п., в условиях
        надлежащего оказания услуг Исполнителем, не является основанием для
        расторжения настоящего Договора и возврата денежных средств Заказчику.
      </Text>
      <Text>
        4.16. Исполнитель не несет ответственности за то, каким образом Заказчик
        использовал полученную информацию, а также за достижение каких-либо
        результатов, связанных с практическим применением данной информации.
        Любые рекомендации, полученные в ходе оказания услуг, осуществляются
        Заказчиком на свой риск.
      </Text>
      <Text>
        4.17. Исполнитель вправе изменять в одностороннем порядке график
        размещения материалов Курса, проведения обратной связи и иных
        консультаций, не меняя при этом установленную периодичность их
        проведения, а также менять и дополнять содержание заданий для Заказчика
        в рамках Курса.
      </Text>
      <Text>
        4.18. Заказчик понимает и соглашается с тем, что в ходе получения Услуги
        или для начала ее получения, Заказчику необходим будет следующий
        минимальный набор дополнительного оборудования: персональный компьютер
        или иное портативное (мобильное) устройство с доступом в сеть Интернет,
        оборудованное наушниками и микрофоном.
      </Text>
      <Text>
        4.19. В случае возникновения у Заказчика необходимости/желания изменить
        Тариф, Заказчик должен обратиться к Исполнителю, посредством отправки
        соответствующего заявления на адрес электронной почты Исполнителя
        Royalbritishpronunciation@gmail.com. При этом Исполнитель вправе
        отказать Заказчику в переходе с одного Тарифа на другой без объяснения
        причин. Переход с более дорогостоящего Тарифа на менее дорогостоящий
        Тариф не осуществляется. В случае, если Исполнитель принимает
        положительное решение относительно смены Заказчиком Тарифа, сроки и
        условия внесения необходимой доплаты в соответствии с новым Тарифом
        согласуются Сторонами.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>5. ПОРЯДОК СДАЧИ-ПРИЕМКИ УСЛУГ</Text>
      </HStack>
      <Text>
        5.1. Услуги по Договору считаются оказанными с надлежащим качеством и в
        срок, а также принятыми Заказчиком, если в течение 2 (двух) календарных
        дней с момента окончания оказания услуг в соответствии с положениями п.
        3.5. настоящего Договора, Заказчик не заявил мотивированного возражения
        о качестве и объеме таких услуг путем отправки соответствующей обращения
        по адресу электронной почты: Royalbritishpronunciation@gmail.com .
        Сообщение, направляемое на адрес электронной почты Исполнителя, должно
        содержать сведения, позволяющие однозначно идентифицировать личность
        отправителя.
      </Text>
      <Text>
        5.2. Срок устранения Исполнителем недостатков составляет 5 (пять)
        рабочих дней со дня получения Исполнителем письменного мотивированного
        возражения Заказчика, указанного в п. 5.1. Договора.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>6. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</Text>
      </HStack>
      <Text>
        6.1. Общая стоимость рассчитывается из стоимости выбранных Заказчиком
        услуг в соответствии с Тарифами, указанными на Сайте. НДС не облагается.
      </Text>
      <Text>
        6.2. Общая стоимость услуг может быть изменена только путем подписания
        дополнительного соглашения к Договору.
      </Text>
      <Text>
        6.3. Оплата услуг по Договору осуществляется в порядке 100 (сто) –
        процентной предоплаты или частичной оплаты, если на Сайте Исполнителя не
        указано иное. Оплата осуществляется путем перечисления денежных средств
        на реквизиты, указанные Исполнителем, либо с использованием электронных
        платежных систем, указанных Исполнителем (платежный модуль на Сайте
        Исполнителя). Услуги считаются оплаченными с момента поступления
        денежных средств на расчетный счет Исполнителя.
      </Text>
      <Text>
        6.3.1. По желанию Заказчика возможно оформление рассрочки посредством
        банковской рассрочки от Тинькоф Банка при приобретении Заказчиком Услуг
        в соответствии с Тарифом «С куратором» либо при приобретении Услуг в
        формате «Библиотеки». При приобретении Заказчиком Услуг в соответствии с
        Тарифом «С Маргарет» оформление рассрочки посредством банковской
        рассрочки от Тинькоф Банка не предусмотрено. При приобретении Заказчиком
        Услуг в соответствии с Тарифом «С Маргарет», Заказчик вправе обратиться
        за рассрочкой непосредственно к Исполнителю.
      </Text>
      <Text>
        6.4. Возврат денежных средств Исполнителем осуществляется по заявлению
        Заказчика, направленному на электронный адрес:
        Royalbritishpronunciation@gmail.com не позднее 10 (десяти) календарных
        дней с даты принятия решения Исполнителем о возврате. Срок рассмотрения
        заявления на возврат – не более 10 (десяти) календарных дней с даты
        получения соответствующего заявления.
      </Text>
      <Text>
        6.4.1. Возврат осуществляется при условии полной оплаты Исполнителю
        фактически понесенных расходов, и с учетом условий, указанных в п. 6.8,
        п. 6.8.1. настоящего Договора.
      </Text>
      <Text>
        6.4.2. К фактическим затратам Исполнителя относятся (включая, но не
        ограничиваясь) комиссии банковских, кредитных организаций и
        соответствующих платежных систем за осуществление возврата денежных
        средств, организационные издержки в виде денежного вознаграждения
        сотрудников Исполнителя и (или) третьих лиц, участвующих в подготовке и
        оказании образовательных услуг (в том числе работа кураторов,
        Приглашенных экспертов и т.д.), стоимость организации и записи уроков. К
        фактическим затратам Исполнителя относятся также стоимость материалов,
        предоставленных Заказчику, стоимость которых определяется Исполнителем
        самостоятельно, даже если Заказчик не использовал материалы и (или) не
        знакомился с ними (при их наличии), при условии наличия такой
        возможности.
      </Text>
      <Text>
        6.5. Заявление на возврат денежных средств в обязательном порядке должно
        содержать ФИО, паспортные данные лица, дату рождения и банковские
        реквизиты для возврата, а также адрес электронной почты, номер и дату
        заказа. В случае отсутствия каких-либо из указанных сведений,
        Исполнитель вправе не принимать заявление к рассмотрению до момента
        устранения недочетов.
      </Text>
      <Text>
        6.6. Денежные средства возвращаются на тот же банковский счет Заказчика,
        с которого производилась оплата услуг. Денежные средства, за услуги,
        оплаченные Заказчиком с помощью банковской карты, не могут быть
        возвращены наличными денежными средствами.
      </Text>
      <Text>
        6.7. В соответствии с п. 14 «Перечня непродовольственных товаров
        надлежащего качества, не подлежащих возврату или обмену на аналогичный
        товар других размера, формы, габарита, фасона, расцветки или
        комплектации» образовательные материалы в формате PDF-файлов, видео-,
        аудиозаписей курса, а также воспроизведенные на иных технических
        носителях информации, предоставляемые Исполнителем Заказчику
        единовременно, возврату не подлежат. Иными словами, Исполнитель не
        осуществляет возврат в случае, если материалы уже получены Заказчиком/
        он получил к ним доступ.
      </Text>
      <Text>
        6.8. Обращение за возвратом при условии получения доступа к материалам с
        учетом этапности получения Услуги осуществляется в следующем объеме:
      </Text>
      <Text>
        <Text textDecoration={'underline'}>
          <i>
            При обращении за возвратом при приобретении услуг в соответствии с
            Тарифом «С куратором»:
          </i>
        </Text>
        <Text pl={4}>
          - При обращении за возвратом до старта Курса – возврат 90 % от
          отплаченной стоимости Услуг за вычетом фактически понесенных расходов
          (удерживаемых 10% составляют стоимость проведенной личной консультации
          Исполнителя перед началом обучения);
          <br />- При обращении за возвратом после старта Курса возврат
          осуществляется пропорционально открытым Заказчиком модулям за вычетом
          фактических расходов, а также за вычетом организационных издержек.
          Стоимость организационных издержек определяется в размере 30% от
          стоимости Тарифа, выбранного Заказчиком.
        </Text>
      </Text>
      <Text>
        <Text textDecoration={'underline'}>
          <i>
            При обращении за возвратом при приобретении услуг в соответствии с
            Тарифом «С Маргарет»:
          </i>
        </Text>
        <Text pl={4}>
          - При обращении за возвратом до старта Курса – возврат 90 % от
          отплаченной стоимости Услуг за вычетом фактически понесенных расходов
          (удерживаемых 10% составляют стоимость проведенной личной консультации
          Исполнителя перед началом обучения);
          <br />- При обращении за возвратом после старта Курса возврат
          осуществляется пропорционально открытым Заказчиком модулям за вычетом
          фактических расходов, а также за вычетом организационных издержек.
          Стоимость организационных издержек определяется в размере 30% от
          стоимости Тарифа, выбранного Заказчиком.
        </Text>
      </Text>
      <Text>
        <Text textDecoration={'underline'}>
          <i>
            При обращении за возвратом при приобретении услуг в соответствии с
            форматом «Библиотека»:
          </i>
        </Text>
        <Text pl={4}>
          - При обращении за возвратом до получения доступа к материалам –
          возврат 100 % за вычетом фактически понесенных расходов;
          <br />- При обращении за возвратом после получения доступа к
          материалам – возврат не осуществляется.
        </Text>
      </Text>
      <Text>
        6.8.1. В случае, если Заказчик не проходит Психологический блок
        полностью, Заказчику осуществляется возврат, пропорционально сроку
        обучения, приходящемуся на Психологический блок, от общего срока
        длительности обучения, в соответствии с приобретенными Заказчиком
        Услугами, в течение 10 (десяти) календарных дней с даты принятия решения
        о не прохождении Психологического блока. В случае, если Заказчик не
        проходит Психологический блок частично, Заказчику осуществляется
        возврат, пропорционально сроку не пройденного обучения, приходящемуся на
        Психологический блок, от общего срока длительности обучения, в
        соответствии с приобретенными Заказчиком Услугами, в течение 10 (десяти)
        календарных дней с даты принятия решения о не прохождении
        Психологического блока.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>7. ОТВЕТСТВЕННОСТЬ СТОРОН. ПОРЯДОК РАСТОРЖЕНИЯ ДОГОВОРА</Text>
      </HStack>
      <Text>
        7.1. Стороны несут ответственность за неисполнение или ненадлежащее
        исполнение своих обязательств по Договору в соответствии с Договором и
        законодательством России.
      </Text>
      <Text>
        7.2. Договор может быть расторгнут по соглашению Сторон, а также в
        одностороннем порядке по письменному требованию одной из Сторон по
        основаниям, предусмотренным Договором и законодательством.
      </Text>
      <Text>
        7.3. Расторжение Договора в одностороннем порядке производится только по
        письменному требованию Сторон. Заказчик вправе расторгнуть Договор в
        одностороннем порядке только при условии полной оплаты Исполнителю
        фактически осуществленных последним расходов на оказание услуг и в
        соответствии с условиями возврата, указанными в разделе 6 настоящего
        Договора. Исполнитель вправе расторгнуть Договор в одностороннем порядке
        в случае нарушения Заказчиком его обязательств по настоящему Договору.
      </Text>
      <Text>
        7.4. Исполнитель обязуется принимать все необходимые меры, однако не
        гарантирует 100% бесперебойности предоставления услуг по настоящему
        Договору. В случае неудовлетворительного качества Интернет-соединения со
        стороны Заказчика, наличия технических неполадок в устройствах, или
        программном обеспечении, используемом им для получения услуг, дата/сроки
        оказания услуг могут быть изменены Исполнителем в одностороннем порядке
        без согласования с Заказчиком.
      </Text>
      <Text>
        7.5. Стороны пришли к соглашению, что будут стараться урегулировать
        возникающие конфликты мирным путем. Претензионный порядок является для
        Сторон обязательным. Спор может передаваться на разрешение суда после
        принятия Сторонами мер по досудебному урегулированию конфликта.
      </Text>
      <Text>
        7.6. Споры из Договора разрешаются в судебном порядке в г.
        Санкт-Петербург.
      </Text>
      <Text>
        7.7. Исполнитель не несет ответственности за ненадлежащее качество
        оказания Услуг, если ненадлежащее исполнение явилось следствием
        недостоверности, недостаточности или несвоевременности предоставленных
        Заказчиком сведений, а также вследствие других нарушений условий
        настоящего Договора со стороны Заказчика.
      </Text>
      <Text>
        7.8. Исполнитель не несет ответственности за несоответствие
        предоставленной услуги ожиданиям Заказчика и/или за его субъективную
        оценку. Такое несоответствие ожиданиям и/или отрицательная субъективная
        оценка не являются основаниями считать услуги оказанными некачественно,
        или не в согласованном объеме.
      </Text>
      <Text>
        7.9. Исполнитель не несет ответственности за неверную (недостоверную)
        информацию, указанную Заказчиком при регистрации, либо при оплате услуг.
      </Text>
      <Text>
        7.10. Исполнитель не несет ответственности за невозможность обслуживания
        Заказчика по причинам, связанным с нарушением работы Интернет-канала,
        оборудования или программного обеспечения со стороны Заказчика.
      </Text>
      <Text>
        7.11. В случае, если Заказчик не направляет в адрес Исполнителя
        предусмотренные Курсом задания своевременно, а также отстает от
        программы, предусмотренной Курсом более, чем на 3 (три) недели,
        Исполнитель имеет право расторгнуть настоящий Договор при условии полной
        оплаты Исполнителю фактически понесенных расходов, и с учетом условий,
        указанных в разделе 6 настоящего Договора-оферты.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>8. ФОРС-МАЖОР</Text>
      </HStack>
      <Text>
        8.1. Стороны освобождаются от ответственности за полное или частичное
        неисполнение обязательств по Договору в случае, если неисполнение
        обязательств явилось следствием действий непреодолимой силы, а именно:
        пожара, наводнения, землетрясения, забастовки, войны, действий органов
        государственной власти или других независящих от Сторон обстоятельств.
      </Text>
      <Text>
        8.2. Сторона, которая не может выполнить обязательства по Договору,
        должна своевременно, но не позднее 5 (пяти) календарных дней после
        наступления обстоятельств непреодолимой силы, письменно известить другу
        Сторону, с предоставлением обосновывающих документов. Выданных
        компетентными органами.
      </Text>
      <Text>
        8.3. Стороны признают, что неплатежеспособность Заказчика, отсутствие у
        него свободного времени по любым основаниям для получения оплаченной
        услуги, нахождение в отпуске, командировке, неоплата доступа к сети
        Интернет, поломка средства доступа к сети Интернет не являются
        обстоятельствами непреодолимой силы (форс-мажорными обстоятельствами).
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>9. ПРОЧИЕ УСЛОВИЯ</Text>
      </HStack>
      <Text>
        9.1. Стороны признают, что, если какое-либо из положений становится
        недействительным в течение срока его действия вследствие изменения
        законодательства, остальные положения настоящего Договора обязательны
        для Сторон в течение срока действия Договора.
      </Text>
      <Text>
        9.2. Информация, передаваемая в рамках оказания Услуг, является
        интеллектуальной собственностью Исполнителя и подлежит охране в
        соответствии с законодательством Российской Федерации. Распространение
        полученной в рамках Услуг информации в коммерческих целях недопустимо.
        Заказчик обязуется не копировать записи Услуг и не предоставлять ее
        третьим лицам или размещать в открытом доступе на других сайтах.
        Заказчику запрещается распространять, публиковать, размещать в Интернет,
        копировать, передавать или продавать третьим лицам, осуществлять запись,
        скачивание, изготовление скриншотов и фотографий записей
        аудио-визуальных произведений и другого контента, предоставляемого в
        рамках оказания Услуг по настоящему Договору Исполнителем/ другими
        заказчиками. Заказчику запрещается сохранять и передавать третьим лицам
        свою переписку с Куратором/ другими заказчиками, информацию и любые
        материалы, полученные в рамках настоящего Договора, создавать на их
        основе информационные/ образовательные продукты, а также использовать
        эту информацию каким-либо иным образом, кроме как для личного
        пользования в рамках настоящего Договора.
      </Text>
      <Text>
        9.3. В случае нарушения такого обязательства, Исполнитель вправе
        требовать с Заказчика возмещения понесенных убытков, а также компенсации
        упущенной выгоды в соответствии с действующим законодательством РФ, а
        также штраф в размере 500 000 (пятьсот тысяч) рублей.
      </Text>
      <Text>
        9.4. Исполнитель имеет право незамедлительно прекратить оказание услуг
        без возвращения денежных средств, в случае проявления со стороны
        Заказчика агрессии или неуважительного отношения к Исполнителю,
        Кураторам и иным привлеченным третьим лицам, в случае использования
        ненормативной лексики и проявления агрессии в комментариях на Платформе,
        в чатах, а также в случаях разжигания межнациональных конфликтов,
        рассылки спама, размещения рекламы. Настоящим Заказчик подтверждает, что
        предоплаченная им стоимость услуг в случаях, указанных в настоящем
        пункте, признается Сторонами как неустойка за нарушение Заказчиком
        условий Договора и Заказчику не возвращается.
      </Text>
      <Text>
        9.5. Заказчик подтверждает, что у него есть время в выходные для
        посещения не менее 2-3 онлайн занятий длительностью по 1,5 часа.
        Заказчик подтверждает, что у него есть достаточно энергии и времени
        (минимум 8 часов в неделю) для прохождения курса.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>10. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</Text>
      </HStack>
      <Text>
        10.1. В соответствии с требованиями Федерального закона от 27.07.2006 №
        152 – ФЗ «О персональных данных» Заказчик (субъект персональных данных),
        принимая условия настоящего Договора, дает согласие на обработку всех
        своих персональных данных, предоставляемых Исполнителю, а также на их
        передачу лицам, с которыми у Исполнителя имеются договорные отношения по
        исполнению условий настоящего Договора и оказанию услуги Заказчику.
        Обработка персональных данных Заказчика осуществляется в соответствии с
        законодательством РФ, а также в соответствии с Политикой
        конфиденциальности, являющейся локальным документом Исполнителя.
      </Text>
      <Text>
        10.2. Все персональные данные обрабатываются Исполнителем в соответствии
        с требованиями Федерального закона 152-ФЗ «О персональных данных» и в
        соответствии с Политикой конфиденциальности, размещенной на Сайте по
        ссылке:
        <Link to='/privacy'> https://thequeenenglish.com/privacy</Link>
      </Text>
      <Text>
        10.3. Исполнитель обязуется сохранять и не разглашать конфиденциальную
        информацию, ставшую ему известной от Заказчика при оказании Услуг по
        настоящему Договору.
      </Text>
      <Text fontWeight={700} pt={6}>
        Реквизиты Исполнителя и контактная информация:
      </Text>
      <Text>
        ИП Остроухова Маргарита Сергеевна
        <br />
        ИНН 505396143480
        <br />
        ОГРН 320508100147062
        <br />
        Электронная почта: Royalbritishpronunciation@gmail.com
      </Text>
    </VStack>
  )

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='lightGray'
        backBg='white'
      >
        <BlackPageHeader title='Договор-оферта оказания образовательных услуг' />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        pb={12}
      >
        {content}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Contract
