import React from 'react'
import {
  IConference,
  ICourse,
  IEntityType,
  IExaminationAttempt,
  ILesson,
  ISeminar,
  ITestResult,
  IWebinar
} from 'shared/types'

export interface ICourseContext {
  entity: ICourse | IConference | IWebinar | ISeminar | null
  entityType: IEntityType
  lessons: Record<string, ILesson> | null
  testResults: ITestResult[]
  passedWeeks: Record<string, boolean>
  passedDays: Record<string, boolean>
  examinationAttempts: Record<string, IExaminationAttempt> | null
}

const Ctx = React.createContext<ICourseContext>({
  entity: null,
  entityType: IEntityType.COURSES,
  lessons: null,
  testResults: [],
  passedWeeks: {},
  passedDays: {},
  examinationAttempts: null
})

export default Ctx
