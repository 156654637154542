import { useState, useEffect } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { dbFetchPaymentLink } from 'controllers/userPayments'
import { IEntityType, IPaymentLink } from 'shared/types'
import Loading from 'components/Loading'
import PaymentModalContent from 'modals/paymentModal/PaymentModalContent'
// import PrimaryButton from 'shared/components/PrimaryButton'

const PaymentLink = () => {
  const [pl, setPl] = useState<IPaymentLink | null>()
  const { linkId } = useParams() as { linkId: string }
  // const contentRef = useRef<IPaymentModalContent>(null)
  // const [loading, setLoading] = useState(false)
  // const authData = useSelector(state => state.authData)
  // const payanywayModalRef = useRef<IPayanywayModal>(null)
  // const [isComplete, setIsComplete] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const run = async () => {
      const res = await dbFetchPaymentLink(linkId)
      setPl(res)
    }
    run()
  }, [linkId])

  // const renderHeader = () => {
  //   if (pl) {
  //     return (
  //       <>
  //         <Text textStyle={'h3'} fontWeight={700}>
  //           {pl.title}
  //         </Text>
  //         <Text textStyle={'h3'}>{pl.price} руб.</Text>
  //       </>
  //     )
  //   }
  // }

  // const renderComplete = () => {
  //   if (isComplete) {
  //     return (
  //       <HStack bg='green10' w='full' py={2} align='center' justify={'center'}>
  //         <Text color='green'>Оплата прошла успешно</Text>
  //       </HStack>
  //     )
  //   }
  // }

  const navigateOnSucess = () => {
    navigate('/profile')
  }

  const renderContent = () => {
    if (pl === undefined) {
      return <Loading />
    } else if (pl === null) {
      return (
        <HStack align={'center'} justify={'center'} h='full' w='full'>
          <Text textStyle={'small'} color={'darkGray'}>
            Платежная ссылка не существует
          </Text>
        </HStack>
      )
    } else {
      return (
        <VStack
          maxW='540px'
          w='full'
          boxShadow={'shadow1'}
          rounded={'8px'}
          // p={6}
          align='flex-start'
          spacing={6}
        >
          <PaymentModalContent
            itemInfo={{
              id: pl.id,
              title: pl.title,
              type: IEntityType.PAYMENT_LINKS,
              price: pl.price,
              successPath: '/profile'
            }}
            navigateOnSucess={navigateOnSucess}
            onClose={() => null}
          />
        </VStack>
      )
    }
  }

  // const openPaymentDialog = async (
  //   userId: string,
  //   email: string,
  //   isRub: boolean
  // ) => {
  //   console.log('openPaymentDialog', userId, isRub)
  //   if (authData && pl) {
  //     const itemInfo: IItemInfo = {
  //       title: pl.title,
  //       id: pl.id,
  //       type: IEntityType.PAYMENT_LINKS,
  //       price: pl.price
  //     }
  //     payanywayModalRef.current?.open(itemInfo)
  //   }
  // }

  // const apply = async (isRub: boolean) => {
  //   if (contentRef.current) {
  //     const errs = contentRef.current.getErrors()
  //     if (isEmpty(errs)) {
  //       if (authData?.uid) {
  //         openPaymentDialog(authData.uid, authData.email || '', isRub)
  //       } else {
  //         setLoading(true)
  //         const user = await contentRef.current.signUp()
  //         if (user) {
  //           openPaymentDialog(user.id, user.email || '', isRub)
  //         }
  //       }
  //     }
  //     setLoading(false)
  //   }
  // }

  // const renderButtons = () => {
  //   return (
  //     <PrimaryButton
  //       w='full'
  //       onClick={() => apply(true)}
  //       isLoading={loading}
  //       maxW='802px'
  //     >
  //       Оплатить
  //     </PrimaryButton>
  //   )
  // }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        pt={24}
        pb={12}
        full
        minH='90vh'
        align={'center'}
      >
        {renderContent()}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default PaymentLink
