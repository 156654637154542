import { HStack, Text, Flex, Spinner, VStack } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/crrown.svg'

const Loading = () => {
  return (
    <HStack w='full' h='80vh' align='center' justify={'center'}>
      <VStack>
        <HStack spacing={2} color='black'>
          <Flex
            h={{ base: '22px', lg: 7 }}
            w={{ base: '22px', lg: 7 }}
            bg='white'
            rounded={'full'}
            justify={'center'}
            align='center'
            px={0.5}
          >
            <Logo />
          </Flex>
          <Text
            fontWeight={'medium'}
            fontSize={{ base: 'xl', lg: '2xl' }}
            letterSpacing={{ base: '-1px', lg: '-1.3px' }}
          >
            thequeenenglish
          </Text>
        </HStack>
        <Spinner size='xs' />
      </VStack>
    </HStack>
  )
}

export default Loading
