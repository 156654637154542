import { useContext } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import ContentContainer from 'shared/components/ContentContainer'
import UserEntityHeader from 'pages/userEntity/UserEntityHeader'
import { IWeek } from 'shared/types'
import EntityElementsContainer from 'components/EntityElementsContainer'
import EntityRowStatus from 'components/EntityRowStatus'
import get from 'lodash/get'
import has from 'lodash/has'

const UserEntityMain = () => {
  const { entity, entityType, passedWeeks } = useContext(LessonsContext)

  // console.log('passedWeeks', passedWeeks)

  const renderWeek = (w: IWeek) => {
    if (entity) {
      // console.log('week status success', w.id, get(passedWeeks, w.id, false))
      return (
        <EntityRowStatus
          key={w.id}
          title={w.title}
          toPath={`/profile/${entityType}/${entity.id}/${w.id}`}
          status={get(passedWeeks, w.id, false) ? 'success' : 'checkmark'}
          isDisabled={!has(passedWeeks, w.id)}
        />
      )
    }
  }

  const renderWeeks = () => {
    if (entity && entity.content) {
      return (
        <EntityElementsContainer>
          {entity.content.map(renderWeek)}
        </EntityElementsContainer>
      )
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      >
        <UserEntityHeader
          title={entity?.title || ''}
          backUrl={`/profile/${entityType}`}
        />
      </ContentContainer>
      <ContentContainer
        bg='white'
        backBg='black.950'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={8}
        minH='80vh'
        full
      >
        {renderWeeks()}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default UserEntityMain
