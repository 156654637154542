import { Divider, Text, VStack } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  title: string
  isDisabled?: boolean
  onClick: () => void
  hideDivider?: boolean
}

const MenuItem: FC<Props> = ({ title, onClick, isDisabled, hideDivider }) => {
  return (
    <VStack
      w='full'
      align='flex-start'
      spacing={'22px'}
      as={isDisabled ? undefined : 'button'}
      _hover={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      onClick={isDisabled ? undefined : onClick}
      // pb={{ base: 5, lg: '60px' }}
      opacity={isDisabled ? 0.4 : 1}
    >
      <Text textStyle='h2'>{title}</Text>
      {!hideDivider && <Divider />}
    </VStack>
  )
}

export default MenuItem
