import ReactDOM from 'react-dom/client'
import './index.css'
import 'shared/qlStyles.css'
import App from './App'
import '@fontsource-variable/inter'
import '@fontsource-variable/inter/wght.css'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import DayJSUtc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekday from 'dayjs/plugin/weekday'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as Sentry from '@sentry/react'
import PACKAGE from '../package.json'

dayjs.extend(DayJSUtc)
dayjs.extend(timezone)
dayjs.extend(weekday)
dayjs.extend(dayOfYear)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('ru')

Sentry.init({
  dsn: 'https://fc81ed8acc3516eb981ff6ccbac8a82a@o4506653173743616.ingest.sentry.io/4506653175578624',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', 'https://thequeenenglish.com']
    })
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: PACKAGE.version,
  environment:
    window.location.hostname === 'localhost' ? 'localhost' : 'production'
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
