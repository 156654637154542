import { createReducer } from '@reduxjs/toolkit'
import { ISeminar } from 'shared/types'
import { receiveSeminars } from 'model/actions'

const initialState = null as Record<string, ISeminar> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveSeminars, (state, action) => action.payload)
})

export default reducer
