import { onSnapshot, query, collection, where } from 'firebase/firestore'
import { db } from 'controllers/db'
import { ISeminar } from 'shared/types'
import { receiveSeminars } from 'model/actions'
import store from 'model/store'
import dayjs from 'dayjs'

export const dbFetchSeminars = async () => {
  try {
    const fromDate = +dayjs()
    const q = query(
      collection(db, 'seminars'),
      where('invisible', '==', false),
      where('startDate', '>=', fromDate)
    )
    onSnapshot(
      q,
      sn => {
        const res: Record<string, ISeminar> = {}
        sn.forEach(doc => {
          const p = doc.data() as ISeminar
          res[doc.id] = { ...p, id: doc.id }
        })
        store.dispatch(receiveSeminars(res))
      },
      err => {
        console.log(`dbFetchSeminars error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchSeminars error', e)
  }
}
