import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import SigninForm from 'components/auth/SigninForm'
import SignupForm from 'components/auth/SignupForm'
import { User } from 'firebase/auth'

export interface IAuthModal {
  open: () => void
}

type Props = {
  onAuth?: (authData: User) => void
}

const AuthModal: ForwardRefRenderFunction<IAuthModal, Props> = (
  { onAuth },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isSignin, setIsSignin] = useState(true)

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const onAuthHandler = (authData: User) => {
    console.log('SignIn on auth')
    if (onAuth) {
      onAuth(authData)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent rounded='16px'>
        <ModalBody p={6} m={0}>
          {isSignin ? (
            <SigninForm
              toSignUp={() => setIsSignin(false)}
              onAuth={onAuthHandler}
              toResetPassword={() => null}
            />
          ) : (
            <SignupForm
              toSignin={() => setIsSignin(true)}
              onAuth={onAuthHandler}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AuthModal)
