import PageContentWrapper from 'shared/components/PageContentWrapper'
import { VStack, Text, HStack } from '@chakra-ui/react'
import ContentContainer from 'shared/components/ContentContainer'
import { Link } from 'react-router-dom'
import BlackPageHeader from 'components/BlackPageHeader'

const Agreement = () => {
  const content = (
    <VStack
      w='full'
      align={'start'}
      color='black'
      textStyle={'body'}
      spacing={6}
      pt={12}
    >
      <Text>Дата утверждения: 01.11.2022 г.</Text>
      <Text>
        ИП Остроухова Маргарита Сергеевна, именуемый в дальнейшем Исполнитель,
        действующий от собственного имени, адресует настоящий Договор-оферту
        (далее по тексту – Договор-оферта, Договор) любому лицу (неопределенному
        кругу лиц), чья воля будет выражена им лично либо через уполномоченного
        представителя, выразившему готовность воспользоваться услугами
        Исполнителя (далее по тексту – Заказчик).
      </Text>
      <HStack textStyle={'h3'} pt={6}>
        <Text>
          ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕМ ДОГОВОРЕ-ОФЕРТЕ:
        </Text>
      </HStack>
      <Text>
        <b>Авторизация</b> – удостоверение Администратором правомочности
        обращения Пользователя к Системе для получения доступа к своему Личному
        кабинету. Авторизация Пользователя осуществляется по Логину и Паролю.
      </Text>
      <Text>
        <b>Администратор</b> – ИП Остроухова Маргарита Сергеевна, обладающая
        исключительными правами управления и развития Сервиса.
      </Text>
      <Text>
        <b>Активация</b> – действия Пользователя, направленные на формирование
        Пароля и присвоение в Системе Личному кабинету Покупателя статуса,
        позволяющего совершать посредством Личного кабинета действия,
        предусмотренные настоящим Договором.
      </Text>
      <Text>
        <b>Акцепт</b> - это ответ лица о полном и безоговорочном принятии
        оферты, путем оформления и оплаты Заказа в порядке и на условиях,
        установленных настоящим Договором-офертой.
      </Text>
      <Text>
        <b>Библиотека</b> - это обновляемая коллекция Онлайн-курсов на различные
        темы для самостоятельного прохождения в любое время.
      </Text>
      <Text>
        <b>Информационный обмен</b>- действия Сторон, направленные на
        информационное и технологическое взаимодействие при обмене Электронными
        документами посредством Личного кабинета.
      </Text>
      <Text>
        <b>Заказчик</b>- физическое лицо, оплатившее Услугу и получающее ее на
        условиях настоящего Договора-оферты.
      </Text>
      <Text>
        <b>Информация о Пользователе (ПИ)</b> – информация и данные
        Пользователя, включая, но не ограничиваясь: персональная информация
        Пользователя, контактные данные, предоставляемые Покупателем при оплате
        Услуг на Сервисе.
      </Text>
      <Text>
        <b>Курс, Онлайн-курс</b> - разработанный и утвержденный Исполнителем
        комплекс дистанционно оказываемых Услуг, представляющий собой
        совокупность нескольких объединенных одной темой форматов Услуг,
        разработанных Исполнителем с целью передачи Заказчику определяемой
        программой образовательной информации.
      </Text>
      <Text>
        <b>Личный кабинет Пользователя (далее – «Личный̆ кабинет»)</b> -
        специализированный раздел на Сайте Сервиса, защищённый специальными
        средствами защиты, содержащий Пользовательскую (и иную) информацию, в
        том числе историю осуществленных Операций, и предоставляющий
        Пользователю возможность совершать действия, предусмотренные настоящим
        Договором.
      </Text>
      <Text>
        <b>Логин</b> – адрес электронной почты, указанный Заказчиком при
        Регистрации, позволяющий определить Пользователя в Системе для
        последующей Авторизации в Личном кабинете.
      </Text>
      <Text>
        <b>Обратная связь</b> – услуга, выражающаяся в получении Заказчиком
        ответов от Исполнителя или привлеченным им третьими лицами на вопросы
        Заказчика, проводимая в виде общения в личном кабинете на Сервисе или с
        помощью мессенджеров либо в семинарской комнате.
      </Text>
      <Text>
        <b>Пароль</b>- известная только Пользователю уникальная
        последовательность алфавитно-цифровых символов, связанная с присвоенным
        Пользователю Логина и используемая для Авторизации. Пароль является
        аналогом собственноручной подписи Пользователя и является подтверждением
        надлежащей Авторизации Пользователя в Системе.
      </Text>
      <Text>
        <b>Пользователь</b> - Заказчик, совершившее действия по регистрации в
        Личном кабинете и заключению настоящего договора с Администратором.
      </Text>
      <Text>
        <b>Подписка</b> – получение доступа к Библиотеке, за платеж,
        периодичность которого устанавливается Исполнителем.
      </Text>
      <Text>
        <b>Регистрация</b> – действия Пользователя, совершаемые на Сайте
        Сервиса, направленные на регистрацию в Личном кабинете, а также
        предоставление Администратору первичной ПИ в целях последующего
        заключения договора на условиях Договора и получения доступа к Личному
        кабинету
      </Text>
      <Text>
        <b>Сайт, Сервис</b> – совокупность информации, текстов, графических
        элементов, дизайна, изображений, фото и видеоматериалов, и иных
        результатов интеллектуальной деятельности, а также программ для ЭВМ,
        содержащихся в информационной системе, обеспечивающей доступность такой
        информации в сети Интернет по сетевым адресам:
        https://thequeenenglish.com/ и их поддоменах, обеспечивающий доступ
        Пользователя к Личному кабинету.
      </Text>
      <Text>
        <b>Тариф</b> – стоимость и состав оказываемых Исполнителем Заказчику
        услуг в рамках программы Курса, информация о которых указана на Сайте
        Исполнителя.
      </Text>
      <Text>
        <b>Услуги</b> – образовательные услуги в сфере иностранных языков,
        предоставляемые в виде вебинаров, уроков, курсов, тренингов, семинаров,
        видео-, аудио записей, консультаций, тестов, электронных книг, пособий,
        брошюр или в любых иных формах, как при помощи различных он-лайн
        сервисов, электронной почты, мессенджеров или иными другими способами,
        включающие в себя прямые трансляции в сети Интернет, рассылки информации
        и обучающих материалов, предоставление материалов в записи, оказываемые
        как непосредственно Исполнителем, так и с помощью третьих лиц. Перечень,
        регламент и условия оказания Услуги описан на соответствующем Сайте,
        описывающую услугу.
      </Text>
      <Text>
        <b>Устройство</b> – любое техническое устройство, используемое
        Покупателем (компьютер, мобильный телефон и пр.), обеспечивающее доступ
        Пользователя в сеть Интернет.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>1. ОБЩИЕ ПОЛОЖЕНИЯ И ПРАВОВОЕ ОСНОВАНИЕ ДОГОВОРА-ОФЕРТЫ</Text>
      </HStack>
      <Text>
        1.1. Договор-оферта является официальным предложением Исполнителя
        (офертой) к заключению договора оказания образовательных услуг (далее по
        тексту – Услуги) и содержит все существенные условия договора оказания
        образовательных услуг (далее по тексту – Договор).
      </Text>
      <Text>
        1.2. Совершение Пользователем на Сайте Сервиса действий, направленных на
        Регистрацию, рассматривается как полное и безусловное согласие с
        условиями настоящего Договора-оферты и принятие всех условий настоящего
        Договора-оферты и приложений к нему. Регистрация Пользователя на Сайте
        является акцептом всех условий настоящего документа.
      </Text>
      <Text>
        1.3. В том числе, Пользователь, совершая действия, указанные в п. 1.3.
        Договора-оферты, подтверждает, что:
      </Text>
      <Text>
        1.3.1. Согласен на заключение с Администратором договора на условиях,
        изложенных в настоящем Договоре-оферте, и принимает на себя указанные в
        нем права и обязанности, связанные с использованием и функционированием
        Личного кабинета. Пользователь приобретает доступ к использованию
        функционала Личного кабинета и Сервиса;
      </Text>
      <Text>
        1.3.2. Подтверждает, что у Пользователя есть необходимое оборудование,
        программное обеспечение и возможность пользоваться Личным кабинетом,
        электронная почта и доступ в Интернет. Пользователь понимает и
        соглашается, что Администратор не несет ответственности за то, как
        Личный кабинет будет работать с Устройством, используемым Покупателем
        для доступа в Личный кабинет;
      </Text>
      <Text>
        1.3.3. При несогласии с условиями Договора-оферты Пользователь не вправе
        проходить Регистрацию и должен прекратить использование Личного
        кабинета;
      </Text>
      <Text>
        1.3.4. Действия Пользователя, направленные на Регистрацию, не ограничены
        сроком и могут быть осуществлены в любой день до момента официального
        отзыва Администратором Договора-оферты;
      </Text>
      <Text>
        1.3.5. Договор считается заключенным между Администратором и
        Пользователем с момента осуществления Пользователем Регистрации;
      </Text>
      <Text>
        1.3.6. С момента Регистрации (заключения между Сторонами договора),
        Администратор предоставляет Пользователю возможность посредством Личного
        кабинета: проводить Операции в рамках функционала Сервиса; просматривать
        историю совершенных Операций; получать Услуги в соответствии с их
        описанием на Сервисе и в настоящем Договоре-оферте; Администратор
        предоставляет Пользователю всю необходимую информацию об использовании
        Личного кабинета и Сервиса путем опубликования ее на Сайте и в Личном
        кабинете.
      </Text>

      <Text>
        1.4. Осуществляя акцепт Договора-оферты в порядке, определенном в п.
        1.2. Договора-оферты, Заказчик гарантирует, что ознакомлен, соглашается,
        полностью и безоговорочно принимает все условия Договора в том виде, в
        каком они изложены в тексте Договора-оферты.
      </Text>

      <Text>
        1.5. Заказчик понимает, что акцепт Договора-оферты в порядке, указанном
        в п. 1.2. Договора-оферты равносилен заключению Договора.
      </Text>
      <Text>
        1.6. Совершая действия по акцепту Договора-оферты Заказчик подтверждает,
        что он является совершеннолетним дееспособным лицом и имеет законные
        права на вступление в договорные отношения с Исполнителем.
      </Text>
      <Text>
        1.7. Исполнитель вправе в любое время вносить изменения в условия
        Договора-оферты без предварительного согласования с Заказчиком,
        обеспечивая при этом публикацию измененного Договора.
      </Text>
      <Text>1.8. Договор-оферта не может быть отозван.</Text>
      <Text>
        1.9. Договор-оферта не требует скрепления печатями и/или подписания
        Заказчиком и Исполнителем, сохраняя при этом юридическую силу.
      </Text>
      <Text>
        1.10. Осуществляя акцепт настоящей оферты, Заказчик подтверждает, что
        имеет доступ к сети Интернет и имеет возможность получать Услуги в
        онлайн-формате, в том числе имеет возможность пользоваться Сервисом.
      </Text>
      <Text>
        1.11. Заказчик обязуется внимательно прочитать текст данной оферты и,
        если он не согласен с каким-либо пунктом, то Исполнитель предлагает
        отказаться от каких-либо действий, необходимых для акцепта.
      </Text>
      <Text>
        1.12. Условия использования Личного кабинета обозначены в Приложении № 1
        к настоящему Договору-оферте и являющего его неотъемлемой частью.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>2. ПРЕДМЕТ ДОГОВОРА</Text>
      </HStack>
      <Text>
        2.1. Предметом настоящей Оферты является возмездное предоставление
        Заказчику Услуг, выбранных Заказчиком, в соответствии с условиями,
        указанными на Сайте Исполнителя, которые Заказчик обязуется принять и
        оплатить в порядке, предусмотренном настоящим Договором.
      </Text>
      <Text>
        2.2. Исполнитель обязуется оказать Заказчику на условиях настоящего
        Договора услуги, выбранные и оплаченные Заказчиком. Оказание Услуг
        осуществляется с помощью специализированного программного обеспечения
        Исполнителя.
      </Text>
      <Text>
        2.3. Услуги, не входящие в Подписку оплачиваются Заказчиком
        дополнительно на условиях, указанных на Сайте Исполнителя.
      </Text>
      <Text>
        2.4. Настоящий Договор-оферта определяет порядок и условия использования
        Пользователем Личного кабинета, а также регулирует права и обязанности
        Администратора и Пользователя, возникающие в связи с Регистрацией и
        использованием Личного кабинета. Договор-оферта применяется к любому
        использованию Личного кабинета и к любой Пользовательской информации,
        любой размещаемой и передаваемой информации с использованием Личного
        кабинета.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>3. СРОК ДЕЙСТВИЯ ДОГОВОРА </Text>
      </HStack>
      <Text>
        3.1. Договор считается заключенным с момента поступления денежных
        средств Заказчика в качестве полной или частичной оплаты за выбранные
        Услуги на расчетный счет Исполнителя и действует до момента исполнения
        Сторонами своих обязательств.
      </Text>
      <Text>
        3.2. В случае если Заказчик не получает Услуги своевременно по
        собственной вине или собственному желанию, в силу личных обстоятельств,
        Услуги считаются оказанной надлежащим образом и полностью.
      </Text>
      <Text>
        3.3. Заказчик самостоятельно знакомится с содержанием Услуг на
        Сайте/Сервисе и несет ответственность за своевременное получение
        информации и выполнение предусмотренных заданий, если задания
        предусмотрены выбранными Заказчиком Услугами.
      </Text>
      <Text>
        3.4. Доступ к образовательным материалам сохраняется за Заказчиком в
        течение срока, указанного в описании соответствующих приобретаемых Услуг
        на Сайте Исполнителя. Продленных доступ к материалам по завершении
        оказания услуг предоставляется Заказчику на безвозмездной основе.
      </Text>
      <Text>
        3.4.1. В случае приобретения Услуг в формате Подписки срок доступа к
        образовательным материалам начинает течь с момента оплаты.
      </Text>
      <Text>
        3.5. Если в течение 2 (двух) календарных дней с момента получения
        Заказчиком доступа к образовательным материалам, в соответствии с
        выбранными Заказчиком Услугами, Заказчик не заявил письменного
        мотивированного возражения в отношении качества и/или объема оказанных
        услуг путем отправки соответствующего обращения на адрес электронной
        почты Исполнителя, указанный на Сайте или в настоящем Договоре, услуги
        считаются принятыми и оказанными в надлежащем качестве, а Заказчик не
        имеет претензий к Исполнителю.
      </Text>
      <Text>
        3.6. По истечении 2 (двух) календарных дней с момента получения доступа
        к образовательным материалам, в соответствии с выбранными Заказчиком
        Услугами, Исполнитель принимает претензии только на предмет технических
        неполадок и отсутствия доступа к материалам в рамках срока, указанного в
        п. 3.4. и п. 3.4.1. настоящего Договора-оферты.
      </Text>
      <Text>
        3.7. Приостановка срока доступа к материалам, указанного в п. 3.4.
        настоящего Договора-оферты, не предусмотрена.
      </Text>
      <Text>3.8. Приостановление срока получения Услуг не предусмотрено.</Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>4. УСЛОВИЯ И ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГ</Text>
      </HStack>
      <Text>
        4.1. Заказчик вносит указанную и зафиксированную на Сайте сумму оплаты
        или часть оплаты, после чего Исполнитель приступает к оказанию Услуг.
      </Text>
      <Text>
        4.2. Заказчик не вправе передавать третьим лицам свой доступ к
        образовательным материалам, предоставленный Исполнителем. При нарушении
        данного пункта настоящего договора Исполнитель вправе запретить
        Заказчику доступ к уже оплаченным Услугам без возврата ранее оплаченных
        денежных средств.
      </Text>
      <Text>
        4.3. Заказчик соглашается с тем, что Исполнитель предоставляет доступ к
        услугам или цифровым продуктам как есть, и не вправе требовать
        дополнительной доработки, оплаченной им услуги или продукта, а также его
        отдельных компонентов, образовательных материалов, а также изменения
        правил предоставления услуг или доступа к продуктам, описанных
        положениями настоящего Договора.
      </Text>
      <Text>
        4.4. Условия оказания Услуг могут также сообщаться Исполнителем
        Заказчику, путем направления соответствующего уведомления на контактный
        е-mail, и/или SMS сообщения на контактный номер телефона Заказчика, на
        Сервисе, в мессенджерах, при этом Заказчик обязан самостоятельно и
        заблаговременно проверять получение уведомления, исходящего от
        Исполнителя.
      </Text>
      <Text>
        4.5.Исполнитель имеет право привлекать к оказанию Услуг третьих лиц без
        предварительного получения на то согласия Заказчика. Привлекаемые третьи
        лица выбираются по усмотрению Исполнителя. Исполнитель оставляет за
        собой право замены третьих лиц при оказании Услуг.
      </Text>
      <Text>
        4.6. В случае приобретения Услуг в формате Подписки обратную связь Заказ
        можно получить при посещении семинарской комнаты, в количестве,
        указанном на Сайте Исполнителя.
      </Text>
      <Text>
        4.7. В случае приобретения Услуг в формате Курса обратная связь
        предоставляется на условиях, обозначенных Исполнителем на сайте
        Исполнителя или на Сервисе, или путем направления уведомления в адрес
        Заказчика способами, установленными п. 4.4. настоящего Договора-оферты.
      </Text>
      <Text>
        4.8. В случае, если выбранными Заказчиком Услугами предусмотрено
        выполнение домашнего Задания, то сроки выполнения и проверки такого
        домашнего задания и иные условия определяются Исполнителем
        самостоятельно.
      </Text>
      <Text>
        4.9. Услуги по предоставлению обратной связи, в том числе по
        предоставлению обратной связи в рамках проверки домашних заданий,
        выполненных Заказчиков, считаются оказанными в срок и в надлежащем
        качестве и в том случае, если Заказчиком не заявлялось такое требование.
      </Text>
      <Text>
        4.10. Любое копирование, сохранение, размещение, публикация и т.д.
        материалов, предоставляемых Исполнителем в рамках оказания услуг, для
        личного или иного использования, на персональные компьютеры, электронные
        устройства, серверы, сайты, видеохостинги, электронные и
        интернет-ресурсы, предоставление данных доступа к Сервисе, иного
        предоставления образовательных материалов Исполнителя третьим лицам
        категорически запрещено. Установление указанных фактов влечет блокировку
        личного кабинета Заказчика и прекращение оказания услуг по Договору без
        возврата денежных средств.
      </Text>
      <Text>
        4.11. Исполнителем обязуется принимать все необходимые меры, однако не
        гарантирует 100% бесперебойности предоставления услуг по настоящему
        Договору. В случае неудовлетворительного качества Интернет-соединения со
        стороны Заказчика, наличия технических неполадок в устройствах, или
        программном обеспечении, используемом им для получения услуг,
        технических неполадок со стороны сервисов оплаты, размещенных на Сайте
        Исполнителя, дата/сроки оказания услуг могут быть изменены Исполнителем
        в одностороннем порядке без согласования с Заказчиком.
      </Text>
      <Text>
        4.12. Исполнитель обязуется оказывать услуги на высоком профессиональном
        уровне, подтвержденном опытом, Исполнителя, в соответствии с
        собственным, авторским видением и при использовании приемов/методик,
        отражающих видение и опыт Исполнителя. Несогласие Заказчика с
        результатами оказания услуг, выражающееся в субъективном непринятии
        стиля работы Исполнителя, собственных результатов и т.п., в условиях
        надлежащего оказания услуг Исполнителем, не является основанием для
        расторжения настоящего Договора и возврата денежных средств Заказчику.
      </Text>
      <Text>
        4.13. Исполнитель не несет ответственности за то, каким образом Заказчик
        использовал полученную информацию, а также за достижение каких-либо
        результатов, связанных с практическим применением данной информации.
        Любые рекомендации, полученные в ходе оказания услуг, осуществляются
        Заказчиком на свой риск.
      </Text>
      <Text>
        4.14. Исполнитель вправе изменять в одностороннем порядке и дополнять
        содержание заданий для Заказчика в рамках Курса, если такие задания
        предусмотрены выбранным Заказчиком Тарифом.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>5. ПРАВИЛА ПОЛУЧЕНИЯ УСЛУГ</Text>
      </HStack>
      <Text>
        5.1. Исполнитель имеет право не приступать к оказанию услуг, а также
        приостанавливать оказание услуг, к которым он фактически приступил, в
        случаях нарушения Заказчиком своих обязательств по настоящему Договору,
        а именно: неполной (ненадлежащей, несвоевременной) оплаты, сообщения
        неполной (недостоверной) информации, непредставления (несвоевременного
        представления) регистрационных или иных данных необходимых для оказания
        услуг, нарушения правил общения. 
      </Text>
      <Text>
        5.2. Заказчик обязуется неукоснительно и безоговорочно соблюдать
        следующие Правила общения при оказании ему Услуг Исполнителем:
        <br />- соблюдать дисциплину и общепринятые нормы поведения, в
        частности, проявлять уважение к Исполнителю и его сотрудникам и иным
        привлеченным третьим лицам, не посягать на их честь и достоинство;
        <br />- не допускать агрессивного поведения, в том числе пассивной
        агресси, во время оказания услуг, не мешать Исполнителю, представителю
        Исполнителя иным третьим лицам, привлеченным Исполнителем при
        оказании/получении Услуг;
        <br />- не использовать информацию, полученную от Исполнителя,
        способами, которые могут привести или приведут к нанесению ущерба
        интересам Исполнителя;
        <br />- не использовать предоставленные Исполнителем материалы с целью
        извлечения прибыли путем их тиражирования и многократного
        воспроизведения (публикации в прессе и других изданиях, публичные
        выступления и т.п.) и иными способами;
        <br />- не распространять любым способом, в т.ч. третьим лицам, не
        копировать, не размещать, не публиковать в общедоступных, закрытых,
        открытых источниках для любого круга лиц предоставленные Исполнителем:
        информацию, материалы, записи услуг, оказываемых Исполнителем;
        <br />- не использовать ненормативную лексику, не употреблять в общении
        выражения, которые могут оскорбить Исполнителя и иных третьих лиц,
        привлеченных Исполнителем или других Заказчиков;
        <br />- не распространять рекламу и не предлагать услуги сторонних
        ресурсов, свои услуги или услуги третьих лиц.
        <br />- Заказчику запрещается вводить в заблуждение Исполнителя
        относительно своих персональных данных, данных личного кабинета,
        возможных ограничениях, препятствующих получению Услуг.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>6. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</Text>
      </HStack>
      <Text>
        6.1. Общая стоимость рассчитывается из стоимости выбранных Заказчиком
        Услуг в соответствии с ценами, указанными на Сайте Исполнителя.
      </Text>
      <Text>
        6.2. Общая стоимость услуг может быть изменена только путем подписания
        дополнительного соглашения к Договору.
      </Text>
      <Text>
        6.3. Оплата услуг по Договору осуществляется в порядке 100 (сто) –
        процентной предоплаты или частичной оплаты. Оплата осуществляется путем
        перечисления денежных средств на реквизиты, указанные Исполнителем, либо
        с использованием электронных платежных систем, указанных Исполнителем на
        Сайте. Услуги считаются оплаченными с момента поступления денежных
        средств на расчетный счет Исполнителя.
      </Text>
      <Text>
        6.3.1. По желанию Заказчика, являющегося гражданином Российской
        Федерации, возможно оформление рассрочки посредством банковской
        рассрочки, рассрочки через сервис от Тинькофф банка.
      </Text>
      <Text>
        6.4. Возврат денежных средств Исполнителем осуществляется по заявлению
        Заказчика, направленному в на электронный адрес:
        royalbritishpronunciation@gmail.com не позднее 10 (десяти) календарных
        дней с даты получения соответствующего заявления.
      </Text>
      <Text>
        6.5. Возврат осуществляется при условии полной оплаты Исполнителю
        фактически понесенных расходов, и с учетом условий, указанных в п. 6.9.
        и 6.9.1. настоящего Договора. 
      </Text>
      <Text>
        6.6. К фактически понесенным расходам Исполнителя относятся (включая,
        но, не ограничиваясь) комиссии банковских/ платежных организаций за
        осуществление возврата денежных средств, денежное
        вознаграждение сотрудников Исполнителя и (или) третьих лиц, участвующих
        в подготовке оказания Услуг.
      </Text>
      <Text>
        6.7. Заявление на возврат денежных средств в обязательном порядке должно
        содержать ФИО и банковские реквизиты для возврата, а также адрес
        электронной почты, номер и дату заказа. В случае отсутствия каких-либо
        из указанных сведений, Исполнитель вправе не принимать заявление к
        рассмотрению до момента устранения недочетов.
      </Text>
      <Text>
        6.8. Денежные средства возвращаются на тот же банковский счет Заказчика,
        с которого производилась оплата услуг. Денежные средства, за услуги,
        оплаченные Заказчиком с помощью банковской карты, не могут быть
        возвращены наличными денежными средствами.
      </Text>
      <Text>
        6.9. Обращение за возвратом осуществляется в следующем порядке: при
        обращении за возвратом, возврат осуществляется пропорционально оказанным
        Заказчику Услугам на момент обращения за возвратом от полной стоимости
        Услуг, в отношении которых Заказчик хочет осуществить возврат, за
        вычетом фактически понесенных расходов, а также за вычетом
        организационных издержек. Стоимость организационных издержек
        определяется в размере 30 % от полной стоимости Услуг, в отношении
        которых Заказчик хочет осуществить возврат.
      </Text>

      <Text>
        6.9.1. При обращении за возвратом, в случае приобретения Услуг в формате
        Подписки, возврат осуществляется в следующем порядке: при обращении за
        возвратом, возврат осуществляется пропорционально времени (количеству
        месяцев), в течение которого Заказчику был предоставлен доступ к
        образовательным материалам от полной стоимости Услуг, выбранных
        Заказчиком, за вычетом фактических расходов. Для целей расчета возврата
        берется 1 месяц с момента оплаты, даже если ткущий месяц только начался.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>7. ОТВЕТСТВЕННОСТЬ СТОРОН. ПОРЯДОК РАСТОРЖЕНИЯ ДОГОВОРА</Text>
      </HStack>
      <Text>
        7.1. Стороны несут ответственность за неисполнение или ненадлежащее
        исполнение своих обязательств по Договору в соответствии с Договором и
        законодательством России.
      </Text>
      <Text>
        7.2. Договор может быть расторгнут по соглашению Сторон, а также в
        одностороннем порядке по письменному требованию одной из Сторон по
        основаниям, предусмотренным Договором и законодательством.
      </Text>
      <Text>
        7.3. Расторжение Договора в одностороннем порядке производится только по
        письменному требованию Сторон. Заказчик вправе расторгнуть Договор в
        одностороннем порядке только при условии полной оплаты Исполнителю
        фактически осуществленных последним расходов на оказание услуг и в
        соответствии с условиями возврата, указанными в разделе 6 настоящего
        Договора. Исполнитель вправе расторгнуть Договор в одностороннем порядке
        в случае нарушения Заказчиком его обязательств по настоящему Договору.
      </Text>
      <Text>
        7.4. Исполнитель обязуется принимать все необходимые меры, однако не
        гарантирует 100% бесперебойности предоставления услуг по настоящему
        Договору. В случае неудовлетворительного качества Интернет-соединения со
        стороны Заказчика, наличия технических неполадок в устройствах, или
        программном обеспечении, используемом им для получения услуг, дата/сроки
        оказания услуг могут быть изменены Исполнителем в одностороннем порядке
        без согласования с Заказчиком.  
      </Text>
      <Text>
        7.5. Стороны пришли к соглашению, что будут стараться урегулировать
        возникающие конфликты мирным путем. Претензионный порядок является для
        Сторон обязательным. Спор может передаваться на разрешение суда после
        принятия Сторонами мер по досудебному урегулированию конфликта.
      </Text>
      <Text>
        7.6. Споры из Договора разрешаются в судебном порядке в соответствии с
        законодательством РФ.
      </Text>
      <Text>
        7.7. Исполнитель не несет ответственности за ненадлежащее качество
        оказания Услуг, если ненадлежащее исполнение явилось следствием
        недостоверности, недостаточности или несвоевременности предоставленных
        Заказчиком сведений, а также вследствие других нарушений условий
        настоящего Договора со стороны Заказчика.
      </Text>
      <Text>
        7.8. Исполнитель не несет ответственности за несоответствие
        предоставленной услуги ожиданиям Заказчика и/или за его субъективную
        оценку. Такое несоответствие ожиданиям и/или отрицательная субъективная
        оценка не являются основаниями считать услуги оказанными некачественно,
        или не в согласованном объеме.
      </Text>
      <Text>
        7.9. Исполнитель не несет ответственности за неверную (недостоверную)
        информацию, указанную Заказчиком при регистрации, либо при оплате услуг,
        а также не несет ответственности за возможные блокировки личного
        кабинета.
      </Text>
      <Text>
        7.10. Пользователь несет личную ответственность за достоверность
        указываемой ПИ. При указании недостоверной информации Администратор не
        несет ответственность за неисполнение или ненадлежащее исполнение
        условия настоящего Договора-оферты и Приложений к нему, а также за любые
        убытки, возникшие у Пользователя в связи с предоставлением недостоверной
        ПИ; за доступ третьих лиц к использованию адреса электронной почты и/или
        номера мобильного телефона, указанного Пользователем при Регистрации; за
        конфиденциальность Логина и Пароля, а также за предоставление информации
        о Логине и Пароле третьим лицам. Администратор не несет ответственности
        в случае, если не по вине Администратора Логин и Пароль для входа в
        Личный кабинет станут известны третьим лицам, что впоследствии приведет
        к материальному ущербу Пользователя.
      </Text>
      <Text>
        7.11. Пользователь самостоятельно несет риски любых убытков или иных
        негативных последствий, возникших у него в результате выполнения
        действий по Регистрации и использованию Личного кабинета, совершению
        Операций, или в связи с тем, что Пользователь получил Услугу.
        Администратор не несут ответственность за любые убытки и последствия,
        возникшие у Пользователя вследствие получения Услуги. Пользователь
        самостоятельно принимает решения о совершении своих личных поступков и
        осознает, что Администратор посредством Сервиса не дает Пользователю
        каких-либо указаний/ советов/ не решает внутренние и внешние проблемы
        Пользователя.
      </Text>
      <Text>
        7.12. Пользователь соглашается с тем, что Администратор не несет
        ответственности за аварии, перебои в обслуживании электросетей и иные
        сбои, связанные с системами подачи электроэнергии и/или линий связи или
        сетей, которые обеспечиваются, подаются или обслуживаются третьими
        лицами и вследствие неисправности которых, доступ к Личному кабинету
        и/или информационный обмен, станут невозможными.
      </Text>
      <Text>
        7.13. Администратор не несет ответственности за сохранность банковской
        тайны, относящейся к использованию Покупателем Личного кабинета при
        осуществлении Операций. Указанные сведения предоставляются Пользователем
        исключительно банкам и платежным сервисам при оплате Услуг в порядке,
        установленном законодательством Российской Федерации.
      </Text>
      <Text>
        7.14. Администратор не несет ответственность: за прямой или косвенный
        ущерб, возникший у Пользователя вследствие использования Личного
        кабинета; за перерывы в работе Системы, и/или Сайта Сервиса, и/или
        Личного кабинета, в случае сбоев программного обеспечения, связи или
        поломок оборудования, не принадлежащего Администратору; если не по вине
        Администратора доступ к использованию Устройства Пользователя получат
        третьи лица в связи с чем посредством Личного кабинета будут совершены
        действия, которые впоследствии приведут к материальному ущербу
        Пользователя; за полные или частичные перерывы в работе Сайта и/ или
        Сервиса, и/или Личного кабинета, связанные с заменой оборудования,
        программного обеспечения или проведением других работ, вызванных
        необходимостью поддержания работоспособности и модернизации программного
        обеспечения Сервиса;{' '}
      </Text>
      <Text>
        7.15. Администратор вправе заблокировать Личный кабинет Пользователя в
        случае нарушения последним любых условий настоящего Договора-оферты и
        приложений к нему. В таком случае дополнительные штрафные санкции на
        Администратора не налагаются.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>8. ФОРС-МАЖОР</Text>
      </HStack>
      <Text>
        8.1. Стороны освобождаются от ответственности за полное или частичное
        неисполнение обязательств по Договору в случае, если неисполнение
        обязательств явилось следствием действий непреодолимой силы, а именно:
        пожара, наводнения, землетрясения, забастовки, войны, действий органов
        государственной власти или других независящих от Сторон обстоятельств.
      </Text>
      <Text>
        8.2. Сторона, которая не может выполнить обязательства по Договору,
        должна своевременно, но не позднее 5 (пяти) календарных дней после
        наступления обстоятельств непреодолимой силы, письменно известить другу
        Сторону, с предоставлением обосновывающих документов. Выданных
        компетентными органами.
      </Text>
      <Text>
        8.3. Стороны признают, что неплатежеспособность Заказчика не являются
        форс-мажорными обстоятельствами.  
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>9. ПРОЧИЕ УСЛОВИЯ</Text>
      </HStack>
      <Text>
        9.1. Стороны признают, что, если какое-либо из положений становится
        недействительным в течение срока его действия вследствие изменения
        законодательства, остальные положения Договора обязательны для Сторон в
        течение срока действия Договора.
      </Text>
      <Text>
        9.2. Информация, передаваемая в рамках оказания Услуг, является
        интеллектуальной собственностью Исполнителя и подлежит охране в
        соответствии с законодательством Российской Федерации. Распространение
        полученной в рамках Услуг информации в коммерческих целях недопустимо.
        Заказчик обязуется не копировать записи Услуг и не предоставлять ее
        третьим лицам или размещать в открытом доступе на других сайтах.
        Заказчику запрещается распространять, публиковать, размещать в Интернет,
        копировать, передавать или продавать третьим лицам, осуществлять запись,
        скачивание, изготовление скриншотов и фотографий записей
        аудио-визуальных произведений и другого контента, предоставляемого в
        рамках оказания Услуг по настоящему Договору. Заказчику запрещается
        сохранять и передавать третьим лицам свою переписку с Куратором,
        информацию и любые материалы, полученные в рамках настоящего Договора,
        создавать на их основе информационные/ образовательные продукты, а также
        использовать эту информацию каким-либо иным образом, кроме как для
        личного пользования в рамках настоящего Договора.
      </Text>
      <Text>
        9.3. В случае нарушения такого обязательств, предусмотренных п. 9.2.
        настоящего Договора, Исполнитель вправе требовать с Заказчика возмещения
        понесенных убытков, а также компенсации упущенной выгоды в соответствии
        с действующим законодательством РФ, а также штраф в размере 500 000
        (пятьсот тысяч) рублей.
      </Text>
      <Text>
        9.4. Исполнитель имеет право незамедлительно прекратить оказание услуг
        без возвращения денежных средств, в случае проявления со стороны
        Заказчика агрессии или неуважительного отношения к Исполнителю,
        Кураторам и иным привлеченным третьим лицам, в случае использования
        ненормативной лексики и проявления агрессии в комментариях на Сервисе, в
        чатах, а также в случаях разжигания межнациональных конфликтов, рассылки
        спама, размещения рекламы. Настоящим Заказчик подтверждает, что
        предоплаченная им стоимость услуг в случаях, указанных в настоящем
        пункте, признается Сторонами как неустойка за нарушение Заказчиком
        условий Договора и Заказчику не возвращается.
      </Text>
      <Text>
        9.5. Стороны установили, что скриншоты электронной переписки между
        Сторонами во исполнение настоящего Договора-оферты, являются достаточным
        и допустимым доказательством для подтверждения тех фактов, которые в них
        указаны.
      </Text>

      <HStack textStyle={'h3'} pt={6}>
        <Text>10. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</Text>
      </HStack>
      <Text>
        10.1. В соответствии с требованиями Федерального закона от 27.07.2006 №
        152 – ФЗ «О персональных данных» Заказчик (субъект персональных данных),
        принимая условия настоящего Договора-оферты, дает согласие на обработку
        всех своих персональных данных, предоставляемых Исполнителю, а также на
        их передачу лицам, с которыми у Исполнителя имеются договорные отношения
        по исполнению условий настоящего Договора-оферты и оказанию услуги
        Заказчику. Обработка персональных данных Заказчика осуществляется в
        соответствии с законодательством РФ, а также в соответствии с Политикой
        обработки персональных данных, являющейся локальным документами
        Исполнителя.
      </Text>
      <Text>
        10.2. Все персональные данные обрабатываются Исполнителем в соответствии
        с требованиями Федерального закона 152-ФЗ «О персональных данных» и в
        соответствии с Политикой конфиденциальности, размещенной на Сайте по
        ссылке:
        <Link to='/privacy'> https://thequeenenglish.com/privacy</Link>
      </Text>
      <Text>
        10.3. Исполнитель обязуется сохранять и не разглашать конфиденциальную
        информацию, ставшую ему известной от Заказчика при оказании Услуг по
        настоящему Договору.
      </Text>
      <Text>
        10.4. Исполнитель обязуется не передавать/не разглашать данные для
        идентификации на Сервисе, в том числе логин и пароль.
      </Text>
      <Text fontWeight={700} pt={6}>
        Реквизиты Исполнителя и контактная информация:
      </Text>
      <Text>
        ИП Остроухова Маргарита Сергеевна Электронная почта:
        <br /> Royalbritishpronunciation@gmail.com
      </Text>
    </VStack>
  )

  const application1 = (
    <VStack
      w='full'
      align={'start'}
      color='black'
      textStyle={'body'}
      spacing={6}
      pt={'32'}
    >
      <HStack w='full' justify={'flex-end'} pt={6}>
        <Text>Приложение 1</Text>
      </HStack>
      <HStack w='full' justify={'center'} textStyle={'h3'} pt={6}>
        <Text textAlign={'center'}>Условия использования Личного кабинета</Text>
      </HStack>
      <Text>
        1. Администратор предоставляет Пользователю возможность использовать
        Личный кабинет для целей, определенных в Договоре-оферте.
      </Text>
      <Text>
        2. Право пользования Личным кабинетом предоставляется только
        Пользователю, осуществившему Регистрацию. Заказчик не может передавать
        свое право пользования Личным кабинетом другим лицам.
      </Text>
      <Text>
        3. Доступ к Личному кабинету предоставляется Пользователю только при
        условии одновременного выполнения следующих требований:
        <br />- Пользователь осуществил Регистрацию в Личном кабинете;
        <br />- Личный кабинет Пользователя не заблокирован;
        <br />- Пользователь имеет необходимый технический доступ в Интернет;
        <br />- Пользователь осуществил успешную Авторизацию.
      </Text>
      <Text>
        4. Заказчик использует Личный кабинет исключительно на свой риск.
      </Text>
      <Text>
        5. Заказчику при использовании Личного кабинета запрещается:
        <br />- осуществлять Регистрацию от имени или вместо другого лица без
        соответствующих полномочий, предоставленных указанным лицом. При этом
        возможна Регистрация от имени и поручению другого физического лица при
        условии получения необходимых полномочий;
        <br />- вводить Администратора в заблуждение относительно своего номера
        телефона и адреса электронной почты, использовать для Авторизации Логин
        и Пароль другого зарегистрированного Пользователя;
        <br />- использовать программное обеспечение и осуществлять действия,
        направленные на нарушение нормального функционирования Личного кабинета,
        в том числе загружать, хранить, публиковать, распространять и
        предоставлять доступ или иным образом использовать вирусы, трояны и
        другие вредоносные программы, а также использовать без специального на
        то разрешения Администратора автоматизированные скрипты программы для
        взаимодействия с Сервисом и его функционалом.
        <br />- в случае нарушения Пользователем условий использования Личного
        кабинета, Администратор вправе прекратить доступ Покупателем к Личному
        кабинету (осуществить блокирование Личного кабинета) без
        предварительного предупреждения Пользователя.
      </Text>
      <Text>
        6. Регистрация в Личном кабинете является добровольным действием
        Пользователя.
      </Text>
      <Text>
        7. Право Регистрации предоставляется только Пользователям, достигшим
        возраста 18 лет.
      </Text>
      <Text>
        8. Для Регистрации Пользователю необходимо изучить условия
        Договора-оферты, а также инструкции, размещенные на Сайте Сервиса, и
        пройти Регистрацию в Личном кабинете на Сайте Сервиса путем
        самостоятельного формирования Логина и Пароля, а также выполнить иные
        условия, которые публикуются и доводятся Администратором до сведения
        Заказчика на Сайте Сервиса.
      </Text>
      <Text>
        9. Если Пользователь не принимает или не понимает условия пользования
        Личным кабинетом, а также инструкции, размещенные на Сайте Сервиса,
        Заказчик не может пользоваться Личным кабинетом и не должен проходить
        Регистрацию.
      </Text>
      <Text>
        10. Действия Пользователя по Регистрации в Личном кабинете подразумевают
        возникновение у Пользователя возможности использовать Личный кабинет в
        объеме и в порядке, определенными Договором-офертой и Приложениями к
        нему.
      </Text>
      <Text>
        11. Логин и Пароль, используемые при Регистрации, являются необходимой и
        достаточной информацией для последующей Авторизации и доступа
        Пользователя в Личный кабинет. Пользователь не имеет права передавать
        или разглашать свои Логин и Пароль третьим лицам без предварительного
        письменного согласия Администратора. Пользователь несет полную
        ответственность за сохранность Логина и Пароля, самостоятельно выбирая
        способ их хранения. Администратор настоятельно не рекомендует
        Пользователю на используемом им Устройстве разрешить хранение Логина и
        Пароля (с использованием файлов cookies) для последующей автоматической
        Авторизации в Личном кабинете.
      </Text>
      <Text>
        12. Пользователь несет ответственность за достоверность, актуальность,
        полноту информации, предоставленной при Регистрации Пользователя.
      </Text>
      <Text>
        13. Для получения доступа в Личный кабинет Пользователю необходимо
        пройти процедуру Авторизации в Личном кабинете по Логину и Паролю.
        Пользователь считается успешно Авторизованным в случае соответствия
        Логина и Пароля, введенного при входе в Личный кабинет, Логину и Паролю,
        хранящемуся в Системе.{' '}
      </Text>
      <Text>
        14. С момента Авторизации Пользователя в Личном кабинете, любые
        действия, в том числе Операции, совершенные через Личный кабинет с
        использованием Логина и Пароля, считаются совершенными самим
        Пользователем. Подтверждением в осуществлении Пользователем Операций
        являются успешная Авторизация учетной записи по Логину и Паролю.{' '}
      </Text>
      <Text>
        15. В случае несанкционированного доступа к Логину и Паролю и/или
        Личному кабинету Пользователя, или распространения Логина и Пароля,
        Пользователь обязан незамедлительно сообщить об этом Администратору по
        адресу электронной почты, указанной в Договоре-оферте.{' '}
      </Text>
      <Text>
        16. В случае неоднократного непрохождения Пользователем процедуры
        Авторизации, а также в иных случаях, Администратор вправе блокировать
        доступ к Личному кабинету. В целях восстановления доступа к Личному
        кабинету, Пользователю необходимо обратиться к Администратору по адресу
        электронной почты, указанной в Договоре-оферте.
      </Text>
      <Text>
        17. Функциональностью Сервиса возможность изменения/отмены Заказа не
        предусмотрена.{' '}
      </Text>
    </VStack>
  )

  return (
    <PageContentWrapper>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='lightGray'
        backBg='white'
      >
        <BlackPageHeader
          title='Пользовательское соглашение'
          subtitle='Договор-оферта использования физическими лицами Интернет-сервиса https://thequeenenglish.com/'
        />
      </ContentContainer>
      <ContentContainer
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        bg='white'
        pb={12}
      >
        {content}
        {application1}
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default Agreement
