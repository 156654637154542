import { createReducer } from '@reduxjs/toolkit'
import { IFree } from 'shared/types'
import { receiveFrees } from 'model/actions'

const initialState = null as Record<string, IFree> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveFrees, (state, action) => action.payload)
})

export default reducer
